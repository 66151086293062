import React, { lazy, useState, useEffect } from "react"
// for the strategies icon purposes
import { useDispatch, useSelector } from "react-redux"
import { useLocation } from "react-router-dom"
import FilterProjectsEventsLayout from "../../components/Layout/FilterProjectsEventsLayout"
import EmptyState from "../../components/Project/EmptyState"
import { filterProjects } from "../../store/actions/projectsActions"
import ProjectCard from "./ProjectCard"
import { sendEventViewProjectList } from "@/lib/firebase"

export default function Projects() {
  const location = useLocation()
  const dispatch = useDispatch()
  const [projects, setProjects] = useState([])
  // const projects = useSelector((state) => state.projects.projects)
  const selectedStrategies = useSelector((state) => state.projects.selectedStrategies)
  const isAnalyticsEnabled = () => process.env.MODE === "production"

  useEffect(() => {
    dispatch(filterProjects({ strategies: selectedStrategies })).then((res) => {
      setProjects(res)
      if (isAnalyticsEnabled()) {
        console.log("this is production environment")
        sendEventViewProjectList(res)
      } else {
        console.log("this is staging environment")
      }
    })
  }, [selectedStrategies])

  const isReplaceingProjects = location.pathname.includes("replacement")
  const loggedIn = useSelector((state) => state.account.loggedIn)
  const showEmpty =
    (!loggedIn && projects?.filter((project) => project?.isClosingSoon)?.length === projects?.length && !isReplaceingProjects) || projects?.length === 0
  return (
    <>
      <div className="px-[5%]">{loggedIn && <FilterProjectsEventsLayout />}</div>
      {!showEmpty ? (
        <div className="grid px-[5%] grid-cols-1 mobile:grid-cols-2 tablet:grid-cols-3 laptop:grid-cols-4   my-8 gap-4">
          {isReplaceingProjects
            ? projects?.map((project) => {
                if (!project?.isClosingSoon && !project?.isSupporting) {
                  return <ProjectCard key={project.id} project={project} />
                }
              })
            : projects?.map((project) => {
                if (!loggedIn) {
                  return !project?.isClosingSoon ? <ProjectCard key={project.id} project={project} /> : null
                }
                return <ProjectCard key={project.id} project={project} />
              })}
        </div>
      ) : (
        <div className="w-full pb-10">
          <EmptyState title="No projects assigned to this strategy" />
        </div>
      )}
    </>
  )
}
