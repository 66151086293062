import React from "react";
import { Link } from "react-router-dom";

const EmptyState = ({ title, description, link, linkDescription }) => {
  return (
    <div className="grid place-items-center w-full  h-fit">
      <img src={require("@/assets/img/illustration_empty.svg")} alt=""></img>
      <div className="font-bold px-2 text-center grid place-items-center  text-[22px]">
        {title}
      </div>
      {description && (
        <div className="my-4 grid place-items-center">
          <div className=" flex justify-center ">{description}</div>
        </div>
      )}
      {linkDescription && link && (
        <div className="bg-orange_v2 p-2 grid place-items-center  text-white font-[16px] rounded my-4">
          <Link to={link}>{linkDescription}</Link>
        </div>
      )}
    </div>
  );
};

export default EmptyState;
