import * as actionTypes from "../action-types"

let initialState = {
  personal: [],
  individual: null,
  couple: null,
  family: null,
  business: null,
  selectedSubscription: "",
  selectedBillingFrequency: "",
  orderHistory: [],
  orderHistoryTotalPages: 0,
  orderHistoryTotalItems: 0,
}
function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.GET_PERSONAL_SUBSCRIPTIONS:
      return {
        ...state,
        personal: action.payload,
        individual: action.payload.find((personal) => personal.name.toLowerCase().includes("individual")),
        couple: action.payload.find((personal) => personal.name.toLowerCase().includes("couple")),
        family: action.payload.find((personal) => personal.name.toLowerCase().includes("families")),
      }
    case actionTypes.SELECTED_SUBSCRIPTION:
      return {
        ...state,
        selectedSubscription: action.payload,
      }
    case actionTypes.SELECTED_BILLING_FREQUENCY:
      return {
        ...state,
        selectedBillingFrequency: action.payload,
      }
    case actionTypes.GET_BUSINESS_SUBSCRIPTIONS:
      return {
        ...state,
        business: action.payload,
      }
    case actionTypes.GET_ORDER_HISTORY:
      return {
        ...state,
        orderHistory: action.payload.items,
        orderHistoryTotalPages: action.payload.totalPages,
        orderHistoryTotalItems: action.payload.totalItems,
      }
    default:
      return state
  }
}
export default reducer
