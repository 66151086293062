import React, { useState, useRef, useEffect, memo } from "react"
import background from "@/assets/img/BM-background.jpg"
import Joi from "joi"
import { connect } from "react-redux"
import { produce } from "immer"
import { useNavigate, useLocation } from "react-router-dom"
import InputField from "@/components/InputField"
// import Autocomplete from "@mui/material/Autocomplete"
import Autocomplete from "react-google-autocomplete"
import { TextField } from "@mui/material"
import Button from "@mui/material/Button"
import Checkbox from "@mui/material/Checkbox"
import FormGroup from "@mui/material/FormGroup"
import FormControlLabel from "@mui/material/FormControlLabel"
import FormControl from "@mui/material/FormControl"
import FormLabel from "@mui/material/FormLabel"
import InputLabel from "@mui/material/InputLabel"
import MenuItem from "@mui/material/MenuItem"
import Select from "@mui/material/Select"
import ButtonGroup from "@mui/material/ButtonGroup"
import AddIcon from "@mui/icons-material/Add"
import RemoveIcon from "@mui/icons-material/Remove"

const initialState = {}
const schema = Joi.object({})
const index = (props) => {
  const navigate = useNavigate()
  const { state } = useLocation()

  const [data, setData] = useState(initialState)

  useEffect(() => {
    console.log(state)
    setData(state)
    return () => setData(initialState)
  }, [])
  return (
    <>
      <div
        className=" w-full h-screen "
        style={{
          backgroundImage: `url(${background})`,
          backgroundPosition: "center",
        }}>
        <br></br>
        <div className="m-auto w-[80%] laptop:w-1/3 bg-white rounded-8">
          <div className="grid place-items-center w-full p-4">
            <div>
              <p className=" text-sm text-[#EA664E] laptop:text-base flex-wrap">Banff Marathon</p>
              <p className="font-bold  md:pr-28 desktop:pr-40 text-xl laptop:text-4xl  relative leading-tight">Carbon Emissions Calculator</p>
              <p className=" text-sm  laptop:text-base flex-wrap">Calculations are based on road distance, and average highway fuel consumption.</p>
              <div className="flex justify-evenly space-x-4 py-2">
                <div className="w-full border border-black rounded-8">
                  <div className="bg-[#EA664E] rounded-t-8 uppercase text-white grid place-items-center font-bold py-2"> result </div>
                  <div className="text-xl text-[#EA664E] grid place-items-center font-bold pt-4">
                    <div className="">
                      {data?.carbonFootPrint?.toLocaleString(undefined, { maximumFractionDigits: 2 })}&nbsp; kg CO2<span className="lowercase">e</span>
                    </div>
                  </div>
                  <div className="grid place-items-center py-2"> Carbon Footprint </div>
                </div>
                <div className="w-full border border-black rounded-8">
                  <div className="bg-[#444] rounded-t-8 uppercase text-white grid place-items-center font-bold py-2">
                    <p className="flex">
                      <span className="hidden laptop:grid">membership</span>&nbsp;<span>discount</span>
                    </p>
                  </div>
                  <div className="text-xl text-[#EA664E] grid place-items-center font-bold pt-4">
                    <div className="">BANFF24</div>
                  </div>
                  <div className="grid place-items-center py-2">Promo Code </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br></br>
        <div className="m-auto w-[80%] laptop:w-1/3 bg-white rounded-8">
          <div className="grid place-items-center p-4">
            <div className="flex">
              <img style={{ width: "60%" }} className="w-full" src={require("@/assets/img/QR code - Karbon-X old.png")} alt=""></img>
              <div className="m-auto ">
                <div className="text-[#EA664E] uppercase">
                  <span className="font-bold">Download</span> the Karbon-X app
                </div>
                <div className="hidden tablet:grid"> Join the movement, support climate action projects conveniently from your phone.</div>
                <div className="laptopL:flex laptopL:space-x-4 mt-2">
                  <a href="https://apps.apple.com/ca/app/karbon-x/id1673803570" target="_blank">
                    <img src={require("@/assets/svg/App Store.svg")} alt=""></img>
                  </a>
                  <a href="https://play.google.com/store/apps/details?id=com.vog.karbonx&hl=en_CA&gl=US" target="_blank">
                    <img src={require("@/assets/svg/Google Play.svg")} alt=""></img>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="flex justify-end ">
            <div className="space-x-2 m-2">
              <Button
                onClick={() => navigate("/banff-marathon-intro")}
                sx={{
                  borderRadius: 999,
                  textTransform: "none",
                  fontWeight: "bold",
                }}
                autoCapitalize="true"
                variant="contained">
                Done
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default connect(null, null)(index)
