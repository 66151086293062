import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { ToggleSuccessModal } from "@/store/actions/modalActions"

export default function SuccessModal({}) {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const successText = useSelector((state) => state.modal.successText)
  const successUrl = useSelector((state) => state.modal.successUrl)
  const successButtonText = useSelector((state) => state.modal.successButtonText)

  return (
    <>
      <div className="justify-centeritems-center flex overflow-x-hidden overflow-y-auto fixed items-center inset-0 z-50 outline-none focus:outline-none">
        <div className="relative w-auto my-6 mx-auto max-w-3xl">
          {/*content*/}
          <div className="border-0 rounded-lg rounded shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            {/*body*/}
            <div className="relative   max-w-[800px] laptop:w-[600px] h-[637px] grid rounded">
              <div className={`font-bold text-[30px] ${successText ? "bg-register_bg_confirm" : "bg-payment_bg_confirm"} text-center bg-bottom h-full  px-10`}>
                {successText ? (
                  successText
                ) : (
                  <>
                    <div className="flex justify-center text-center pt-[380px] uppercase">Your Karbon-X Journey</div>
                    <div className="flex justify-center text-orange_v2 uppercase">Starts Now!</div>
                    <div className="text-[14px] text-darker-grey/60 flex justify-center mt-8">
                      Thank you for supporting this project and for being part of Karbon-X you received your first badge.
                    </div>
                  </>
                )}
              </div>
              <div className="flex justify-center">
                <button
                  className="w-[350px] h-[54px] my-2 relative bg-orange_v2 justify-center uppercase grid content-center rounded-[32px] cursor-pointer text-white font-semibold disabled:bg-dark-grey disabled:cursor-default"
                  onClick={() => {
                    dispatch(ToggleSuccessModal())
                    if (successUrl) {
                      navigate(successUrl)
                    } else {
                      navigate("/projects/mine")
                    }
                  }}>
                  {successButtonText ? successButtonText : "Go to My Projects"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  )
}
