import { createAction, createReducer } from "@reduxjs/toolkit"

let initialState = {
  userAction: "",
  isForSomeoneElse: false,
  country: {},
  senderName: "",
  senderEmail: "",
  beneficiaryEmail: "",
  beneficiaryName: "",
  summary: {},
  totalCarbonOffset: "",
  totalCustomAmount: "",
  postalCode: "",
  paymentInfo: {},
}

export default createReducer(initialState, (builder) => {
  builder
    .addCase("otp/init", (state, action) => {
      //load template data, replace null values
      state.userAction = "select-country"
    })
    .addCase("otp/someone", (state, action) => {
      state.isForSomeoneElse = action.payload
    })
    .addCase("otp/updateUserAction", (state, action) => {
      state.userAction = action.payload
    })
    .addCase("otp/addCountry", (state, action) => {
      state.country = action.payload
    })
    .addCase("otp/addPostalCode", (state, action) => {
      state.postalCode = action.payload
    })
    .addCase("otp/updateSenderName", (state, action) => {
      state.senderName = action.payload
    })
    .addCase("otp/updateSenderEmail", (state, action) => {
      state.senderEmail = action.payload
    })
    .addCase("otp/updateBeneficiaryName", (state, action) => {
      state.beneficiaryName = action.payload
    })
    .addCase("otp/updateBeneficiaryEmail", (state, action) => {
      state.beneficiaryEmail = action.payload
    })
    .addCase("otp/updateSummary", (state, action) => {
      state.summary = action.payload
    })
    .addCase("otp/tempOffset", (state, action) => {
      state.totalCarbonOffset = action.payload
    })
    .addCase("otp/tempCustom", (state, action) => {
      state.totalCustomAmount = action.payload
    })
    .addCase("otp/updatePaymentInfo", (state, action) => {
      state.paymentInfo = action.payload
    })
    .addCase("otp/closeModal", (state, action) => {
      state.userAction = ""
      state.country = {}
      state.senderName = ""
      state.senderEmail = ""
      state.beneficiaryEmail = ""
      state.beneficiaryName = ""
      state.summary = {}
      state.totalCarbonOffset = ""
      state.totalCustomAmount = ""
      state.postalCode = ""
      state.paymentInfo = {}
    })
    .addDefaultCase((state, action) => state)
})
