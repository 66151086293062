import * as actionTypes from "../action-types"
import axios from "@/utils/request.js"
import to from "await-to-js"
import { toastError } from "./axiosErrorActions"
import { ToggleAccountOnHoldModal, TogglePayOnHoldModal, ToggleSuccessModal, ToggleCountrySelection } from "./modalActions"

let token = sessionStorage.getItem("access_token")

const multipartHeader = {
  headers: {
    Authorization: `Bearer ${token}`,
    "content-type": "multipart/form-data",
  },
}

export function emailLogin(data) {
  // return function (dispatch) {
  // 	dispatch({ type: actionTypes.LOGIN });
  // };
  return (dispatch) =>
    new Promise(async (resolve, reject) => {
      try {
        dispatch({ type: actionTypes.SHOW_LOADING, payload: true })
        let res = await axios.post(`/account/login/email`, data)
        if (res.code === "PASSWORD_ERROR") {
          dispatch(toastError(res.message))
          reject()
        } else {
          sessionStorage.setItem("access_token", res.data.accessToken)
          sessionStorage.setItem("refresh_token", res.data.refreshToken)
          dispatch(getProfile())
          dispatch(getMySubscription())
          dispatch({ type: actionTypes.LOGIN, payload: res.data })
          resolve()
        }
      } catch (error) {
      } finally {
        dispatch({ type: actionTypes.HIDE_LOADING, payload: false })
      }
    })
}

export function logout() {
  return function (dispatch) {
    sessionStorage.clear()
    dispatch({ type: actionTypes.LOGOUT })
  }
}

export function sendVerificationCode(data) {
  return (dispatch) =>
    new Promise(async (resolve, reject) => {
      try {
        dispatch({ type: actionTypes.SHOW_LOADING, payload: true })
        let res = await axios.post(`/account/register/verify/v1-1`, data)
        dispatch({ type: actionTypes.SEND_VERIFICATION_TOKEN, payload: res.data })
        resolve()
      } catch (error) {
      } finally {
        dispatch({ type: actionTypes.HIDE_LOADING, payload: false })
      }
    })
}

export function resendVerificationCode(data) {
  return (dispatch) =>
    new Promise(async (resolve, reject) => {
      try {
        dispatch({ type: actionTypes.SHOW_LOADING, payload: true })
        let res = await axios.post(`/account/register/resend/v1-1`, data)
        dispatch({ type: actionTypes.SEND_VERIFICATION_TOKEN, payload: res.data })
        resolve()
      } catch (error) {
      } finally {
        dispatch({ type: actionTypes.HIDE_LOADING, payload: false })
      }
    })
}

export function registerVerifyCode(data) {
  return (dispatch) =>
    new Promise(async (resolve, reject) => {
      try {
        dispatch({ type: actionTypes.SHOW_LOADING, payload: true })
        let res = await axios.post(`/account/register/verify-code`, data)
        if (res.code === "INVALID_CODE") {
          dispatch(toastError(res.message))
          reject()
        } else {
          dispatch({
            type: actionTypes.VERIFY_REGISTRY_VERIFICATION_CODE,
            payload: data,
          })
          resolve()
        }
      } catch (error) {
      } finally {
        dispatch({ type: actionTypes.HIDE_LOADING, payload: false })
      }
    })
}

export function saveIncompleteUser(data) {
  return (dispatch) =>
    new Promise(async (resolve, reject) => {
      try {
        dispatch({ type: actionTypes.SHOW_LOADING, payload: true })
        let res = await axios.post(`/account/save-incomplete-signup`, data)
        resolve(res)
      } catch (error) {
      } finally {
        dispatch({ type: actionTypes.HIDE_LOADING, payload: false })
      }
    })
}

export function loadStripeConfig() {
  return async (dispatch) => {
    try {
      dispatch({ type: actionTypes.SHOW_LOADING, payload: true })
      let res = await axios.get(`/stripe-config`)
      dispatch({ type: actionTypes.LOAD_STRIPE, payload: res.data })
    } catch (error) {
    } finally {
      dispatch({ type: actionTypes.HIDE_LOADING, payload: false })
    }
  }
}

export function saveStripePromise(stripePromise) {
  return function (dispatch) {
    dispatch({ type: actionTypes.SAVE_STRIPE_PROMISE, payload: stripePromise })
  }
}

export function savePersonalInfo(data) {
  return function (dispatch) {
    dispatch({ type: actionTypes.SAVE_PERSONAL_INFO, payload: data })
  }
}

export function savePromoCode(data) {
  return function (dispatch) {
    dispatch({ type: actionTypes.SAVE_PROMO_CODE, payload: data })
  }
}

export function savePassword(data) {
  return function (dispatch) {
    dispatch({ type: actionTypes.SAVE_PASSWORD, payload: data })
  }
}

export function saveNumberOfEmployees(data) {
  return function (dispatch) {
    dispatch({ type: actionTypes.SAVE_NUM_OF_EMPLOYEES, payload: data })
  }
}

export function saveBusinessName(data) {
  return function (dispatch) {
    dispatch({ type: actionTypes.SAVE_BUSINESS_NAME, payload: data })
  }
}

export function registerEmail(data) {
  return (dispatch) =>
    new Promise(async (resolve, reject) => {
      try {
        dispatch({ type: actionTypes.SHOW_LOADING, payload: true })
        let res = await axios.post(`/account/register/email`, data)
        dispatch({ type: actionTypes.REGISTER_EMAIL, payload: res.data })
        resolve(res)
      } catch (error) {
      } finally {
        dispatch({ type: actionTypes.HIDE_LOADING, payload: false })
      }
    })
}

export function clearSignupInfo() {
  return function (dispatch) {
    dispatch({ type: actionTypes.CLEAR_SIGNUP_INFO })
  }
}

export function forgetPassword(data) {
  return (dispatch) =>
    new Promise(async (resolve, reject) => {
      try {
        dispatch({ type: actionTypes.SHOW_LOADING, payload: true })
        let res = await axios.post(`/account/password/forgot/v1-1`, data)
        if (res.data) {
          dispatch({ type: actionTypes.FORGET_PASSWORD, payload: data.email })
          resolve()
        } else {
          dispatch(toastError(res.message))
          reject()
        }
      } catch (error) {
      } finally {
        dispatch({ type: actionTypes.HIDE_LOADING, payload: false })
      }
    })
}

export function setResetPasswordVerificationCode(data) {
  return (dispatch) =>
    new Promise(async (resolve, reject) => {
      try {
        dispatch({ type: actionTypes.SHOW_LOADING, payload: true })
        let res = await axios.post(`/account/password/verify-code`, data)
        if (res.code === "INVALID_CODE") {
          dispatch(toastError(res.message))
          reject()
        } else {
          dispatch({
            type: actionTypes.VALIDATE_RESET_PASSWORD_CODE,
            payload: data,
          })
          resolve()
        }
      } catch (error) {
        reject()
      } finally {
        dispatch({ type: actionTypes.HIDE_LOADING, payload: false })
      }
    })
}

export function getAbout() {
  // service-agreements/terms-of-service

  return async (dispatch) => {
    try {
      dispatch({ type: actionTypes.SHOW_LOADING, payload: true })
      let res = await axios.get(`/service-agreements/about`)
      dispatch({
        type: actionTypes.GET_ABOUT,
        payload: res.data.cultureBasedContent.content,
      })
    } catch (error) {
    } finally {
      dispatch({ type: actionTypes.HIDE_LOADING, payload: false })
    }
  }
}

export function getTermsOfService() {
  // service-agreements/terms-of-service

  return async (dispatch) => {
    try {
      dispatch({ type: actionTypes.SHOW_LOADING, payload: true })
      let res = await axios.get(`/service-agreements/terms-of-service`)
      dispatch({
        type: actionTypes.GET_TERMS_OF_SERVICE,
        payload: res.data,
      })
    } catch (error) {
    } finally {
      dispatch({ type: actionTypes.HIDE_LOADING, payload: false })
    }
  }
}

export function getPrivacyPolicy() {
  return async (dispatch) => {
    try {
      dispatch({ type: actionTypes.SHOW_LOADING, payload: true })
      let res = await axios.get(`/service-agreements/privacy-policy`)
      dispatch({
        type: actionTypes.GET_PRIVACY_POLICY,
        payload: res.data,
      })
    } catch (error) {
    } finally {
      dispatch({ type: actionTypes.HIDE_LOADING, payload: false })
    }
  }
}

export function resetPasswordByToken(data) {
  return (dispatch) =>
    new Promise(async (resolve, reject) => {
      try {
        dispatch({ type: actionTypes.SHOW_LOADING, payload: true })
        let res = await axios.post(`/account/password/reset`, data)
        dispatch({ type: actionTypes.RESET_PASSWORD_BY_TOKEN, payload: res })
        resolve()
      } catch (error) {
      } finally {
        dispatch({ type: actionTypes.HIDE_LOADING, payload: false })
      }
    })
}

export function resetForgetPassword() {
  return function (dispatch) {
    dispatch({ type: actionTypes.RESET_FORGET_PASSWORD, payload: data })
  }
}

export function updateTokens(data) {
  return function (dispatch) {
    dispatch({ type: actionTypes.UPDATE_TOKEN, payload: data })
  }
}

export function signupWithSocial(data) {
  return (dispatch) =>
    new Promise(async (resolve, reject) => {
      try {
        dispatch({ type: actionTypes.SHOW_LOADING, payload: true })
        let res = await axios.post(`/account/register/social`, data)
        // dispatch({ type: actionTypes.SIGNUP_WITH_GOOGLE, payload: res });
        resolve(res)
      } catch (error) {
      } finally {
        dispatch({ type: actionTypes.HIDE_LOADING, payload: false })
      }
    })
}

export function signinWithSocial(data) {
  return (dispatch) =>
    new Promise(async (resolve, reject) => {
      try {
        dispatch({ type: actionTypes.SHOW_LOADING, payload: true })
        let res = await axios.post(`/account/login/social`, data)
        // dispatch({ type: actionTypes.SIGNUP_WITH_GOOGLE, payload: res });
        sessionStorage.setItem("access_token", res.data.accessToken)
        sessionStorage.setItem("refresh_token", res.data.refreshToken)
        dispatch(getProfile())
        dispatch(getMySubscription())
        dispatch({ type: actionTypes.LOGIN, payload: res.data })
        resolve(res.data)
      } catch (error) {
      } finally {
        dispatch({ type: actionTypes.HIDE_LOADING, payload: false })
      }
    })
}

export function getSocialTokenData(data) {
  return (dispatch) =>
    new Promise(async (resolve, reject) => {
      try {
        dispatch({ type: actionTypes.SHOW_LOADING, payload: true })
        let res = await axios.post(`/account/tokenData`, data)
        // dispatch({ type: actionTypes.SIGNUP_WITH_GOOGLE, payload: res });
        resolve(res.data)
      } catch (error) {
      } finally {
        dispatch({ type: actionTypes.HIDE_LOADING, payload: false })
      }
    })
}

export function getProfile() {
  return (dispatch) =>
    new Promise(async (resolve, reject) => {
      try {
        dispatch({ type: actionTypes.SHOW_LOADING, payload: true })
        let res = await axios.get(`/profile/me/v1-1`)
        dispatch({ type: actionTypes.GET_PROFILE, payload: res.data })
        resolve()
      } catch (error) {
      } finally {
        dispatch({ type: actionTypes.HIDE_LOADING, payload: false })
      }
    })
}

export function updateProfile(data) {
  return (dispatch) => {
    return new Promise(async (resolve, reject) => {
      try {
        dispatch({ type: actionTypes.SHOW_LOADING, payload: true })
        let res = await axios.put(`/profile`, data)
        dispatch({ type: actionTypes.UPDATE_PROFILE, payload: res.data })
        resolve(res)
      } catch (error) {
      } finally {
        dispatch({ type: actionTypes.HIDE_LOADING, payload: false })
      }
    })
  }
}

export function uploadProfilePic(data) {
  return (dispatch) => {
    return new Promise(async (resolve, reject) => {
      try {
        dispatch({ type: actionTypes.SHOW_LOADING, payload: true })
        let res = await axios.post(`/files/upload-file`, data, multipartHeader)
        resolve(res.data)
      } catch (error) {
      } finally {
        dispatch({ type: actionTypes.HIDE_LOADING, payload: false })
      }
    })
  }
}

export function changePassword(data) {
  return (dispatch) => {
    return new Promise(async (resolve, reject) => {
      try {
        dispatch({ type: actionTypes.SHOW_LOADING, payload: true })
        let res = await axios.post(`/account/password/change`, data)
        resolve()
      } catch (error) {
      } finally {
        dispatch({ type: actionTypes.HIDE_LOADING, payload: false })
      }
    })
  }
}

export function getMySubscription() {
  return async (dispatch) => {
    try {
      dispatch({ type: actionTypes.SHOW_LOADING, payload: true })
      let res = await axios.get(`/profile/my-subscription`)
      if (res.data?.needsCountryUpdate) {
        dispatch(ToggleCountrySelection(true))
        return
      } else {
        dispatch(ToggleCountrySelection(false))
      }
      dispatch({ type: actionTypes.GET_MY_SUBSCRIPTION, payload: res.data })
      if (res.data?.status === "OnHold") {
        dispatch(ToggleAccountOnHoldModal(true))
        dispatch(TogglePayOnHoldModal(false))
      } else if (res?.data?.status === "SubscriptionPendingAuthorization") {
        dispatch(TogglePayOnHoldModal(true))
        dispatch(ToggleAccountOnHoldModal(false))
      } else if (res.data?.status === "Active") {
        dispatch(TogglePayOnHoldModal(false))
        dispatch(ToggleAccountOnHoldModal(false))
      }
      return res.data
    } catch (error) {
    } finally {
      dispatch({ type: actionTypes.HIDE_LOADING, payload: false })
    }
  }
}

export function validateExisting(data) {
  return (dispatch) => {
    return new Promise(async (resolve, reject) => {
      try {
        dispatch({ type: actionTypes.SHOW_LOADING, payload: true })
        let res = await axios.post(`/account/validate-existing`, data)
        resolve(res.data.accountExists)
      } catch (error) {
      } finally {
        dispatch({ type: actionTypes.HIDE_LOADING, payload: false })
      }
    })
  }
}

export function deleteProfile() {
  return (dispatch) => {
    return new Promise(async (resolve, reject) => {
      try {
        dispatch({ type: actionTypes.SHOW_LOADING, payload: true })
        await axios.delete(`/profile`)
        resolve()
      } catch (error) {
      } finally {
        dispatch({ type: actionTypes.HIDE_LOADING, payload: false })
      }
    })
  }
}

export function deleteProfilePicture() {
  return (dispatch) => {
    return new Promise(async (resolve, reject) => {
      try {
        dispatch({ type: actionTypes.SHOW_LOADING, payload: true })
        await axios.delete(`/profile/picture`)
        resolve()
      } catch (error) {
      } finally {
        dispatch({ type: actionTypes.HIDE_LOADING, payload: false })
      }
    })
  }
}

export function updateProfileBadgeAsRead(id) {
  return (dispatch) => {
    return new Promise(async (resolve, reject) => {
      try {
        dispatch({ type: actionTypes.SHOW_LOADING, payload: true })

        let res = await axios.put(`/profile/badges/${id}/read`)

        dispatch(getProfile())
        resolve()
      } catch (error) {
      } finally {
        dispatch({ type: actionTypes.HIDE_LOADING, payload: false })
      }
    })
  }
}

export function getBadgeImg(id) {
  return (dispatch) => {
    return new Promise(async (resolve, reject) => {
      try {
        dispatch({ type: actionTypes.SHOW_LOADING, payload: true })

        let res = await axios({
          url: `/profile/badge/${id}`,
          method: "get",
          responseType: "blob",
        })
        console.log(res)
        resolve(res)
      } catch (error) {
      } finally {
        dispatch({ type: actionTypes.HIDE_LOADING, payload: false })
      }
    })
  }
}

export function getCountries() {
  return async (dispatch, getState) => {
    dispatch({ type: actionTypes.SHOW_LOADING, payload: true })
    let [err, response] = await to(axios.get(`/country`))
    if (err) {
      dispatch({ type: actionTypes.HIDE_LOADING, payload: false })
      return Promise.reject(err.message)
    }
    dispatch({ type: actionTypes.HIDE_LOADING, payload: false })
    return Promise.resolve(response)
  }
}

export function validatePostalCode(data) {
  return async (dispatch, getState) => {
    dispatch({ type: actionTypes.SHOW_LOADING, payload: true })
    let [err, response] = await to(axios.post(`/country/validate`, data))
    if (err) {
      dispatch({ type: actionTypes.HIDE_LOADING, payload: false })
      return Promise.reject(err.message)
    }
    dispatch({ type: actionTypes.HIDE_LOADING, payload: false })
    return Promise.resolve(response)
  }
}
