import React from "react"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { ToggleReSubscriptionModal } from "../../store/actions/modalActions"

export default function ReSubscribeModal({}) {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  return (
    <>
      <div className="justify-centeritems-center flex overflow-x-hidden items-center overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative w-auto my-6 mx-auto max-w-3xl">
          {/*content*/}
          <div className="border-0 rounded-lg rounded shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            {/*body*/}
            <div className="relative bg-lighter-grey max-w-[872px] h-fit rounded py-4">
              <div className="flex text-[30px] whitespace-pre justify-start mx-8">
                <div className="font-bold">Re-subscribe?</div>
              </div>
              <div className="text-base mt-2 mx-8">Seems like don't have a subscription. Would you like to re-subscribe to Karbon-X?</div>
              <div className="flex justify-end mx-8 gap-2">
                <div className="grid justify-center mt-[32px]">
                  <div
                    onClick={() => {
                      dispatch(ToggleReSubscriptionModal())
                    }}
                    className="border-2 font-semibold text-base laptop:w-[232px] px-10 h-[54px] rounded-full grid justify-center items-center cursor-pointer ">
                    Cancel
                  </div>
                </div>
                <div className="grid justify-center mt-[32px]">
                  <div
                    onClick={() => {
                      dispatch(ToggleReSubscriptionModal())
                      navigate("/settings/subscription")
                    }}
                    className="bg-orange_v2 text-white font-semibold text-base laptop:w-[232px] px-10 h-[54px] rounded-full grid justify-center items-center cursor-pointer ">
                    Ok
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  )
}
