let ApiURL = "";
console.log("process.env.MODE:::", process.env.MODE);
if (process.env.MODE === "development") {
	ApiURL = "https://api.staging.karbon-x.com/api/v1";
}
if (process.env.MODE === "production") {
	ApiURL = process.env.BASE_URL;
}
if (process.env.MODE === "uat") {
	ApiURL = process.env.BASE_URL;
}
export const baseURL = ApiURL;
