import React, { useEffect, useState } from "react"
import closeIcon from "@/assets/svg/icon_close_orange.svg"
import { useDispatch } from "react-redux"
import { ToggleCancelSubscriptionFeedbackModal, ToggleCancelSubscriptionModal } from "../../store/actions/modalActions"
import { getNextBillingDate } from "../../store/actions/subscriptionsActions"

export default function CancelSubscriptionModal() {
  const dispatch = useDispatch()
  const [nextBillingDate, setNextBillingDate] = useState("")
  useEffect(() => {
    dispatch(getNextBillingDate()).then((res) => {
      setNextBillingDate(res?.nextBillingDate)
    })
  }, [])
  return (
    <div className="w-screen h-screen justify-center flex overflow-x-hidden items-center overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-darker-grey/60">
      <div className="flex flex-col bg-lighter-grey w-[872px] py-2 rounded">
        <div className="flex justify-end">
          <img src={closeIcon} alt="closeIcon" className="cursor-pointer" onClick={() => dispatch(ToggleCancelSubscriptionModal())} />
        </div>
        <div className="px-8 -mt-3 uppercase">
          <div className="font-bold text-[30px]">Are you sure you want to cancel your offset?</div>
          <div className="text-sm text-[#788088] mt-4">If you cancel offset, you will no longer have access to Karbon-X projects .</div>
          <div className="bg-[#FDEFED] h-[60px] px-4 text-sm items-center flex justify-center text-[#E9664E] rounded-8 mt-4">
            You will be offsetting until the last day of billing cycle and offset will be cancelled at the next billing cycle.
          </div>
          <div className="flex justify-end mt-8">
            <div
              className="bg-[#E9664E] cursor-pointer flex justify-center items-center rounded-full  py-2 px-4 text-white text-semibold text-base "
              onClick={() => {
                dispatch(ToggleCancelSubscriptionModal())
                dispatch(ToggleCancelSubscriptionFeedbackModal())
                // dispatch(cancelSubscription(mySubscription?.id)).then(() => {
                // 	dispatch(ToggleCancelSubscriptionModal());
                // 	// navigate("/");
                // });
              }}>
              Cancel
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
