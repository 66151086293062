import React from "react";
import { useState } from "react";
import Select, { components } from "react-select";
import up_icon from "@/assets/img/icon_chevron_up.svg";
import down_icon from "@/assets/img/icon_chevron_down.svg";
import { capitalizeCreditCard } from "@/utils/helper";
import { cardIcons } from "@/index.js";

const CustomOption = (props) => {
	const { data, innerRef, innerProps } = props;
	return (
		<div ref={innerRef} {...innerProps} className="bg-mist-grey cursor-pointer">
			<div className="flex justify-between hover:bg-winter-grey h-14 rounded items-center mx-2 px-1">
				<div className="flex items-center">
					<div>
						<img
							src={cardIcons(data?.cardBrand)}
							alt="masterCardIcon"
							className="h-[44px] w-[44px]"
						/>
					</div>
					<div className="flex whitespace-pre pl-4">
						{capitalizeCreditCard(data.cardBrand)} ending in{" "}
						<div className="font-bold">{data.cardLast4}</div>
					</div>
				</div>
			</div>
		</div>
	);
};

const DropdownIndicator = (props) => {
	return (
		components.DropdownIndicator && (
			<components.DropdownIndicator
				{...props}
				className="w-[44px] bg-mist-grey h-full rounded"
			>
				<img
					src={props.selectProps.menuIsOpen ? up_icon : down_icon}
					alt="menuIsOpen"
				/>
			</components.DropdownIndicator>
		)
	);
};

// const formatOptionLabel = ({ cardEnding }) => (
// 	<div className="bg-mist-grey">
// 		<div className="flex justify-between h-14 rounded items-center mx-2 px-1">
// 			<div className="flex items-center">
// 				<div>
// 					<img src={masterCardIcon} />
// 				</div>
// 				<div className="flex whitespace-pre pl-4">
// 					MasterCard ending in <div className="font-bold">{cardEnding}</div>
// 				</div>
// 			</div>
// 		</div>
// 	</div>
// );

const ValueContainer = ({ children, ...props }) => {
	const { getValue, hasValue } = props;
	const card = getValue()[0];
	// console.log(getValue());
	if (!hasValue) {
		return (
			<components.ValueContainer {...props}>{children}</components.ValueContainer>
		);
	}
	return (
		<components.ValueContainer {...props} className="bg-mist-grey">
			<div className="bg-mist-grey rounded w-full">
				<div className="flex justify-between h-14 items-center">
					<div className="flex items-center bg-mist-grey">
						<div>
							<img
								src={cardIcons(card?.cardBrand)}
								alt="masterCardIcon"
								className="h-[44px] w-[44px]"
							/>
						</div>
						{card && (
							<div className="flex whitespace-pre pl-2">
								{capitalizeCreditCard(card?.cardBrand)} ending in{" "}
								<div className="font-bold">{card?.cardLast4}</div>
							</div>
						)}
						{children}
					</div>
				</div>
			</div>
		</components.ValueContainer>
	);
};

// {
// 	id: "pm_1MCAelGph0qNfkkKo3HUbKew",
// 	userId: null,
// 	cardLast4: "4444",
// 	cardBrand: "mastercard",
// 	expiryYear: 2044,
// 	expiryMonth: 4,
// 	isDefault: false,
// 	createdAt: "0001-01-01T00:00:00.000Z",
// 	updatedAt: "0001-01-01T00:00:00.000Z",
// }

export default function SelectComponent({
	setSelected,
	selected,
	options,
	className,
}) {
	const [isOpen, setIsOpen] = useState(false);
	return (
		<div className="">
			<Select
				onChange={setSelected}
				placeholder="Please select a payment method"
				value={selected}
				// defaultValue={selected}
				className={className}
				// formatOptionLabel={formatOptionLabel}
				onClick={() => {
					setIsOpen(!isOpen);
				}}
				isSearchable={false}
				components={{
					Option: CustomOption,
					DropdownIndicator,
					IndicatorSeparator: () => null,
					ValueContainer,
				}}
				options={options}
			></Select>
		</div>
	);
}
