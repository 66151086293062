import React, { useState, useRef, useEffect, memo } from "react"
import background from "@/assets/img/BM-background.jpg"
import Joi from "joi"
import { connect } from "react-redux"
import { produce } from "immer"
import { useNavigate } from "react-router-dom"
import InputField from "@/components/InputField"
import { toast } from "react-toastify"
// import Autocomplete from "@mui/material/Autocomplete"
import Autocomplete from "react-google-autocomplete"
import GooglePlacesAutocomplete from "react-google-places-autocomplete"
import { TextField } from "@mui/material"
import Button from "@mui/material/Button"
import Checkbox from "@mui/material/Checkbox"
import FormGroup from "@mui/material/FormGroup"
import FormControlLabel from "@mui/material/FormControlLabel"
import FormControl from "@mui/material/FormControl"
import FormLabel from "@mui/material/FormLabel"
import InputLabel from "@mui/material/InputLabel"
import MenuItem from "@mui/material/MenuItem"
import Select from "@mui/material/Select"
import ButtonGroup from "@mui/material/ButtonGroup"
import AddIcon from "@mui/icons-material/Add"
import RemoveIcon from "@mui/icons-material/Remove"
import * as actions from "@/store/actions/banffMarathon"

const initialState = {
  name: "",
  email: "",
  originAddress: "",
  vehicleTypeId: "",
  numberOfPassengers: 1,
  selectedCarType: {},
  fuelType: "",
  vehicleType: "",
  province: "",
  google_place: "",
}
const schema = Joi.object({
  name: Joi.string().min(3).max(30).required(),
  email: Joi.string().email({
    minDomainSegments: 2,
    tlds: {
      allow: [
        "com",
        "net",
        "org",
        "edu",
        "gov",
        "mil",
        "int",
        "info",
        "biz",
        "name",
        "pro",
        "aero",
        "coop",
        "museu",
        "jobs",
        "travel",
        "tel",
        "cat",
        "mobi",
        "post",
        "ca",
      ],
    },
  }),
  originAddress: Joi.string().required().label("city"),
  vehicleTypeId: Joi.string().required().label("vehicle type"),
  fuelType: Joi.string().label("fuel type"),
  vehicleType: Joi.string().label("vehicle type"),
  numberOfPassengers: Joi.number(),
  selectedCarType: Joi.object(),
  province: Joi.string().allow(""),
  google_place: Joi.object(),
})

const index = (props) => {
  const navigate = useNavigate()
  const [data, setData] = useState(initialState)
  const [carTypes, setCarTypes] = useState([])

  useEffect(() => {
    props.getCarTypes().then((res) => setCarTypes(res?.vehicles))
    return () => setData(initialState)
  }, [])

  const calculate = async () => {
    const { error, value } = schema.validate(data)
    console.log(error, value)
    if (error) toast.error(error.message)
    else props.banffMarathonCalculator(data).then((res) => navigate("/banff-marathon-success", { state: res?.user }))
  }

  return (
    <div
      className="flex w-full h-screen "
      style={{
        backgroundImage: `url(${background})`,
        backgroundPosition: "center",
      }}>
      <div className="m-auto laptop:w-1/3 bg-white rounded-8">
        <div className="grid place-items-center w-full p-4">
          <div>
            <p className=" text-sm text-[#EA664E] laptop:text-base flex-wrap">Banff Marathon</p>
            <p className="font-bold  md:pr-28 desktop:pr-40 text-xl laptop:text-4xl  relative leading-tight">Carbon Emissions Calculator</p>
            <p className=" text-sm  laptop:text-base flex-wrap">
              Calculate your carbon emissions to know your environmental impact and take meaningful actions towards sustainability.
            </p>
            <hr className="my-4"></hr>
            <p className="font-bold text-[14px] my-2 relative leading-tight">Name</p>
            <TextField
              autoComplete="new-password"
              value={data?.name}
              className="bg-[#EFF1F2] "
              onChange={(event) => {
                console.log(event.target.value)
                setData(
                  produce(data, (draft) => {
                    draft.name = event.target.value
                  })
                )
              }}
              sx={{
                input: {
                  color: "black",
                  "&::placeholder": {
                    opacity: 1,
                  },
                },
                label: { color: "blue" },
              }}
              fullWidth
              placeholder="Enter your name"
              id="outlined-basic"
              size="small"
              variant="outlined"
            />
            <p className="font-bold text-[14px] relative leading-tight mt-6 mb-2">Email address</p>
            <TextField
              autoComplete="new-password"
              value={data?.email}
              className="bg-[#EFF1F2] "
              onChange={(event) =>
                setData(
                  produce(data, (draft) => {
                    draft.email = event.target.value
                  })
                )
              }
              sx={{
                input: {
                  color: "black",
                  "&::placeholder": {
                    opacity: 1,
                  },
                },
                label: { color: "blue" },
              }}
              fullWidth
              placeholder="Enter your email address"
              id="outlined-basic"
              size="small"
            />
            <p className="font-bold text-[14px] mt-6 mb-3 relative leading-tight">City Traveled From </p>
            {/* <Autocomplete
              value={data?.originAddress}
              onChange={(event) => {
                setData(
                  produce(data, (draft) => {
                    draft.originAddress = event.target.value
                  })
                )
              }}
              className="bg-[#EFF1F2] w-full p-2 rounded-[4px] border border-gray-400 placeholder:text-black"
              apiKey="AIzaSyBQ-1LfBDKmX6ne7Ix6y-uLk7NsAAJET5g"
              onPlaceSelected={(place) => {
                let province = ""
                if (place?.address_components[2]?.short_name == "CA") province = place?.address_components[1]?.short_name
                else province = "N/A"
                setData((data) => ({ ...data, originAddress: place?.formatted_address, province, google_place: place }))
              }}
            /> */}

            <GooglePlacesAutocomplete
              apiKey="AIzaSyBQ-1LfBDKmX6ne7Ix6y-uLk7NsAAJET5g"
              autocompletionRequest={{
                types: ["(cities)"],
              }}
              selectProps={{
                placeholder: <div className="text-black">Enter the city traveled from</div>,
                styles: {
                  control: (styles, state) => ({
                    ...styles,
                    backgroundColor: "#EFF1F2",
                    border: state.isFocused ? "none" : "1px solid #acacad",
                    // borderColor: "1px solid #EFF1F2",
                    outline: state.isFocused ? "2px solid #E9664E" : "1px solid #EFF1F2",
                  }),
                },
                value: data?.google_place,
                onChange: (place) =>
                  setData(
                    produce(data, (draft) => {
                      draft.originAddress = place?.label
                      draft.google_place = place
                      if (place?.label.includes("Canada")) draft.province = place?.value?.terms[1]?.value
                      else draft.province = "N/A"
                    })
                  ),
              }}
            />
            <div className="grid grid-cols-2 space-x-2 mt-6 mb-2">
              <div className="font-bold text-[14px]">Car Class</div>
              <div className="font-bold text-[14px]">Number of passenger(s)</div>
            </div>
            <div className="grid grid-cols-2 space-x-2">
              <FormControl className="bg-[#EFF1F2]" fullWidth size="small">
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={data?.vehicleTypeId}
                  onChange={(event) => {
                    setData(
                      produce(data, (draft) => {
                        draft.vehicleTypeId = event.target.value
                        let selectedCarType = carTypes.find(({ id }) => id === event.target.value)
                        console.log(selectedCarType)
                        draft.vehicleType = selectedCarType.type
                        draft.selectedCarType = selectedCarType
                        draft.numberOfPassengers = 1
                        draft.fuelType = selectedCarType?.fuelTypes[0]
                        if (selectedCarType?.type == "EV") draft.fuelType = "N/A"
                      })
                    )
                  }}>
                  {carTypes.map((item) => {
                    return (
                      <MenuItem key={item.id} className="capitalize" value={item.id}>
                        {item?.type}
                      </MenuItem>
                    )
                  })}
                </Select>
              </FormControl>
              <ButtonGroup disabled={data?.selectedCarType?.maxPassengers == 30} fullWidth aria-label="Basic button group">
                <Button
                  variant="contained"
                  onClick={() => {
                    setData(
                      produce(data, (draft) => {
                        if (draft.numberOfPassengers > 1) draft.numberOfPassengers = draft.numberOfPassengers - 1
                      })
                    )
                  }}
                  sx={{
                    backgroundColor: "#EFF1F2",
                    color: "black",
                    fontWeight: "bold",
                  }}>
                  <RemoveIcon />
                </Button>
                <Button
                  variant="text"
                  sx={{
                    backgroundColor: "#fff",
                    color: "black",
                    fontWeight: "bold",
                  }}>
                  {data?.numberOfPassengers}
                </Button>
                <Button
                  variant="contained"
                  onClick={() => {
                    setData(
                      produce(data, (draft) => {
                        if (draft.numberOfPassengers < data?.selectedCarType?.maxPassengers) draft.numberOfPassengers = draft.numberOfPassengers + 1
                      })
                    )
                  }}
                  sx={{
                    backgroundColor: "#EFF1F2",
                    color: "black",
                    fontWeight: "bold",
                  }}>
                  <AddIcon />
                </Button>
              </ButtonGroup>
            </div>
            {data?.selectedCarType?.fuelTypes?.length > 0 && <p className="font-bold text-[14px] mt-6 mb-3 relative leading-tight">Fuel Type</p>}
            <div className="grid  grid-cols-2">
              {data?.selectedCarType &&
                data?.selectedCarType?.fuelTypes?.map((item) => {
                  return (
                    <FormControlLabel
                      onChange={(event) =>
                        setData(
                          produce(data, (draft) => {
                            draft.fuelType = event.target.value
                          })
                        )
                      }
                      key={item}
                      checked={data?.fuelType == item}
                      value={item}
                      control={<Checkbox />}
                      label={item}
                      labelPlacement="end"
                    />
                  )
                })}
            </div>
          </div>
        </div>
        <div className="flex justify-end m-2">
          <div className="space-x-2">
            <Button
              onClick={() => setData(initialState)}
              sx={{
                borderRadius: 999,
                textTransform: "none",
                fontWeight: "bold",
              }}
              autoCapitalize="true"
              variant="outlined">
              Clear Fields
            </Button>
            <Button
              onClick={calculate}
              sx={{
                borderRadius: 999,
                textTransform: "none",
                fontWeight: "bold",
              }}
              autoCapitalize="true"
              variant="contained">
              Calculate
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default connect(null, actions)(index)
