import * as actionTypes from "../action-types";

let initialState = { notificationSettings: null };

function reducer(state = initialState, action) {
	switch (action.type) {
		case actionTypes.GET_NOTIFICATION_SETTING:
			return { ...state, notificationSettings: action.payload };

		default:
			return state;
	}
}
export default reducer;
