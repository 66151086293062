export const Energy = "Energy";
export const Air = "Air";
export const Earth = "Earth";
export const Water = "Water";
export const Innovate = "Innovate";

// export const Energy = "Biomass";
// export const Air = "Forestry";
// export const Earth = "Soil";
// export const Water = "Oceans";
// export const Innovate = "Clean Energy";
