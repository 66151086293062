import React from "react"
import { useDispatch } from "react-redux"
import { ToggleMarkAllAsReadModal } from "@/store/actions/modalActions"
import { markAllNotificationAsRead } from "../../store/actions/notifications"
import icon_close_green from "@/assets/img/icon_close_orange.svg"
export default function MarkAllAsReadModal({}) {
  const dispatch = useDispatch()

  return (
    <>
      <div className="justify-centeritems-center flex overflow-x-hidden items-center overflow-y-auto fixed inset-0 z-[100] outline-none focus:outline-none">
        <div className="relative w-auto my-6 mx-auto max-w-3xl">
          {/*content*/}
          <div className="border-0 rounded-lg rounded shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            {/*body*/}
            <div className="relative bg-lighter-grey max-w-[600px] laptop:w-[600px] px-8 h-fit pb-8 rounded">
              <div className="flex justify-end mt-3 mr-4">
                <img
                  src={icon_close_green}
                  onClick={() => {
                    dispatch(ToggleMarkAllAsReadModal())
                  }}
                  className="cursor-pointer"></img>
              </div>
              <div className="text-center text-[30px] justify-center mt-4">
                <div className="font-bold">Mark as read?</div>
              </div>
              <div className="text-base text-darker-grey/60 text-center mt-2">Are you sure you want to mark all notifications as read?</div>

              <div className="grid justify-center mt-[32px]">
                <div
                  onClick={() => {
                    dispatch(markAllNotificationAsRead()).then(() => {
                      dispatch(ToggleMarkAllAsReadModal())
                    })
                  }}
                  className="bg-green text-white font-semibold text-base w-[252px] h-[54px] rounded grid justify-center items-center cursor-pointer">
                  Confirm
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  )
}
