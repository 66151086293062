export const SHOW_LOADING = "SHOW_LOADING"
export const HIDE_LOADING = "HIDE_LOADING"

// Service Agreements
export const GET_TERMS_OF_SERVICE = "GET_TERMS_OF_SERVICE"
export const GET_PRIVACY_POLICY = "GET_PRIVACY_POLICY"
export const GET_ABOUT = "GET_ABOUT"

// accounts:
export const LOAD_STRIPE = "LOAD_STRIPE"
export const LOGOUT = "LOGOUT"
export const LOGIN = "LOGIN"
export const SEND_VERIFICATION_TOKEN = "SEND_VERIFICATION_TOKEN"
export const SAVE_PERSONAL_INFO = "SAVE_PERSONAL_INFO"
export const SAVE_SIGNUP_COUNTRY_POSTALCODE = "SAVE_SIGNUP_COUNTRY_POSTALCODE"
export const CLEAR_SIGNUP_INFO = "CLEAR_SIGNUP_INFO"
export const SAVE_PASSWORD = "SAVE_PASSWORD"
export const SAVE_NUM_OF_EMPLOYEES = "SAVE_NUM_OF_EMPLOYEES"
export const SAVE_BUSINESS_NAME = "SAVE_BUSINESS_NAME"
export const REGISTER_EMAIL = "REGISTER_EMAIL"
export const FORGET_PASSWORD = "FORGET_PASSWORD"
export const VALIDATE_RESET_PASSWORD_CODE = "VALIDATE_RESET_PASSWORD_CODE"
export const VERIFY_REGISTRY_VERIFICATION_CODE = "VERIFY_REGISTRY_VERIFICATION_CODE"
export const RESET_PASSWORD_BY_TOKEN = "RESET_PASSWORD_BY_TOKEN"
export const RESET_FORGET_PASSWORD = "RESET_FORGET_PASSWORD"
export const UPDATE_TOKEN = "UPDATE_TOKEN"
export const SIGNUP_WITH_GOOGLE = "SIGNUP_WITH_GOOGLE"
export const GET_PROFILE = "GET_PROFILE"
export const UPDATE_PROFILE = "UPDATE_PROFILE"
export const GET_MY_SUBSCRIPTION = "GET_MY_SUBSCRIPTION"
export const SAVE_STRIPE_PROMISE = "SAVE_STRIPE_PROMISE"
export const SAVE_PROMO_CODE = "SAVE_PROMO_CODE"

// modals
export const TOGGLE_SUCCESS_MODAL = "TOGGLE_SUCCESS_MODAL"
export const TOGGLE_REPLACEMENT_PROJECT_MODAL = "TOGGLE_REPLACEMENT_PROJECT_MODAL"
export const TOGGLE_NEW_CREDIT_CARD_MODAL = "TOGGLE_NEW_CREDIT_CARD_MODAL"
export const TOGGLE_ONE_TIME_SUPPORT_MODAL = "TOGGLE_ONE_TIME_SUPPORT_MODAL"
export const TOGGLE_EVENT_CODE_MODAL = "TOGGLE_EVENT_CODE_MODAL"
export const TOGGLE_CANCEL_SUBSCRIPTION_MODAL = "TOGGLE_CANCEL_SUBSCRIPTION_MODAL"
export const TOGGLE_REMOVE_PAYMENT_METHOD_MODAL = "TOGGLE_REMOVE_PAYMENT_METHOD_MODAL"
export const TOGGLE_CANCEL_SUBSCRIPTION_FEEDBACK_MODAL = "TOGGLE_CANCEL_SUBSCRIPTION_FEEDBACK_MODAL"
export const TOGGLE_MARK_ALL_AS_READ = "TOGGLE_MARK_ALL_AS_READ"
export const TOGGLE_REMOVE_NOTIFICATION = "TOGGLE_REMOVE_NOTIFICATION"
export const TOGGLE_DELETE_ACCOUNT = "TOGGLE_DELETE_ACCOUNT"
export const TOGGLE_COUNTRY_SELECTION = "TOGGLE_COUNTRY_SELECTION"
export const TOGGLE_ACCOUNT_ON_HOLD = "TOGGLE_ACCOUNT_ON_HOLD"
export const TOGGLE_PAY_ON_HOLD = "TOGGLE_PAY_ON_HOLD"
export const TOGGLE_FINISH_PAYMENT = "TOGGLE_FINISH_PAYMENT"
export const TOGGLE_RE_SUBSCRIBE = "TOGGLE_RE_SUBSCRIBE"
export const TOGGLE_CANCEL_EVENT = "TOGGLE_CANCEL_EVENT"
export const TOGGLE_SUBMIT_FEEDBACK = "TOGGLE_SUBMIT_FEEDBACK"
export const TOGGLE_SHOW_NOTIFICATION_DETAIL = "TOGGLE_SHOW_NOTIFICATION_DETAIL"
export const TOGGLE_DELETE_ALL_NOTIFICATIONS = "TOGGLE_DELETE_ALL_NOTIFICATIONS"

// projects:
export const FILTER_PROJECTS = "FILTER_PROJECTS"
export const GET_ALL_STRATEGIES = "GET_ALL_STRATEGIES"
export const GET_FILTERED_PROJECTES = "GET_FILTERED_PROJECTES"
export const GET_PROJECT = "GET_PROJECT"
export const GET_ALL_PROJECTS = "GET_ALL_PROJECTS"
export const GET_PROJECT_OF_THE_MONTH = "GET_PROJECT_OF_THE_MONTH"
export const GET_MY_PROJECTS = "GET_MY_PROJECTS"
export const UPDATE_SELECTED_STRATEGIES = "UPDATE_SELECTED_STRATEGIES"

// subscriptions:
export const GET_PERSONAL_SUBSCRIPTIONS = "GET_PERSONAL_SUBSCRIPTIONS"
export const GET_BUSINESS_SUBSCRIPTIONS = "GET_BUSINESS_SUBSCRIPTIONS"
export const SELECTED_SUBSCRIPTION = "SELECTED_SUBSCRIPTION"
export const SELECTED_BILLING_FREQUENCY = "SELECTED_BILLING_FREQUENCY"
export const GET_SUBSCRIPTIONS = "GET_SUBSCRIPTIONS"
export const GET_ORDER_HISTORY = "GET_ORDER_HISTORY"

// events:
export const GET_ALL_EVENTS = "GET_ALL_EVENTS"
export const GET_EVENT = "GET_EVENT"
export const GET_EVENTS_BY_STRATEGIES = "GET_EVENTS_BY_STRATEGIES"
export const GET_MY_ATTENDED_EVENTS = "GET_MY_ATTENDED_EVENTS"
export const GET_MY_UPCOMING_EVENTS = "GET_MY_UPCOMING_EVENTS"

// payment
export const GET_PAYMENT_SOURCES = "GET_PAYMENT_SOURCES"

// setting
export const GET_NOTIFICATION_SETTING = "GET_NOTIFICATION_SETTING"

// notifications
export const GET_UNREAD_NOTIFICATION = "GET_UNREAD_NOTIFICATION"
export const GET_ALL_NOTIFICATIONS = "GET_ALL_NOTIFICATIONS"
export const GET_STATUS_BAR = "GET_STATUS_BAR"
export const SET_CURRENT_NOTIFICATION = "SET_CURRENT_NOTIFICATION"
export const REMOVE_NOTIFICATION = "REMOVE_NOTIFICATION"
export const UPDATE_UNREAD_NOTIFICATION_COUNT = "UPDATE_UNREAD_NOTIFICATION_COUNT"
export const SET_NOTIFICATION_PAGE = "SET_NOTIFICATION_PAGE"
export const MARK_ALL_AS_READ = "MARK_ALL_AS_READ"
export const MARK_NOTIFICATION_AS_READ = "MARK_NOTIFICATION_AS_READ"
