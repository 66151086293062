import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
	ToggleEventCodeModal,
} from "@/store/actions/modalActions";

export default function EventCodeModal({}) {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const successText = useSelector((state) => state.modal.successText);

	return (
		<>
			<div className="justify-centeritems-center flex overflow-x-hidden items-center overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
				<div className="relative w-auto my-6 mx-auto max-w-3xl">
					{/*content*/}
					<div className="border-0 rounded-lg rounded shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
						{/*body*/}
						<div className="relative bg-lighter-grey w-[872px] h-[418px] rounded">
							<div className="flex text-[30px] whitespace-pre justify-center mt-20">
								Enter <div className="font-bold">Event Code</div>
							</div>
							<div className="text-base text-darker-grey/60 text-center mt-2">
								Enter the event code to participate on this event
							</div>
							<div className="grid justify-center mt-[32px]">
								<div className="w-[396px] h-[112px] justify-center flex-col flex items-center bg-white rounded">
									<div className="font-bold text-base">Event Code</div>
									<div className="w-[372px] h-[56px] px-[20px] py-[17px] bg-[#EEF1F3]">
										210910
									</div>
								</div>
							</div>
							<div className="grid justify-center mt-[32px]">
								<div
									onClick={() => {
										dispatch(ToggleEventCodeModal());
										
									}}
									className="bg-green text-white font-semibold text-base w-[252px] h-[54px] rounded grid justify-center items-center cursor-pointer"
								>
									Enter Code
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
		</>
	);
}
