import * as actionTypes from "../action-types";

let initialState = {
	events: [],
	event: null,
	attendedEvents: [],
	upcomingEvents: [],
};

function reducer(state = initialState, action) {
	switch (action.type) {
		case actionTypes.GET_ALL_EVENTS:
			return { ...state, events: action.payload };
		case actionTypes.GET_EVENT:
			return { ...state, event: action.payload };
		case actionTypes.GET_EVENTS_BY_STRATEGIES:
			return { ...state, events: action.payload };
		case actionTypes.GET_MY_ATTENDED_EVENTS:
			return { ...state, attendedEvents: action.payload };
		case actionTypes.GET_MY_UPCOMING_EVENTS:
			return { ...state, upcomingEvents: action.payload };
		default:
			return state;
	}
}
export default reducer;
