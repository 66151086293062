import React, { lazy, useEffect, useState } from "react"
import { useParams, useNavigate } from "react-router-dom"
import background from "@/assets/img/imagem_carbon.png"
import icon_arrow_left_orange from "@/assets/img/icon_arrow_left_orange.png"
import icon_building_white from "@/assets/img/icon_building_white.png"
import icon_calendar_white from "@/assets/img/icon_calendar_white.png"
import { useDispatch, useSelector } from "react-redux"
import { getDate, getDatesDifferent, getDateWithoutPostfix } from "../../utils/helper"
import { getProject } from "../../store/actions/projectsActions"
import { sendEventSelectProject, sendEventViewProjectDetails } from "@/lib/firebase"
import ReactPixel from "react-facebook-pixel"
import CountrySelectionModal from "./modals/CountrySelection"
import SupportDetail from "./modals/SupportDetail"
import Summary from "./modals/Summary"
import Payment from "./modals/Payment"

const ProjectDetailSmallCard = lazy(() => import("@/components/Card/ProjectDetail/ProjectDetailSmallCard"))
const ProjectDetailPercentage = lazy(() => import("@/components/Card/ProjectDetail/ProjectDetailPercentage"))
const ProjectDetailGeo = lazy(() => import("@/components/Card/ProjectDetail/ProjectDetailGeo"))
const ProjectDetailSupport = lazy(() => import("@/components/Card/ProjectDetail/ProjectDetailSupport"))
const ProjectDetailCard = lazy(() => import("@/components/Card/ProjectDetail/ProjectDetailCard"))
const ProjectDetailClosing = lazy(() => import("@/components/Card/ProjectDetail/ProjectDetailClosing"))

export default function ProjectDetail() {
  // const project = useSelector((state) => state.projects.project)
  const [project, setProject] = useState({})
  const { id } = useParams()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const otp = useSelector((state) => state.otp)
  const isAnalyticsEnabled = () => process.env.MODE === "production"

  useEffect(() => {
    dispatch(getProject(id)).then((res) => {
      setProject(res)
      if (isAnalyticsEnabled()) {
        console.log("this is production environment")
        sendEventViewProjectDetails({ subscriptionPlan: {}, project: res })
      } else {
        console.log("this is staging environment")
      }
    })
  }, [])

  return (
    <div className="px-[5%] mb-8">
      {otp.userAction == "select-country" && <CountrySelectionModal />}
      {otp.userAction == "support-detail" && <SupportDetail />}
      {otp.userAction == "summary" && <Summary />}
      {otp.userAction == "payment" && <Payment />}
      <div className="flex mt-4 ">
        <img onClick={() => navigate("/")} className="cursor-pointer h-1/4 tablet:h-full" src={icon_arrow_left_orange} alt="left_icon"></img>
        <div className="text-base tablet:text-2xl grid  content-center font-bold uppercase">Project Information</div>
      </div>
      <div className="tablet:flex relative tablet:space-x-4">
        <div className="w-full tablet:w-[60%]">
          <ProjectDetailCard />
        </div>
        <div className="w-full tablet:w-[40%]">
          <div className="bg-white h-fit rounded  pb-2 ">
            <div className="mx-4 relative border border-white ">
              <div className="font-bold my-2">THE PROJECT IN NUMBERS</div>
              <div
                style={{
                  backgroundImage: `url(${background})`,
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                }}
                className="flex w-full text-white  min-h-[118px] h-fit relative rounded-[15px] sm:mt-2">
                <div className="w-1/2 rounded grid items-center py-2">
                  <div className="mobile:ml-10 ml-4">
                    <div className="font-bold mobile:tablet::text-[28px] text-xl">
                      {project?.totalCarbonOffset} <span className="mobile:text-[18px] text-sm">Kg CO2</span>
                    </div>
                    <div className="mobile:text-base text-sm">OFFSET BY THIS PROJECT</div>
                  </div>
                </div>
              </div>
            </div>

            <div className={"flex mx-4 mt-4 justify-between gap-2"}>
              <div> </div>
              <button
                className={
                  "outline-hidden p-1  tablet:p-3   w-1/2 border-1 border-[#E9664E]   hover:shadow-md  cursor-pointer h-fit rounded-full bg-[#E9664E] font-semibold text-white  disabled:bg-dark-grey"
                }
                onClick={() => {
                  dispatch({ type: "otp/init" })
                  if (isAnalyticsEnabled()) {
                    console.log("this is production environment")
                    sendEventSelectProject(project)
                  } else {
                    console.log("this is staging environment")
                  }
                }}>
                {otp.isForSomeoneElse ? (
                  <p>
                    GIFT <span className="hidden laptop:inline-block">THIS</span> PROJECT
                  </p>
                ) : (
                  <p>
                    SUPPORT <span className="hidden laptop:inline-block">THIS</span> PROJECT
                  </p>
                )}
              </button>
            </div>
          </div>
          <div className="laptop:grid laptop:grid-cols-2 gap-2 mt-4 relative">
            <div className="w-full p-2 bg-white rounded">
              <ProjectDetailSmallCard
                title={
                  <>
                    PROJECT LENGTH:&nbsp;
                    <span className="text-sm font-normal text-[#E9664E]">
                      {project?.endDate ? getDatesDifferent(project?.startDate?.split("T")[0], project?.endDate?.split("T")[0]) : " Ongoing"}
                    </span>
                  </>
                }
                image={icon_calendar_white}
                firstLine={<div className="text-[14px]">Starting Date: {getDateWithoutPostfix(project?.startDate)}</div>}
                secondLine={
                  <div className="text-[14px]">
                    End Date: <span className="text-[#E9664E]">{project?.endDate ? getDateWithoutPostfix(project?.endDate) : "Ongoing"}</span>
                  </div>
                }
              />
            </div>
            <div className="w-full p-2 bg-white rounded">
              <ProjectDetailSmallCard
                title="THIS PROJECT IS ORGANIZED BY"
                image={icon_building_white}
                firstLine={<div className="text-[14px] font-semibold">{project?.owner}</div>}
                // secondLine={<div className="text-[14px] text-[#E9664E] font-semibold">{project?.classification}</div>}
              />
            </div>
          </div>
          <div className="mt-4 relative">
            <ProjectDetailGeo />
          </div>
          {/* <div className="mt-4 relative">
            <ProjectDetailPercentage />
          </div> */}
        </div>
      </div>
    </div>
  )
}
