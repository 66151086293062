import React, { useEffect, useState } from "react"
import InputField from "@/components/InputField"
import { useSelector } from "react-redux"
import { isValidEmail } from "../../../utils/helper"
import icon_navbar_profile_filled from "@/assets/img/icon_navbar_profile_filled.svg"

export default function Page1({
  yourself,
  setYourself,
  setSomeoneElseEmail,
  someoneElseEmail,
  emailError,
  setEmailError,
  beneficiaryName,
  setBeneficiaryName,
}) {
  const project = useSelector((state) => state.projects.project)
  const profile = useSelector((state) => state.account.profile)

  useEffect(() => {
    if (someoneElseEmail) {
      const timeoutId = setTimeout(() => {
        if (!isValidEmail(someoneElseEmail)) {
          setEmailError("Email Formatted Incorrectly")
        } else {
          setEmailError("")
        }
      }, 1500)

      return () => {
        clearTimeout(timeoutId)
      }
    }
  }, [someoneElseEmail])

  return (
    <div className="px-8 pt-2">
      <div className="flex gap-4 sm:flex-col">
        <div className="w-full">
          <div className="text-[14px] text-darker-grey/30 font-semibold uppercase">Project selected:</div>
          <div className="border-2 border-gray-400 bg-white w-full h-[94px] rounded-[15px] mt-2 grid grid-cols-4 items-center px-4">
            <img src={project?.bannerURL} alt={project?.projectName} className="w-[80px] h-[70px] object-cover object-left rounded" />
            <div className="col-span-3 md:ml-2">
              <div className="font-bold text-[16px]">{project?.projectName?.toUpperCase()}</div>
              <div className="text-darker-grey/60 text-[14px]">{project?.country?.toUpperCase()}</div>
            </div>
          </div>
          <div className="mt-4">
            <div className="text-[14px] text-darker-grey/30 font-semibold uppercase">One time support badge:</div>
            <div className="border-2 border-gray-400 bg-white w-full h-[94px] rounded-[15px] mt-2 grid grid-cols-4 items-center px-4">
              <img src={project?.badgeIconURL} alt={project?.badgeName} className="w-[70px] h-[70px] object-fit object-left rounded pl-1  md:-ml-2" />
              <div className="col-span-3  md:ml-2">
                <div className="font-bold text-[16px]">{project?.strategy?.toUpperCase()}</div>
                <div className="text-darker-grey/60 text-[14px] font-bold">
                  {/* After {project?.badgeTime} days you will get this badge on your
									profile. */}
                  Coming soon...
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="w-full">
          <div className="text-[14px] text-darker-grey/30 font-semibold uppercase ">On behalf of:</div>
          <div
            className={
              "border-2 border-gray-400 bg-white w-full  rounded-[15px] items-center px-4 py-4 mt-2 h-fit "
              // +
              // (yourself ? " h-[136px] " : " h-[270px] ")
            }>
            <div className="flex gap-4">
              <div
                className={(yourself ? "bg-round_select_check bg-center border-none " : "") + "rounded-full  w-8 h-8 cursor-pointer border-2 border-gray-400"}
                onClick={() => {
                  setYourself(!yourself)
                }}></div>
              <div className="font-bold text-[16px] flex items-center">{profile?.userType === "Business" ? "My Company" : "Yourself"}</div>
              <div
                className={(!yourself ? "bg-round_select_check bg-center border-none " : "") + "rounded-full  w-8 h-8 cursor-pointer border-2 border-gray-400"}
                onClick={() => {
                  setYourself(!yourself)
                }}></div>
              <div className="font-bold text-[16px] flex items-center">Someone else</div>
            </div>
            {yourself ? (
              <div className="flex items-center gap-4 mt-[32px]">
                <img
                  className="w-[48px] h-[48px] rounded-full"
                  src={profile?.profilePicture ? profile?.profilePicture : icon_navbar_profile_filled}
                  alt="profile-pic"
                />
                <div className="font-bold text-base">{profile.fullName}</div>
              </div>
            ) : (
              <div className="mt-[32px]">
                <div className="font-bold text-base my-2">Beneficiary Name</div>
                <InputField onChange={(v) => setBeneficiaryName(v)} inputStyle="w-full h-14 bg-grey rounded px-4" placeholder="Enter the name" />

                <div className="font-bold text-base my-2">Beneficiary Email</div>
                <InputField onChange={(v) => setSomeoneElseEmail(v)} inputStyle="w-full h-14 bg-grey rounded px-4" placeholder="Enter the email" />
                <p className="font-semibold text-red-500 text-sm mt-2">{emailError}</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
