import * as actionTypes from "../action-types"
import axios from "@/utils/request.js"
import { toast } from "react-toastify"
import to from "await-to-js"
import ReactPixel from "react-facebook-pixel"

export function getPaymentSources() {
  return (dispatch) =>
    new Promise(async (resolve, reject) => {
      try {
        dispatch({ type: actionTypes.SHOW_LOADING, payload: true })
        let res = await axios.get(`/payment-sources`)
        dispatch({ type: actionTypes.GET_PAYMENT_SOURCES, payload: res.items })
        resolve(res.items)
      } catch (error) {
      } finally {
        dispatch({ type: actionTypes.HIDE_LOADING, payload: false })
      }
    })
}

export function addNewCreditCard(data) {
  return (dispatch) =>
    new Promise(async (resolve, reject) => {
      try {
        dispatch({ type: actionTypes.SHOW_LOADING, payload: true })
        let res = await axios.post(`/payment-sources`, data)
        resolve(res.data)
      } catch (error) {
      } finally {
        dispatch({ type: actionTypes.HIDE_LOADING, payload: false })
      }
    })
}
export function putSubscription(subscriptionId, data) {
  return (dispatch) =>
    new Promise(async (resolve, reject) => {
      try {
        dispatch({ type: actionTypes.SHOW_LOADING, payload: true })
        const res = await axios.put(`/subscriptions/${subscriptionId}/authentication`, data)
        resolve(res)
      } catch (error) {
      } finally {
        dispatch({ type: actionTypes.HIDE_LOADING, payload: false })
      }
    })
}
export function payOnHold(data, subscriptionId) {
  return (dispatch) =>
    new Promise(async (resolve, reject) => {
      try {
        dispatch({ type: actionTypes.SHOW_LOADING, payload: true })
        const result = await axios.put(`/subscriptions/${subscriptionId}/pay_on_hold`, data)
        toast.success(result?.data?.successMessage)
        resolve(result)
      } catch (error) {
      } finally {
        dispatch({ type: actionTypes.HIDE_LOADING, payload: false })
      }
    })
}

export function removePaymentMethod(data) {
  return (dispatch) =>
    new Promise(async (resolve, reject) => {
      try {
        dispatch({ type: actionTypes.SHOW_LOADING, payload: true })
        let res = await axios.delete(`/payment-sources/${data.paymentSourceId}`)
        resolve(res.data)
      } catch (error) {
      } finally {
        dispatch({ type: actionTypes.HIDE_LOADING, payload: false })
      }
    })
}

export function makePaymentMethodDefault(data) {
  return (dispatch) =>
    new Promise(async (resolve, reject) => {
      try {
        dispatch({ type: actionTypes.SHOW_LOADING, payload: true })
        let res = await axios.put(`/payment-sources/${data.paymentSourceId}`, data)
        resolve(res.data)
      } catch (error) {
      } finally {
        dispatch({ type: actionTypes.HIDE_LOADING, payload: false })
      }
    })
}

export function getInvoicesEstimate(data) {
  return (dispatch) =>
    new Promise(async (resolve, reject) => {
      try {
        dispatch({ type: actionTypes.SHOW_LOADING, payload: true })
        let res = await axios.post(`/invoices/estimate/v1-2`, data)
        ReactPixel.track("Purchase", res.data)
        resolve(res.data)
      } catch (error) {
        reject(error)
      } finally {
        dispatch({ type: actionTypes.HIDE_LOADING, payload: false })
      }
    })
}

export function getInvoicesEstimateGuest(data) {
  return (dispatch) =>
    new Promise(async (resolve, reject) => {
      try {
        dispatch({ type: actionTypes.SHOW_LOADING, payload: true })
        let res = await axios.post(`/invoices/estimate/guest`, data)
        ReactPixel.track("Purchase", res.data)
        resolve(res.data)
      } catch (error) {
        reject(error)
      } finally {
        dispatch({ type: actionTypes.HIDE_LOADING, payload: false })
      }
    })
}
