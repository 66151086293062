import * as actionTypes from "../action-types";
// import _ from "lodash";
let initialState = {
	count: 0,
	notifications: [],
	statusBar: [],
	currentNotification: {},
	removedNotificationId: "",
	notificationTotal: 0,
	page: 0,
};

function reducer(state = initialState, action) {
	switch (action.type) {
		case actionTypes.GET_UNREAD_NOTIFICATION:
			return { ...state, count: action.payload };
		case actionTypes.GET_ALL_NOTIFICATIONS:
			// let found = false;
			// let extraNotifications = [];
			// action.payload.notifications.forEach((element) => {
			// 	found = state.notifications.find(
			// 		(notification) => notification.id === element.id
			// 	);
			// 	if (!found) {
			// 		extraNotifications.push(element);
			// 	}
			// });
			// if (!found && action.payload.count === state.notificationTotal) {
			// 	return {
			// 		...state,
			// 		notifications: [...state.notifications, ...extraNotifications],
			// 		notificationTotal: action.payload.count,
			// 	};
			// } else if (action.payload.count !== state.notificationTotal) {
			// 	return {
			// 		...state,
			// 		notifications: action.payload.notifications,
			// 		notificationTotal: action.payload.count,
			// 	};
			// }
			return {
				...state,
				notifications: action.payload.notifications,
				notificationTotal: action.payload.count,
			};
		// if (!_.isEqual(action.payload.notifications, state.notifications)) {
		// 	return {
		// 		...state,
		// 		notifications: action.payload.notifications,
		// 		notificationTotal: action.payload.count,
		// 	};
		// } else {
		// 	return {
		// 		...state,
		// 		notificationTotal: action.payload.count,
		// 	};
		// }
		case actionTypes.GET_STATUS_BAR:
			return { ...state, statusBar: action.payload };
		case actionTypes.SET_CURRENT_NOTIFICATION:
			return { ...state, currentNotification: action.payload };
		case actionTypes.REMOVE_NOTIFICATION: {
			let data = state.notifications.filter((notification) => {
				return action.payload !== notification.id;
			});
			return {
				...state,
				removedNotificationId: action.payload,
				notifications: data,
				notificationTotal: action.payload
					? state.notificationTotal - 1
					: state.notificationTotal,
			};
		}
		case actionTypes.UPDATE_UNREAD_NOTIFICATION_COUNT:
			if (action.payload === 1) {
				return { ...state, count: state.count - action.payload };
			} else {
				return { ...state, count: 0 };
			}
		case actionTypes.MARK_NOTIFICATION_AS_READ:
			let allNotification = state.notifications.map((notification) => {
				if (notification.id === action.payload) {
					return { ...notification, isRead: true };
				}
				return { ...notification };
			});
			return { ...state, notifications: allNotification };
		case actionTypes.MARK_ALL_AS_READ:
			let allNotifications = state.notifications.map((notification) => {
				return { ...notification, isRead: true };
			});
			return { ...state, notifications: allNotifications };

		default:
			return state;
	}
}
export default reducer;
