import React, { useState } from "react"
import closeIcon from "@/assets/img/icon_close_orange.svg"
import background from "@/assets/img/imagem_carbon.png"
import { ToggleSuccessModal, ToggleOneTimeSupportModal } from "@/store/actions/modalActions"
import { useStripe } from "@stripe/react-stripe-js"
import { useDispatch, useSelector } from "react-redux"
import { getPersonalSubscription } from "../../../store/actions/subscriptionsActions"
import { useEffect } from "react"
import { getPaymentSources } from "../../../store/actions/payment"
import { getMySubscription } from "../../../store/actions/accounts"
import Autocomplete from "@mui/material/Autocomplete"
import { TextField, Box } from "@mui/material"
import { getCountries } from "@/store/actions/accounts"
import { ConnectingAirportsOutlined } from "@mui/icons-material"

export default function OneTimeSupportModal(props) {
  const dispatch = useDispatch()
  const [countries, setCountries] = useState([])
  const otp = useSelector((state) => state.otp)
  const project = useSelector((state) => state.projects.project)
  console.log(otp)

  const nextStep = async () => {
    //next page support details
    console.log("next step")
    dispatch({ type: "otp/updateUserAction", payload: "payment" })
  }

  useEffect(() => {
    dispatch(getCountries()).then((res) => setCountries(res.data))
  }, [])

  return (
    <>
      <div className="w-screen h-screen justify-center flex overflow-x-hidden items-center overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-darker-grey/60">
        <div className={"max-w-[582px] w-screen bg-lighter-grey rounded py-4 px-2 relative overflow-y-auto sm:h-screen "}>
          <div className="flex justify-between">
            <div className="px-8 font-bold text-[30px]">Summary</div>
            <div className=" grid place-items-center">
              <img src={closeIcon} alt="closeIcon" className=" cursor-pointer " onClick={() => dispatch({ type: "otp/closeModal" })} />
            </div>
          </div>
          <div className="px-8">
            {otp?.summary?.id && (
              <div className=" w-full">
                <div
                  style={{
                    backgroundImage: `url(${background})`,
                    backgroundPosition: "center",
                    backgroundSize: "cover",
                  }}
                  className="flex w-full text-white  min-h-[118px] h-fit relative rounded-[15px] sm:mt-2">
                  <div className="w-1/2 rounded grid items-center py-2">
                    <div className="mobile:ml-10 ml-4">
                      <div className="font-bold mobile:tablet::text-[28px] text-xl">
                        {otp?.totalCarbonOffset} <span className="mobile:text-[18px] text-sm">Kg CO2</span>
                      </div>
                      <div className="mobile:text-base text-sm">OFFSET BY THIS PAYMENT</div>
                    </div>
                  </div>
                </div>
                <br></br>
                <div className=" text-darker-grey/30 uppercase">Description Of Service</div>
                <div className="bg-white w-full mb-4  rounded-8 border border-black">
                  <div className="flex mx-4 justify-between mt-2">
                    <div className="font-bold uppercase">{project?.projectName}</div>
                    <div className="text-[14px] text-[#212427]/50">{Number(otp?.summary?.transactionCost ? otp?.summary?.transactionCost : 0).toFixed(2)}</div>
                  </div>
                  <div className="flex mx-4 mt-2 justify-between">
                    <div className="text-[14px] uppercase">Service Fee</div>
                    <div className="text-[14px] text-[#212427]/50">{Number(otp?.summary?.stripeFee ? otp?.summary?.stripeFee : 0).toFixed(2)}</div>
                  </div>
                  <div className="flex mx-4 mt-2 justify-between">
                    <div className="text-[14px] uppercase">Tax</div>
                    <div className="text-[14px] text-[#212427]/50">{Number(otp?.summary?.tax ? otp?.summary?.tax : 0).toFixed(2)}</div>
                  </div>
                  <div className="border-dashed mt-4 border-b mx-4 border-[#E0E5EB]"></div>
                  <div className="flex mx-4 my-2 justify-between">
                    <div className="text-[16px] font-bold uppercase">Total cost (CAD)</div>
                    <div className="text-[16px] text-[#E9664E] font-semibold">{Number(otp?.summary?.total ? otp?.summary?.total : 0).toFixed(2)}</div>
                  </div>
                </div>
              </div>
            )}
          </div>
          <br></br>
          <div className="flex justify-end">
            <button
              className={`uppercase max-w-[505px]   px-4 py-2 mx-2 ${
                otp.country?.countryId ? "bg-[#E9664E]" : "bg-gray-200"
              }  justify-center grid content-center rounded-[32px] cursor-pointer text-white font-semibold disabled:bg-dark-grey disabled:cursor-default`}
              onClick={nextStep}>
              Continue
            </button>
          </div>
        </div>
      </div>
    </>
  )
}
