import React, { useState } from "react"
import Page1 from "./Page1"
import Page2 from "./Page2"
import closeIcon from "@/assets/img/icon_close_orange.svg"
import { toast } from "react-toastify"
import { ToggleSuccessModal, ToggleOneTimeSupportModal } from "@/store/actions/modalActions"
import { useStripe } from "@stripe/react-stripe-js"
import { useDispatch, useSelector } from "react-redux"
import { getPersonalSubscription, oneTimeSupportPay } from "../../../store/actions/subscriptionsActions"
import { useEffect } from "react"
import { getPaymentSources } from "../../../store/actions/payment"
import { getMySubscription } from "../../../store/actions/accounts"
import { isValidEmail } from "../../../utils/helper"
import { sendEventPurchase } from "../../../lib/firebase"
import ReactPixel from "react-facebook-pixel"

export default function OneTimeSupportModal({}) {
  const [yourself, setYourself] = useState(true)
  const [page, setPage] = useState(1)
  const dispatch = useDispatch()
  const successModal = useSelector((state) => state.modal.successModal)
  const newCreditCardModal = useSelector((state) => state.modal.newCreditCardModal)
  const [someoneElseEmail, setSomeoneElseEmail] = useState("")
  const [beneficiaryName, setBeneficiaryName] = useState("")
  const [selected, setSelected] = useState(null)
  const [customAmount, setCustomAmount] = useState(0)
  const [selectedOption, setSelectedOption] = useState("annual") // annual or custom
  const project = useSelector((state) => state.projects.project)
  const [emailError, setEmailError] = useState("")
  const profile = useSelector((state) => state.account.profile)
  const mySubscription = useSelector((state) => state.account.mySubscription)
  const personal = useSelector((state) => state.subscriptions.personal)
  const [paymentInfo, setPaymentInfo] = useState()
  const stripe = useStripe()
  console.log(stripe)
  const [hide, setHide] = useState(false)

  useEffect(() => {
    //once change credit card, enable pay submit button again
    setHide(false)
  }, [selected])

  useEffect(() => {
    dispatch(getPaymentSources())
    if (personal?.length === 0) {
      dispatch(getPersonalSubscription())
    }
    console.log("tst")
    console.log(mySubscription)
    console.log("tst")
    if (!mySubscription || Object.keys(mySubscription).length === 0) {
      dispatch(getMySubscription())
    }
  }, [])
  const cards = useSelector((state) => state.payment.cards)

  useEffect(() => {
    if (cards) {
      let defaultPaymentMethod = cards.find((card) => {
        return card.isDefault === true
      })
      setSelected(defaultPaymentMethod)
    }
  }, [cards])
  const success = () => {
    if (yourself) {
      dispatch(
        ToggleSuccessModal(
          <div className="flex justify-center pt-[340px]">Thank you for supporting this project</div>,
          "/projects/gifted",
          "Go Back to My Projects"
        )
      )
    } else {
      dispatch(
        ToggleSuccessModal(
          <>
            <div className="flex justify-center pt-[340px]">Thank you for supporting this project on behalf of {beneficiaryName}</div>
            <br />
            {/* <div className="flex justify-center mt-[-50px]">project</div> */}
            <div className="text-[14px] text-darker-grey/60 flex justify-center pt-4 font-normal">
              <div>
                <p> We will send an email to {someoneElseEmail} with details of your gift.</p>
                <p>The recipient will receive a certificate of their carbon offset via email.</p>
              </div>
            </div>
          </>,
          "/projects/gifted",
          "Go Back to My Projects"
        )
      )
    }
  }

  return (
    <>
      <div
        className={
          "w-screen h-screen justify-center flex overflow-x-hidden items-center overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-darker-grey/60" +
          (successModal || newCreditCardModal ? " hidden " : "")
        }>
        <div className={"max-w-[882px] w-screen bg-lighter-grey rounded py-4 relative overflow-y-auto sm:h-screen"}>
          <div className="flex justify-between">
            <div className="px-8 font-bold text-[30px]">ONE TIME SUPPORT</div>
            <img src={closeIcon} alt="closeIcon" className="pr-4 pt-4 cursor-pointer" onClick={() => dispatch(ToggleOneTimeSupportModal())} />
          </div>
          {page === 1 ? (
            <Page1
              yourself={yourself}
              setYourself={setYourself}
              setSomeoneElseEmail={setSomeoneElseEmail}
              setBeneficiaryName={setBeneficiaryName}
              someoneElseEmail={someoneElseEmail}
              beneficiaryName={beneficiaryName}
              emailError={emailError}
              setEmailError={setEmailError}
            />
          ) : (
            <Page2
              selected={selected}
              setSelected={setSelected}
              customAmount={customAmount}
              setCustomAmount={setCustomAmount}
              selectedOption={selectedOption}
              setSelectedOption={setSelectedOption}
              paymentInfo={paymentInfo}
              setPaymentInfo={setPaymentInfo}
            />
          )}
          <div className={"flex justify-end h-[54px] items-center px-8 mb-2 mt-4"}>
            <button
              disabled={
                (page === 2 && selectedOption != "annual" && (customAmount < 5.5 || customAmount > 500)) ||
                (page === 2 && !selected) ||
                (page === 1 && !yourself && (someoneElseEmail === "" || beneficiaryName === "")) ||
                (page === 1 && !yourself && !isValidEmail(someoneElseEmail)) ||
                hide
              }
              className="rounded-[40px] h-full w-[144px] bg-orange_v2 text-white disabled:bg-dark-grey"
              onClick={() => {
                if (page === 1) {
                  if (!yourself && someoneElseEmail !== "") {
                    setPage(2)
                  } else if (yourself) {
                    setPage(2)
                  }
                } else {
                  let data = {
                    isForSomeoneElse: yourself ? false : true,
                    beneficiaryEmail: yourself ? "" : someoneElseEmail,
                    beneficiaryName: yourself ? "" : beneficiaryName,
                    supportDuration: selectedOption,
                    stripeToken: "",
                    projectId: project.id,
                    customAmount: selectedOption === "annual" ? 0 : customAmount,
                    paymentSourceId: selected.id,
                  }

                  setHide(true)
                  dispatch(oneTimeSupportPay(data)).then((res) => {
                    if (process.env.MODE === "production") {
                      sendEventPurchase({
                        project,
                        token: data.paymentSourceId,
                        state: { paymentInfo },
                        oneTimeSupport: true,
                      })
                      ReactPixel.track("Purchase", {
                        project,
                        token: data.paymentSourceId,
                        state: { paymentInfo },
                        oneTimeSupport: true,
                      })

                      window.gtag("event", "conversion", {
                        send_to: "AW-11154335658/qg-yCI-cv6AZEKrP5sYp",
                      })
                    }

                    if (res?.paymentIntentSecret) {
                      stripe.confirmCardPayment(res?.paymentIntentSecret).then((res) => {
                        if (res?.paymentIntent?.status == "succeeded") {
                          success()
                        } else {
                          toast.error(res?.error?.message)
                        }
                      })
                    } else {
                      success()
                    }

                    dispatch(ToggleOneTimeSupportModal())
                  })
                }
              }}>
              {page === 1 ? "CONTINUE" : "PAY NOW"}
            </button>
          </div>
        </div>
      </div>
    </>
  )
}
