import React, { lazy, Suspense } from "react"
import ReactDOM from "react-dom/client"
import { createTheme, ThemeProvider } from "@mui/material"
import { ToastContainer, toast } from "react-toastify"
import { getMySubscription } from "@/store/actions/accounts"
import "./index.css"
// import "./toastify.css"
import { Provider, useDispatch, useSelector } from "react-redux"
import store from "@/store"
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation } from "react-router-dom"
import "react-responsive-carousel/lib/styles/carousel.min.css"
import "react-toastify/dist/ReactToastify.css"
import ModalLayout from "./components/Layout/ModalLayout"
import "@fontsource/poppins/700.css"
import "@fontsource/poppins/400.css"
import "@fontsource/poppins/600.css"
import "@fontsource/roboto-condensed/300.css"
import "@fontsource/roboto-condensed/400.css"
// import "@fontsource/roboto-condensed/700.css"

import { useEffect } from "react"
import { loadStripeConfig, logout, saveStripePromise } from "./store/actions/accounts"
import { GoogleOAuthProvider } from "@react-oauth/google"
import { loadStripe } from "@stripe/stripe-js"
import masterCardIcon from "@/assets/img/mc_symbol.svg"
import visaIcon from "@/assets/img/visa_icon.svg"
import americanExpressIcon from "@/assets/img/AMEX.svg"
import icon_credit_card from "@/assets/img/icon_credit_card.svg"
import { getAllNotifications, getNavbarStatusBar, getUnreadNotificationCount } from "./store/actions/notifications"
import * as Sentry from "@sentry/react"
import { BrowserTracing } from "@sentry/tracing"
import { initializeFirebase } from "./lib/firebase"
import ReactPixel from "react-facebook-pixel"

const advancedMatching = { em: "ilyas.sadybekov@vogcalgaryappdeveloper.com" } // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
const options = {
  autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
  debug: false, // enable logs
}
if (process.env.MODE == "development") ReactPixel.init("750893840066638", advancedMatching, options)
else ReactPixel.init("1062865064707670", advancedMatching, options)

// ideally, you can import all variable.css from fontsource.
// however, following the docs, there is no variable.css in Poppins.
// so importing the normal/semibold/bold css individually
// import "@fontsource/poppins/variable.css";

Sentry.init({
  dsn: "https://2639af9f71e5130a3d20a237ae0cfa8c@o4506514417778688.ingest.sentry.io/4506515680395264",
  integrations: [new BrowserTracing()],
  tracesSampleRate: 1.0,
  environment: process.env.MODE,
})

const Home = lazy(() => import("@/pages/Home"))
import BanffMarathonIntro from "@/pages/BanffMarathon/intro"
import BanffMarathonCalculator from "@/pages/BanffMarathon/calculator"
import BanffMarathonSuccess from "@/pages/BanffMarathon/success"
const Landing = lazy(() => import("@/pages/Home/Landing"))
const Splash = lazy(() => import("@/pages/Home/Splash"))
const TermsOfService = lazy(() => import("@/pages/About/TermsOfService"))
const PrivacyPolicy = lazy(() => import("@/pages/About/PrivacyPolicy"))
const Intro = lazy(() => import("@/pages/Intro"))
const Intro1 = lazy(() => import("@/pages/Intro/Intro1"))
const Intro2 = lazy(() => import("@/pages/Intro/Intro2"))
const Intro3 = lazy(() => import("@/pages/Intro/Intro3"))
const Intro4 = lazy(() => import("@/pages/Intro/Intro4"))
const Strategy = lazy(() => import("@/pages/Strategy"))
const Login = lazy(() => import("@/pages/Login"))
const SignUp = lazy(() => import("@/pages/SignUp"))
const SettingYourProfile = lazy(() => import("@/pages/SignUp/SettingYourProfile"))
import SelectYourCountry from "@/pages/SignUp/SelectYourCountry"
const SubscriptionOverview = lazy(() => import("@/pages/SignUp/SubscriptionOverview"))
const TermsOfServiceAndPrivacyPolicy = lazy(() => import("@/pages/SignUp/TermsOfServiceAndPrivacyPolicy"))
const CreateYourAccount = lazy(() => import("@/pages/SignUp/CreateYourAccount"))
const SubscriptionPayment = lazy(() => import("@/pages/SignUp/SubscriptionPayment"))
const Profile = lazy(() => import("@/pages/Profile"))
const EditProfile = lazy(() => import("@/pages/Profile/EditProfile"))
const ForgetPassword = lazy(() => import("@/pages/ForgetPassword"))
const ForgetPasswordPage1 = lazy(() => import("@/pages/ForgetPassword/Page1"))
const ForgetPasswordPage2 = lazy(() => import("@/pages/ForgetPassword/Page2"))
const ForgetPasswordPage3 = lazy(() => import("@/pages/ForgetPassword/Page3"))
const ForgetPasswordPage4 = lazy(() => import("@/pages/ForgetPassword/Page4"))
const ForgetPasswordPage5 = lazy(() => import("@/pages/ForgetPassword/Page5"))
const ChangeProfile = lazy(() => import("@/pages/Settings/Subscription/ChangeProfile"))
const ChangeProfilePage1 = lazy(() => import("@/pages/Settings/Subscription/ChangeProfile/Page1"))
const ChangeProfilePage2 = lazy(() => import("@/pages/Settings/Subscription/ChangeProfile/Page2"))
const ChangeProfilePage3 = lazy(() => import("@/pages/Settings/Subscription/ChangeProfile/Page3"))

const Projects = lazy(() => import("@/pages/Projects"))
const MyProjects = lazy(() => import("@/pages/Projects/MyProjects"))
const ProjectDetail = lazy(() => import("@/pages/Projects/ProjectDetail"))
const GiftedProjects = lazy(() => import("@/pages/Projects/GiftedProjects"))
const ClosedProjects = lazy(() => import("@/pages/Projects/ClosedProjects"))
import OneTimeGuestProjectDetails from "./pages/OneTimePaymentGuest/Project"
import OneTimeGuestProjects from "./pages/OneTimePaymentGuest/Projects"

const Events = lazy(() => import("@/pages/Events"))
const UpcomingEvents = lazy(() => import("@/pages/Events/UpcomingEvents"))
const AttendedEvents = lazy(() => import("@/pages/Events/AttendedEvents"))
const EventDetail = lazy(() => import("@/pages/Events/EventDetail"))

const Notifications = lazy(() => import("@/pages/Notifications"))

const Settings = lazy(() => import("@/pages/Settings"))
const SettingsNotifications = lazy(() => import("@/pages/Settings/Notifications"))
const SettingsSubscription = lazy(() => import("@/pages/Settings/Subscription"))
const SettingsAbout = lazy(() => import("@/pages/Settings/AboutUs/AboutKarbonX"))
const SettingsTermsOfService = lazy(() => import("@/pages/Settings/AboutUs/TermsOfService"))
const SettingsPrivacyPolicy = lazy(() => import("@/pages/Settings/AboutUs/PrivacyPolicy"))
const SettingsFeedback = lazy(() => import("@/pages/Settings/Feedback"))

// layout
const Layout = lazy(() => import("./components/Layout"))
// example of React lazy when fetching a really large file
// const Layout = lazy(() => {
// 	return new Promise((resolve) => {
// 		setTimeout(() => {
// 			resolve(import("./components/layout"));
// 		}, 2000);
// 	});
// });
const StrategyLayout = lazy(() => import("@/components/Layout/StrategyLayout"))
const LoginLayout = lazy(() => import("@/components/Layout/LoginLayout"))
const MyProjectLayout = lazy(() => import("@/components/Layout/MyProjectLayout"))
const ProfileLayout = lazy(() => import("@/components/Layout/ProfileLayout"))
const MyEventLayout = lazy(() => import("@/components/Layout/MyEventLayout"))

// Sentry.init({
//   dsn: "",
//   integrations: [new BrowserTracing()],
//   // We recommend adjusting this value in production, or using tracesSampler
//   // for finer control
//   tracesSampleRate: 1.0,
//   environment: process.env.MODE
// });

const Spinner = () => {
  return (
    <div className="h-screen w-screen flex justify-center items-center bg-grey/50 fixed z-[100]">
      <div role="status">
        <svg
          aria-hidden="true"
          className="mr-2 w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
          viewBox="0 0 100 101"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
            fill="currentColor"
          />
          <path
            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
            fill="currentFill"
          />
        </svg>
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  )
}

function LoadStripe({ children }) {
  const dispatch = useDispatch()
  const stripePK = useSelector((state) => state.account.stripePK)
  useEffect(() => {
    if (!stripePK) {
      dispatch(loadStripeConfig())
    }
    if (stripePK) {
      const stripePromise = loadStripe(stripePK)
      dispatch(saveStripePromise(stripePromise))
    }

    initializeFirebase()
  }, [stripePK])

  return <>{children}</>
}

function Loading({ children }) {
  const loading = useSelector((state) => state.loading.show)
  const loggedIn = useSelector((state) => state.account.loggedIn)
  const dispatch = useDispatch()

  if (loggedIn && loading) dispatch(getMySubscription())

  // const loading = true;
  return (
    <>
      {loading && <Spinner />}
      {children}
    </>
  )
}

function ScrollToTop({ children }) {
  const location = useLocation()
  const dispatch = useDispatch()
  const loggedIn = useSelector((state) => state.account.loggedIn)
  const count = useSelector((state) => state.notifications.count)
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    })
    if (loggedIn) {
      dispatch(getUnreadNotificationCount())
      dispatch(getAllNotifications())
      dispatch(getNavbarStatusBar())
    }
  }, [location])

  // useEffect(()=>{
  //   if (loggedIn){
  //     dispatch(getAllNotifications());
  //   }
  // }, [count])

  return <>{children}</>
}

function PrivateRoute({ children }) {
  const loggedIn = useSelector((state) => state.account.loggedIn)
  if (!loggedIn) {
    return <Navigate to="/" replace />
  }
  return children
}

export function cardIcons(type) {
  switch (type) {
    case "visa":
      return visaIcon
    case "amex":
      return americanExpressIcon
    case "mastercard":
      return masterCardIcon
    default:
      return icon_credit_card
  }
}

const root = ReactDOM.createRoot(document.getElementById("root"))
import CloseIcon from "@mui/icons-material/Close"

const theme = createTheme({
  palette: {
    primary: {
      main: "#E9664E",
    },
  },
  typography: {
    fontFamily: `"Roboto Condensed", "sans-serif"`,
    // fontSize: 14,
    // fontWeightLight: 300,
    // fontWeightRegular: 400,
    // fontWeightMedium: 500,
  },
})
const APP = (props) => {
  const { pathname } = useLocation()
  ReactPixel.pageView(pathname) // For tracking page view

  return (
    <ScrollToTop>
      <ModalLayout>
        <Routes>
          <Route
            index
            exact
            element={
              <Suspense fallback={<Spinner />}>
                <Splash />
              </Suspense>
            }
          />
          <Route
            path=""
            element={
              <Suspense fallback={<Spinner />}>
                <Layout />
              </Suspense>
            }>
            <Route path="home" exact element={<Home />} />
            <Route path="banff-marathon-intro" element={<BanffMarathonIntro />} />
            <Route path="banff-marathon-calculator" element={<BanffMarathonCalculator />} />
            <Route path="banff-marathon-success" element={<BanffMarathonSuccess />} />
          </Route>
          <Route
            element={
              <Suspense fallback={<Spinner />}>
                <StrategyLayout />
              </Suspense>
            }>
            <Route path="strategy" exact element={<Strategy />} />
            <Route path="projects" exact>
              <Route index element={<Projects />} />
              <Route path="/projects/replacement" element={<Projects />} />
              <Route path="/projects/:id" element={<ProjectDetail />} />
              <Route
                element={
                  <Suspense fallback={<Spinner />}>
                    <PrivateRoute>
                      <MyProjectLayout />
                    </PrivateRoute>
                  </Suspense>
                }>
                <Route path="/projects/mine" element={<MyProjects />} />
                <Route path="/projects/gifted" element={<GiftedProjects />} />
                <Route path="/projects/closed" element={<ClosedProjects />} />
              </Route>
            </Route>
            <Route path="one-time-guest" exact>
              <Route index element={<Projects />} />
              <Route path="/one-time-guest/replacement" element={<Projects />} />
              <Route path="/one-time-guest/:id" element={<OneTimeGuestProjectDetails />} />
              <Route path="/one-time-guest/projects" element={<OneTimeGuestProjects />} />
            </Route>
            <Route path="events" exact>
              <Route index element={<Events />} />
              <Route
                element={
                  <Suspense fallback={<Spinner />}>
                    {/* src\components\layout\MyEventLayout.js */}
                    <PrivateRoute>
                      <MyEventLayout />
                    </PrivateRoute>
                  </Suspense>
                }>
                <Route path="/events/upcoming" element={<UpcomingEvents />} />
                <Route path="/events/attended" element={<AttendedEvents />} />
              </Route>
              <Route
                path="/events/:id"
                element={
                  <PrivateRoute>
                    <EventDetail />
                  </PrivateRoute>
                }
              />
            </Route>
          </Route>
          <Route
            exact
            element={
              <Suspense fallback={<Spinner />}>
                <PrivateRoute>
                  <ProfileLayout />
                </PrivateRoute>
              </Suspense>
            }>
            <Route path="profile" element={<Profile />} />
            <Route path="profile/edit" element={<EditProfile />} />
            {/* <Route path="notifications" element={<Notifications />} />
                    <Route path="notifications/:id" element={<Notifications />} /> */}
            <Route
              element={
                <Suspense fallback={<Spinner />}>
                  <Settings />
                </Suspense>
              }>
              <Route path="/settings/subscription" element={<SettingsSubscription />} />
              <Route path="/settings/notifications" element={<SettingsNotifications />} />
              <Route path="/settings/about" element={<SettingsAbout />} />
              <Route path="/settings/terms" element={<SettingsTermsOfService />} />
              <Route path="/settings/privacy" element={<SettingsPrivacyPolicy />} />
              <Route path="/settings/feedback" element={<SettingsFeedback />} />
            </Route>
          </Route>
          <Route
            exact
            element={
              <Suspense fallback={<Spinner />}>
                <LoginLayout />
              </Suspense>
            }>
            <Route path="login" element={<Login />}></Route>
            <Route path="sign-up" element={<SignUp />}>
              <Route path="SelectYourCountry" element={<SelectYourCountry />}></Route>
              <Route path="SettingYourProfile" element={<SettingYourProfile />}></Route>
              <Route path="TermsOfServiceAndPrivacyPolicy" element={<TermsOfServiceAndPrivacyPolicy />}></Route>
              <Route path="CreateYourAccount" element={<CreateYourAccount />}></Route>
              <Route path="SubscriptionOverview" element={<SubscriptionOverview />}></Route>
              <Route path="SubscriptionPayment" element={<SubscriptionPayment />}></Route>
            </Route>
            <Route
              path="change-profile"
              element={
                <PrivateRoute>
                  <ChangeProfile />
                </PrivateRoute>
              }>
              <Route path="ChangeProfile" element={<ChangeProfilePage1 />}></Route>
              <Route path="SubscriptionOverview" element={<ChangeProfilePage2 />}></Route>
              {/* <Route path="Page3" element={<ChangeProfilePage3 />}></Route> */}
            </Route>
            <Route path="forget-password" element={<ForgetPassword />}>
              <Route path="enter-email-account" element={<ForgetPasswordPage1 />}></Route>
              <Route path="check-your-email" element={<ForgetPasswordPage2 />}></Route>
              <Route path="verification-code" element={<ForgetPasswordPage3 />}></Route>
              <Route path="new-password" element={<ForgetPasswordPage4 />}></Route>
              <Route path="password-changed" element={<ForgetPasswordPage5 />}></Route>
            </Route>
          </Route>
          <Route
            path="/TermsOfService"
            exact
            element={
              <Suspense fallback={<Spinner />}>
                <TermsOfService />
              </Suspense>
            }
          />
          <Route
            path="/Privacy"
            exact
            element={
              <Suspense fallback={<Spinner />}>
                <PrivacyPolicy />
              </Suspense>
            }
          />
          <Route path="*" element={<Navigate to="/" replace={true} />} />
        </Routes>
      </ModalLayout>
    </ScrollToTop>
  )
}

root.render(
  <ThemeProvider theme={theme}>
    <Provider store={store}>
      <ToastContainer position="top-center" autoClose={5000} hideProgressBar={true} closeOnClick={true} pauseOnHover={false} draggable={true} />
      <Loading>
        <LoadStripe>
          <GoogleOAuthProvider
            onScriptLoadSuccess={() => console.log("onScriptLoadSuccess")}
            onScriptLoadError={() => console.log("onScriptLoadError")}
            clientId="424914109640-c2ap832q7i245b14lc4c2utths6eaba1.apps.googleusercontent.com">
            <Router>
              <APP />
            </Router>
          </GoogleOAuthProvider>
        </LoadStripe>
      </Loading>
    </Provider>
  </ThemeProvider>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
