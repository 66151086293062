import React from "react"
import { useSelector, useDispatch } from "react-redux"
import { useLocation } from "react-router-dom"
import SuccessModal from "../Modal/SuccessModal"
import ReplaceProjectModal from "../Modal/ReplaceProjectModal"
import NewCreditCardModal from "../Modal/NewCreditCardModal"
import OneTimeSupportModal from "../Modal/OneTimeSupportModal"
import CancelSubscriptionModal from "../Modal/CancelSubscriptionModal"
import EventCodeModal from "../Modal/EventCode"
import RemovePaymentMethodModal from "../Modal/RemovePaymentMethodModal"
import CancelSubscriptionFeedbackModal from "../Modal/CancelSubscriptionFeedbackModal"
import MarkAllAsReadModal from "../Modal/MarkAllAsReadModal"
import RemoveNotificationModal from "../Modal/RemoveNotificationModal"
import DeleteAccountModal from "../Modal/DeleteAccountModal"
import CountrySelectionModal from "../Modal/CountrySelectionModal"
import AccountOnHoldModal from "../Modal/AccountOnHoldModal"
import ReSubscribeModal from "../Modal/ReSubscribeModal"
import CancelEventModal from "../Modal/CancelEventModal"
import SubmitFeedbackModal from "../Modal/SubmitFeedbackModal"
import ShowNotificationDetailModal from "../Modal/ShowNotificationDetailModal"
import RemoveAllNotifications from "../Modal/RemoveAllNotifications"
import PayOnHoldModal from "../Modal/PayOnHoldModal"
import FinishPaymentModal from "../Modal/PayOnHoldModal/FinishPaymentModal"
import { Elements } from "@stripe/react-stripe-js"
import { loadStripe } from "@stripe/stripe-js"

export default function ModalLayout({ children }) {
  const successModal = useSelector((state) => state.modal.successModal)
  const replaceProjectModal = useSelector((state) => state.modal.replaceProjectModal)
  const newCreditCardModal = useSelector((state) => state.modal.newCreditCardModal)
  const oneTimeSupportModal = useSelector((state) => state.modal.oneTimeSupportModal)
  const cancelSubscriptionModal = useSelector((state) => state.modal.cancelSubscriptionModal)
  const eventCodeModal = useSelector((state) => state.modal.eventCodeModal)
  const removePaymentMethodModal = useSelector((state) => state.modal.removePaymentMethodModal)
  const cancelSubscriptionFeedbackModal = useSelector((state) => state.modal.cancelSubscriptionFeedbackModal)
  const markAllAsRead = useSelector((state) => state.modal.markAllAsRead)
  const removeNotification = useSelector((state) => state.modal.removeNotification)
  const deleteAccount = useSelector((state) => state.modal.deleteAccount)
  const { pathname } = useLocation()
  const needsCountryUpdate = useSelector((state) => state.modal.needsCountryUpdate)
  const accountOnHold = useSelector((state) => state.modal.accountOnHold)
  const payOnHold = useSelector((state) => state.modal.payOnHold)
  const finishPayment = useSelector((state) => state.modal.finishPayment)
  const reSubscription = useSelector((state) => state.modal.reSubscription)
  const cancelEventModal = useSelector((state) => state.modal.cancelEventModal)
  const submitFeedbackModal = useSelector((state) => state.modal.submitFeedbackModal)
  const showNotificationDetailModal = useSelector((state) => state.modal.showNotificationDetailModal)
  const deleteAllNotificationsModal = useSelector((state) => state.modal.deleteAllNotificationsModal)
  const stripePK = useSelector((state) => state.account.stripePK)
  const stripePromise = loadStripe(stripePK)

  const dispatch = useDispatch()

  return (
    <Elements stripe={stripePromise}>
      <div className="w-full">
        {/* monitoring */}
        {removePaymentMethodModal && <RemovePaymentMethodModal />}
        {cancelSubscriptionModal && <CancelSubscriptionModal />}
        {successModal && <SuccessModal />}
        {replaceProjectModal && <ReplaceProjectModal />}
        {newCreditCardModal && <NewCreditCardModal />}
        {oneTimeSupportModal && <OneTimeSupportModal />}
        {eventCodeModal && <EventCodeModal />}
        {cancelSubscriptionFeedbackModal && <CancelSubscriptionFeedbackModal />}
        {markAllAsRead && <MarkAllAsReadModal />}
        {removeNotification && <RemoveNotificationModal />}
        {deleteAccount && <DeleteAccountModal />}
        {needsCountryUpdate && !pathname.includes("/profile/edit") && <CountrySelectionModal />}
        {accountOnHold && <AccountOnHoldModal />}
        {payOnHold && <PayOnHoldModal />}
        {finishPayment && <FinishPaymentModal />}
        {reSubscription && <ReSubscribeModal />}
        {cancelEventModal && <CancelEventModal />}
        {submitFeedbackModal && <SubmitFeedbackModal />}
        {showNotificationDetailModal && <ShowNotificationDetailModal />}
        {deleteAllNotificationsModal && <RemoveAllNotifications />}
        {children}
      </div>
    </Elements>
  )
}
