import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { removeNotification } from "../../store/actions/notifications";
import icon_close_green from "@/assets/img/icon_close_green.svg";
import { ToggleShowNotificationDetailModal } from "../../store/actions/modalActions";

export default function ShowNotificationDetailModal({}) {
  const dispatch = useDispatch();
  const currentNotification = useSelector(
    (state) => state.notifications.currentNotification
  );
  return (
    <>
      <div className="justify-centeritems-center flex overflow-x-hidden items-center overflow-y-auto fixed inset-0 z-[100] outline-none focus:outline-none">
        <div className="relative w-auto my-6 mx-auto max-w-3xl">
          {/*content*/}
          <div className="border-0 rounded-lg rounded shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            {/*body*/}
            <div className="relative bg-lighter-grey w-fit h-fit rounded-8 py-10">
              {/* <div className="flex justify-end mt-3 mr-4">
								<img
									src={icon_close_green}
									onClick={() => {
										dispatch(ToggleShowNotificationDetailModal());
									}}
									className="cursor-pointer"
								></img>
							</div> */}
              <div className="font-bold text-center text-[30px] mx-7 ">
                {currentNotification?.title}
              </div>
              <div className="text-base text-darker-grey/60 mt-4 mx-7 text-center">
                {currentNotification?.description}
              </div>
              <div className="flex w-full justify-center mt-4">
                <button
                  className="w-1/3 py-2 bg-orange_v2 text-white font-bold text-center rounded-[20px]"
                  onClick={() => {
                    dispatch(ToggleShowNotificationDetailModal());
                  }}
                >
                  OK
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  );
}
