import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import icon_close_green from "@/assets/img/icon_close_green.svg";
import { ToggleDeleteAccountModal } from "../../store/actions/modalActions";
import { deleteProfile, logout } from "../../store/actions/accounts";

export default function DeleteAccountModal({}) {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	return (
		<>
			<div className="justify-centeritems-center flex overflow-x-hidden items-center overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
				<div className="relative w-auto my-6 mx-auto max-w-3xl">
					{/*content*/}
					<div className="border-0 rounded-lg rounded shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
						{/*body*/}
						<div className="relative bg-lighter-grey max-w-[872px] laptop:w-[872px] h-fit rounded py-8">
							<div className="flex text-[30px] whitespace-pre justify-start mx-8 ">
								<div className="font-bold">Delete Account?</div>
							</div>
							<div className="text-base text-darker-grey/60 mt-2 mx-8">
								Do you want to permanently delete your Karbon-X account?
							</div>
							<div className="text-base text-darker-grey/60 p-4 mt-2 mx-8 bg-grey rounded">
								Permanently delete your account and all your details. You won't be able
								to see your badges or offset anymore
							</div>
							<div className="flex justify-end gap-2 mx-8">
								<div className="grid justify-center mt-[32px]">
									<div
										onClick={() => {
											dispatch(ToggleDeleteAccountModal());
										}}
										className="font-semibold border text-base px-10 w-[232px] sm:w-fit h-[54px] rounded grid justify-center items-center cursor-pointer"
									>
										No, Keep Account
									</div>
								</div>
								<div className="grid justify-center mt-[32px]">
									<div
										onClick={() => {
											dispatch(deleteProfile()).then(() => {
												dispatch(ToggleDeleteAccountModal());
												dispatch(logout());
												navigate("");
											});
										}}
										className="bg-[#EC3254] text-white font-semibold px-10 text-base w-[250px] sm:w-fit h-[54px] rounded grid justify-center items-center cursor-pointer"
									>
										Delete my Account
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
		</>
	);
}
