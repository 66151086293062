import * as actionTypes from "../action-types"

let initialState = {
  successModal: false,
  successText: null,
  successUrl: null,
  successButtonText: null,
  replaceProjectModal: false,
  newCreditCardModal: false,
  oneTimeSupportModal: false,
  eventCodeModal: false,
  cancelSubscriptionModal: false,
  removePaymentMethodModal: false,
  cancelSubscriptionFeedbackModal: false,
  selectedCard: null,
  needsCountryUpdate: false,
  markAllAsRead: false,
  removeNotification: false,
  deleteAccount: false,
  accountOnHold: false,
  payOnHold: false,
  finishPayment: false,
  reSubscription: false,
  cancelEventModal: false,
  submitFeedbackModal: false,
  showNotificationDetailModal: false,
  deleteAllNotificationsModal: false,
}
function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.TOGGLE_SUCCESS_MODAL:
      return {
        ...state,
        successModal: !state.successModal,
        successText: action.payload.text,
        successUrl: action.payload.url,
        successButtonText: action.payload.buttonText,
      }
    case actionTypes.TOGGLE_REPLACEMENT_PROJECT_MODAL:
      return {
        ...state,
        replaceProjectModal: !state.replaceProjectModal,
      }
    case actionTypes.TOGGLE_NEW_CREDIT_CARD_MODAL:
      return {
        ...state,
        newCreditCardModal: action.payload !== undefined ? action.payload : !state.newCreditCardModal,
      }
    case actionTypes.TOGGLE_SUBMIT_FEEDBACK:
      return {
        ...state,
        submitFeedbackModal: !state.submitFeedbackModal,
      }
    case actionTypes.TOGGLE_ONE_TIME_SUPPORT_MODAL:
      return {
        ...state,
        oneTimeSupportModal: !state.oneTimeSupportModal,
      }
    case actionTypes.TOGGLE_EVENT_CODE_MODAL:
      return {
        ...state,
        eventCodeModal: !state.eventCodeModal,
      }
    case actionTypes.TOGGLE_CANCEL_SUBSCRIPTION_MODAL:
      return {
        ...state,
        cancelSubscriptionModal: !state.cancelSubscriptionModal,
      }
    case actionTypes.TOGGLE_CANCEL_SUBSCRIPTION_FEEDBACK_MODAL:
      return {
        ...state,
        cancelSubscriptionFeedbackModal: !state.cancelSubscriptionFeedbackModal,
      }
    case actionTypes.TOGGLE_MARK_ALL_AS_READ:
      return {
        ...state,
        markAllAsRead: !state.markAllAsRead,
      }
    case actionTypes.TOGGLE_REMOVE_NOTIFICATION:
      return {
        ...state,
        removeNotification: !state.removeNotification,
      }
    case actionTypes.TOGGLE_DELETE_ACCOUNT:
      return {
        ...state,
        deleteAccount: !state.deleteAccount,
      }
    case actionTypes.TOGGLE_REMOVE_PAYMENT_METHOD_MODAL:
      return {
        ...state,
        removePaymentMethodModal: !state.removePaymentMethodModal,
        selectedCard: action.payload,
      }
    case actionTypes.TOGGLE_COUNTRY_SELECTION:
      return {
        ...state,
        needsCountryUpdate: action.payload,
      }
    case actionTypes.TOGGLE_ACCOUNT_ON_HOLD:
      return {
        ...state,
        accountOnHold: action.payload,
      }
    case actionTypes.TOGGLE_PAY_ON_HOLD:
      return {
        ...state,
        payOnHold: action.payload,
      }
    case actionTypes.TOGGLE_FINISH_PAYMENT:
      return {
        ...state,
        finishPayment: action.payload,
      }
    case actionTypes.TOGGLE_RE_SUBSCRIBE:
      return {
        ...state,
        reSubscription: !state.reSubscription,
      }
    case actionTypes.TOGGLE_CANCEL_EVENT:
      return {
        ...state,
        cancelEventModal: !state.cancelEventModal,
      }
    case actionTypes.TOGGLE_SHOW_NOTIFICATION_DETAIL:
      return {
        ...state,
        showNotificationDetailModal: !state.showNotificationDetailModal,
      }
    case actionTypes.TOGGLE_DELETE_ALL_NOTIFICATIONS:
      return {
        ...state,
        deleteAllNotificationsModal: !state.deleteAllNotificationsModal,
      }
    default:
      return state
  }
}
export default reducer
