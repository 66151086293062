import axios from "axios";
import { baseURL } from "./config";
import store from "../store/index.js";
// import actionCreators from "../store/actionCreators/accounts.js";
import { parseError } from "./helper";
import { logout, updateTokens } from "../store/actions/accounts";

// create an axios instance
const service = axios.create({
	baseURL: baseURL,
	timeout: 50000,
	headers: {
		"Content-Type": "application/json;",
	},
	//withCredentials: true, // default
});
// request interceptor
service.interceptors.request.use(
	(config) => {
		const token = sessionStorage.getItem("access_token");
		if (token) {
			config.headers["Authorization"] = "Bearer " + token;
		}
		return config;
	},
	(error) => {
		// Do something with request error
		Promise.reject(error);
	}
);

// respone interceptor
let retryConfigs = [];
service.interceptors.response.use(
	(response) => {
		return response.data;
	},
	async (err) => {
		parseError(err);
		const originalConfig = err.config;

		if (
			(originalConfig?.url !== "/account/login/email" ||
				originalConfig?.url !== "/account/login/social") &&
			err.response
		) {
			// Access Token was expired
			const retry = sessionStorage.getItem("originalConfigRetry");
			if (err.response.status === 401 && !retry) {
				console.log("inside 51");
				if (!retry) {
					sessionStorage.setItem("originalConfigRetry", true);
				}
				// sessionStorage.setItem("originalConfigRetry", true);
				// originalConfig._retry = true;

				try {
					const rs = await service.post("/account/refreshtoken", {
						refreshToken: sessionStorage.getItem("refresh_token"),
					});
					sessionStorage.setItem("originalConfigRetry", false);
					const { accessToken, refreshToken } = rs.data;
					sessionStorage.setItem("access_token", accessToken);
					sessionStorage.setItem("refresh_token", refreshToken);
					store.dispatch(updateTokens(rs.data));
					sessionStorage.removeItem("originalConfigRetry");

					// newer version of Axios is giving troubles here
					originalConfig.headers = { ...originalConfig.headers };
					originalConfig.headers["Authorization"] = "Bearer " + accessToken;

					console.log("retryConfigs70", retryConfigs);
					if (retryConfigs.length > 0) {
						// if there are multiple 401 errors, 
						// this is how we are going to do the api call after accessToken got updated
						let finalConfigs = retryConfigs.map((retryConfig) => {
							retryConfig.headers = { ...retryConfig.headers };
							retryConfig.headers["Authorization"] = "Bearer " + accessToken;
							return service(retryConfig);
						});
						Promise.all(finalConfigs).then((vals) => {
							console.log(vals);
						});
					}

					return service(originalConfig);
				} catch (_error) {
					store.dispatch(logout());
					window.location.href = "/";
					return Promise.reject(_error);
				}
			} else if (err.response.status === 401 && retry) {
				retryConfigs.push(originalConfig);
			}
		}

		return Promise.reject(err);
	}
);

export default service;
