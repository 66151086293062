import * as actionTypes from "../action-types";

let initialState = {
	strategies: [],
	projects: [],
	project: null,
	selectedStrategies: [],
	projectOfTheMonth: null,
	myProjects: [],
	closedProjects: [],
	giftedProjects: [],
};
function reducer(state = initialState, action) {
	switch (action.type) {
		case actionTypes.GET_ALL_STRATEGIES:
			return {
				...state,
				strategies: action.payload,
			};
		case actionTypes.GET_FILTERED_PROJECTES:
			return {
				...state,
				projects: action.payload.projects,
			};
		case actionTypes.UPDATE_SELECTED_STRATEGIES:
			return {
				...state,
				selectedStrategies: action.payload,
			};
		case actionTypes.GET_PROJECT:
			return {
				...state,
				project: action.payload,
			};
		case actionTypes.GET_ALL_PROJECTS:
			return {
				...state,
				projects: action.payload,
			};
		case actionTypes.GET_PROJECT_OF_THE_MONTH:
			return {
				...state,
				projectOfTheMonth: action.payload,
			};
		case actionTypes.GET_MY_PROJECTS:
			return {
				...state,
				myProjects: action.payload.myProjects,
				closedProjects: action.payload.closedProjects,
				giftedProjects: action.payload.giftedProjects,
			};
		default:
			return state;
	}
}
export default reducer;
