import * as actionTypes from "../action-types"

let initialState = {
  loggedIn: false,
  accessToken: null,
  refreshToken: null,
  verificationToken: null,
  verificationCode: null,
  stripePK: null,
  signupInfo: {},
  country: {},
  forgetPassword: {},
  userStrategies: {},
  profile: {},
  mySubscription: {},
  stripePromise: null,
  about: "",
  termsOfService: "",
  privacyPolicy: "",
  currentlySupportedProject: {},
}
function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.LOGIN:
      return {
        ...state,
        loggedIn: true,
        accessToken: action.payload.accessToken,
        refreshToken: action.payload.refreshToken,
        userStrategies: action.payload.userStrategies,
      }
    case actionTypes.SEND_VERIFICATION_TOKEN:
      return {
        ...state,
        verificationToken: action.payload.verificationToken,
      }
    case actionTypes.LOAD_STRIPE:
      return {
        ...state,
        stripePK: action.payload.publicKey,
      }
    case actionTypes.SAVE_SIGNUP_COUNTRY_POSTALCODE:
      return {
        ...state,
        country: {
          ...state.country,
          taxCalculationId: action.payload?.taxCalculationId || state.country?.taxCalculationId,
          countryId: action.payload?.countryId || state.country?.countryId,
          postalCode: action.payload?.postalCode || state.country?.postalCode,
          countryCode: action.payload?.countryCode || state.country?.countryCode,
          countryName: action.payload?.countryName || state.country?.countryName,
        },
      }
    case actionTypes.SAVE_PERSONAL_INFO:
      return {
        ...state,
        signupInfo: {
          ...state.signupInfo,
          firstName: action.payload.firstName,
          lastName: action.payload.lastName,
          email: action.payload.email,
          source: action.payload?.source ? action.payload.source : "Email",
          access_token: action.payload?.access_token ? action.payload.access_token : "",
        },
      }
    case actionTypes.SAVE_PROMO_CODE:
      return {
        ...state,
        signupInfo: {
          ...state.signupInfo,
          promoCode: action.payload,
        },
      }
    case actionTypes.SAVE_PASSWORD:
      return {
        ...state,
        signupInfo: {
          ...state.signupInfo,
          password: action.payload.password,
        },
      }
    case actionTypes.SAVE_NUM_OF_EMPLOYEES:
      return {
        ...state,
        signupInfo: {
          ...state.signupInfo,
          employees: action.payload.employees,
        },
      }
    case actionTypes.SAVE_BUSINESS_NAME:
      return {
        ...state,
        signupInfo: {
          ...state.signupInfo,
          businessName: action.payload.businessName,
        },
      }
    case actionTypes.CLEAR_SIGNUP_INFO:
      return {
        ...state,
        signupInfo: null,
        verificationToken: null,
        verificationCode: null,
      }
    case actionTypes.FORGET_PASSWORD:
      return {
        ...state,
        forgetPassword: {
          ...state.forgetPassword,
          email: action.payload,
        },
      }
    case actionTypes.VALIDATE_RESET_PASSWORD_CODE:
      return {
        ...state,
        forgetPassword: {
          ...state.forgetPassword,
          token: action.payload.token,
        },
      }
    case actionTypes.VERIFY_REGISTRY_VERIFICATION_CODE:
      return {
        ...state,
        verificationCode: action.payload.code,
      }
    case actionTypes.RESET_FORGET_PASSWORD:
      return {
        ...state,
        forgetPassword: null,
      }
    case actionTypes.RESET_FORGET_PASSWORD:
      return {
        ...state,
        accessToken: action.payload.accessToken,
        refreshToken: action.payload.refreshToken,
      }
    case actionTypes.GET_PROFILE:
      return {
        ...state,
        profile: action.payload,
      }
    case actionTypes.UPDATE_PROFILE: {
      return {
        ...state,
        profile: action.payload,
      }
    }
    case actionTypes.GET_MY_SUBSCRIPTION: {
      return {
        ...state,
        mySubscription: action.payload,
      }
    }
    case actionTypes.SAVE_STRIPE_PROMISE: {
      return {
        ...state,
        stripePromise: action.payload,
      }
    }
    case actionTypes.GET_TERMS_OF_SERVICE: {
      return {
        ...state,
        termsOfService: action.payload,
      }
    }
    case actionTypes.GET_PRIVACY_POLICY: {
      return {
        ...state,
        privacyPolicy: action.payload,
      }
    }
    case actionTypes.GET_ABOUT: {
      return {
        ...state,
        about: action.payload,
      }
    }
    default:
      return state
  }
}
export default reducer
