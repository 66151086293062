import React, { lazy, useState, useEffect } from "react"
import Icon_filter_orange from "@/assets/svg/icon_filter_orange.svg"
// for the strategies icon purposes
import SoilButton from "@/assets/svg/Earth.svg"
import BiomassButton from "@/assets/svg/Energy.svg"
import SunButton from "@/assets/svg/innovate.svg"
import OceansButton from "@/assets/img/Water.png"
import ForestryButton from "@/assets/svg/Air.svg"
import { useNavigate, useLocation } from "react-router-dom"
import icon_event_orange from "@/assets/img/icon_calendar_orange.png"
import icon_projects_orange from "@/assets/img/icon_projects_orange.png"
import { useDispatch, useSelector } from "react-redux"
import { filterProjects, getAllProjects, getAllStrategies, getProjectOfTheMonth } from "@/store/actions/projectsActions"
import { updateSelectedStrategies } from "../../store/actions/projectsActions"
import { getEventsByStrategies } from "../../store/actions/events"
import { Energy, Air, Earth, Water, Innovate } from "@/pages/Strategy/strategyConstant.js"
import { getAllNotifications } from "../../store/actions/notifications"
export default function FilterProjectsEventsLayout() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const location = useLocation()
  const loggedIn = useSelector((state) => state.account.loggedIn)

  const projectOfTheMonth = useSelector((state) => state.projects.projectOfTheMonth)
  const selectedStrategies = useSelector((state) => state.projects.selectedStrategies)
  const strategies = useSelector((state) => state.projects.strategies)
  const userStrategies = useSelector((state) => state.account.userStrategies)

  const [selectedStrategiesIcons, setSelectedStrategiesIcons] = useState([])

  const mapStrategies = (res) => {
    return res.map((strategy) => {
      switch (strategy.name) {
        case Energy:
          return { ...strategy, iconColoredURL: BiomassButton }
        case Air:
          return { ...strategy, iconColoredURL: ForestryButton }
        case Innovate:
          return { ...strategy, iconColoredURL: SunButton }
        case Water:
          return { ...strategy, iconColoredURL: OceansButton }
        case Earth:
          return { ...strategy, iconColoredURL: SoilButton }
      }
    })
  }

  useEffect(() => {
    if (userStrategies?.length > 0 && selectedStrategies?.length === 0) {
      if (!strategies) {
        dispatch(getAllStrategies())
      }
      dispatch(updateSelectedStrategies(userStrategies))
      dispatch(filterProjects({ strategies: userStrategies }))
      // if (loggedIn) {
      //   dispatch(getEventsByStrategies({ strategies: userStrategies }))
      // }
    }
    // if (!projectOfTheMonth) {
    dispatch(getProjectOfTheMonth())
    // }

    if (!loggedIn) {
      if (selectedStrategies?.length < 1) {
        // directly go to /projects?
        // redirect to select strategy pages
        navigate("/strategy")
      } else {
        let data = mapStrategies(strategies)
        let selectedStrategiesFilterIcons = data.filter((strategy) => {
          return selectedStrategies.includes(strategy.id)
        })
        setSelectedStrategiesIcons(selectedStrategiesFilterIcons)
      }
    }
  }, [])

  if (strategies?.length === 0) {
    dispatch(getAllStrategies())
  }
  useEffect(() => {
    if (selectedStrategies) {
      let data = mapStrategies(strategies)
      let selectedStrategiesFilterIcons = data.filter((strategy) => {
        return selectedStrategies.includes(strategy?.id)
      })
      setSelectedStrategiesIcons(selectedStrategiesFilterIcons)
    }
  }, [selectedStrategies])

  return (
    <>
      {(location.pathname === "/projects" || location.pathname === "/events") && projectOfTheMonth?.id && (
        <div
          className={`h-[262px] bg-cover  bg-center  rounded mt-10 cursor-pointer relative grid place-items-center`}
          style={{ backgroundImage: `linear-gradient(to bottom, transparent, #292828 95%), url("${projectOfTheMonth?.bannerURL}")` }}
          onClick={() => {
            navigate(`/projects/${projectOfTheMonth?.id}`)
          }}>
          <div className="flex absolute w-[98%] h-[24px] top-2 justify-end gap-2">
            {projectOfTheMonth?.isNewProject && (
              <div className="w-[42px] h-[24px] rounded-[4px] bg-[#212427] top-2 left-2 grid items-center">
                <div className="font-bold text-[10px] text-center text-white uppercase">NEW</div>
              </div>
            )}
            {projectOfTheMonth?.strategy && (
              <div
                className={
                  "w-fit px-4 h-[24px] rounded-[4px] left-2 grid items-center " +
                  (projectOfTheMonth?.strategy === Air && " bg-[#66B5AE] ") +
                  (projectOfTheMonth?.strategy === Earth && " bg-[#D96E56] ") +
                  (projectOfTheMonth?.strategy === Water && " bg-[#3574AE] ") +
                  (projectOfTheMonth?.strategy === Energy && " bg-[#E9C54C] ") +
                  (projectOfTheMonth?.strategy === Innovate && " bg-[#362C5E] ")
                }>
                <div className="text-[10px] text-center text-white uppercase">{projectOfTheMonth.strategy}</div>
              </div>
            )}
            {/* <div className="w-fit px-4 h-[24px] rounded-[4px] left-2 grid items-center bg-[#212427]">
              <div className="text-[10px] text-center text-white uppercase">{projectOfTheMonth?.isProfitProject ? "profit" : "non-profit"}</div>
            </div> */}
          </div>
          {projectOfTheMonth && (
            <>
              <div className="grid place-items-center  ">
                <div className="">
                  <div className="flex justify-center text-white uppercase mt-[26px] font-semibold text-[12px]">Project of the month</div>
                  <div
                    onClick={() => {
                      // navigate(`/projects/${projectOfTheMonth?.id}`);
                    }}
                    className="flex justify-center text-white text-center mt-[9px] font-bold text-[29px]">
                    {projectOfTheMonth?.projectName}
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      )}

      <div className={`mt-[36px] ${location.pathname === "/projects/replacement" ? "" : "tablet:flex tablet:justify-between"} `}>
        <div className="flex text-[35px] whitespace-pre">
          {location.pathname === "/projects" || location.pathname === "/events" ? (
            <div className="uppercase">
              Explore <span className="font-bold">{location.pathname === "/projects" ? "projects" : "events"}</span>
            </div>
          ) : (
            <div className="whitespace-pre-line">
              Select one of our projects <span className="font-bold">to replace your current project</span>
            </div>
          )}
        </div>
        <div className="laptop:flex  gap-4 ">
          <div
            className="flex items-center cursor-pointer  rounded"
            onClick={() => {
              if (location.pathname !== "/projects/replacement") {
                if (location.pathname === "/projects") {
                  navigate("/strategy", { state: "projects" })
                } else {
                  navigate("/strategy", { state: "events" })
                }
              } else if (location.pathname === "/projects/replacement") {
                navigate("/strategy", { state: "replaceProject" })
              }
            }}>
            <img src={Icon_filter_orange} alt="Icon_filter_orange" className="opacity-100"></img>
            <div className="text-[#E9664E] font-semibold text-[16px] mx-2 mr-4 opacity-100 laptop:whitespace-pre">Change Strategy</div>
            <div className="flex overflow-x-auto w-24 p-2 mobile:w-full">
              {selectedStrategiesIcons.map((strategyIcon) => {
                return (
                  <img
                    key={strategyIcon.id}
                    src={strategyIcon.iconColoredURL}
                    alt={strategyIcon.name}
                    className="w-[15px] tablet:w-[30px] mx-1 tablet:mx-2 opacity-100"></img>
                )
              })}
            </div>
          </div>

          {location.pathname !== "/projects/replacement" && loggedIn && (
            <div className="flex  space-x-2 py-2">
              <div
                className={"flex w-[140px]  rounded-full cursor-pointer uppercase " + (location.pathname === "/projects" ? " bg-[#F7EAE8] " : "  ")}
                onClick={() => navigate("/projects")}>
                <img src={icon_projects_orange} alt="icon_event_orange" />
                <div className=" mr-6   text-[#E9664E] font-bold  flex items-center  ">Projects</div>
              </div>
              <div
                className={"flex w-[140px] justify-center rounded-full cursor-pointer uppercase" + (location.pathname === "/events" ? " bg-[#F7EAE8] " : "  ")}
                onClick={() => navigate("/events")}>
                <img src={icon_event_orange} alt="icon_event_orange" />
                <div className="mr-6 text-[#E9664E] font-bold  flex items-center ">Events</div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  )
}
