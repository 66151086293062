import React, { useEffect, useState } from "react"
import StripeForm from "@/components/Stripe"
import closeIcon from "@/assets/img/icon_close_orange.svg"
import { Elements } from "@stripe/react-stripe-js"
import { toast } from "react-toastify"
import { useStripe } from "@stripe/react-stripe-js"
import { useDispatch, useSelector } from "react-redux"
import { useLocation, useNavigate, useOutletContext, useParams } from "react-router-dom"
import { emailLogin, registerEmail, clearSignupInfo, signupWithSocial, signinWithSocial } from "../../../store/actions/accounts"
import { getPersonalSubscription, oneTimeSupportPayGuest } from "@/store/actions/subscriptionsActions"
import { ToggleSuccessModal } from "@/store/actions/modalActions"
import { filterProjects } from "@/store/actions/projectsActions"
import { sendEventPurchase } from "../../../lib/firebase"
import ReactPixel from "react-facebook-pixel"

export default function Page5() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const stripePromise = useSelector((state) => state.account.stripePromise)
  const otp = useSelector((state) => state.otp)
  const stripe = useStripe()
  console.log(otp)

  // password=passwordConfirmation, lastName, firstName, employees
  const signupInfo = useSelector((state) => state.account.signupInfo)
  const country = useSelector((state) => state.account.country)
  console.log(country)
  const verificationCode = useSelector((state) => state.account.verificationCode)
  const verificationToken = useSelector((state) => state.account.verificationToken)
  // projectId: project.id
  const project = useSelector((state) => state.projects.project)
  const strategies = useSelector((state) => state.projects.selectedStrategies)
  const selectedSubscription = useSelector((state) => state.subscriptions.selectedSubscription)
  const subscriptionType = useSelector((state) => state.subscriptions[selectedSubscription])
  const billingFrequency = useSelector((state) => state.subscriptions.selectedBillingFrequency)

  const success = () => {
    if (!otp?.isForSomeoneElse) {
      dispatch(ToggleSuccessModal(<div className="flex justify-center pt-[340px]">Thank you for supporting this project</div>, "/", "Go Back To Karbon-X"))
    } else {
      dispatch(
        ToggleSuccessModal(
          <>
            <div className="flex justify-center pt-[340px]">Thank you for supporting this project on behalf of {otp?.beneficiaryName}</div>
            <br />
            {/* <div className="flex justify-center mt-[-50px]">project</div> */}
            <div className="text-[14px] text-darker-grey/60 flex justify-center pt-4 font-normal">
              <div>
                <p>We will send an email to {otp?.beneficiaryEmail} with details of your gift.</p>
                <p>The recipient will receive a certificate of their carbon offset via email.</p>
              </div>
            </div>
          </>,
          "/",
          "Go Back To Karbon-X"
        )
      )
    }
  }

  let { id } = useParams()
  const submitForm = ({ stripeToken }) => {
    const { timeZone } = Intl.DateTimeFormat().resolvedOptions()
    let data = {
      isForSomeoneElse: otp.isForSomeoneElse,
      beneficiaryEmail: otp.beneficiaryEmail,
      beneficiaryName: otp.beneficiaryName,
      senderEmail: otp.senderEmail,
      senderName: otp.senderName,
      // stripeToken: "string",
      // paymentMethodId: "card",
      customAmount: otp?.paymentInfo?.transactionCost,
      // offsetAmount: 0,
      postalCode: otp?.postalCode,
      projectId: id,
      strategies,
      timeZone,
      stripeToken,
      taxCalculationId: otp?.country?.taxCalculationId,
      countryId: otp?.country?.countryId,
      postalCode: otp?.postalCode,
      countryCode: otp.country.countryCode,
    }

    dispatch(oneTimeSupportPayGuest(data)).then((res) => {
      if (process.env.MODE === "production") {
        sendEventPurchase({
          project,
          token: data.paymentSourceId,
          state: otp?.paymentInfo,
          oneTimeSupport: true,
        })

        ReactPixel.track("Purchase", {
          project,
          token: data.paymentSourceId,
          state: otp?.paymentInfo,
          oneTimeSupport: true,
        })
        window.gtag("event", "conversion", {
          send_to: "AW-11154335658/qg-yCI-cv6AZEKrP5sYp",
        })
      }

      if (res?.paymentIntentSecret) {
        stripe.confirmCardPayment(res?.paymentIntentSecret).then((res) => {
          if (res?.paymentIntent?.status == "succeeded") {
            dispatch({ type: "otp/closeModal" })
            success()
          } else {
            toast.error(res?.error?.message)
          }
        })
      } else {
        success()
        dispatch({ type: "otp/closeModal" })
      }
      dispatch({ type: "otp/closeModal" })
    })
  }
  return (
    <div className="w-screen h-screen justify-center flex overflow-x-hidden items-center overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-darker-grey/60">
      <div className={"max-w-[582px] w-screen bg-lighter-grey rounded py-4 px-2 relative overflow-y-auto sm:h-screen grid place-items-center"}>
        <div className="w-full flex justify-between">
          <div className="px-8 font-bold text-[30px]">Offset Payment</div>
          <div className=" grid place-items-center">
            <img src={closeIcon} alt="closeIcon" className=" cursor-pointer " onClick={() => dispatch({ type: "otp/closeModal" })} />
          </div>
        </div>
        <br></br>
        <div className=" w-full px-8 ">
          <Elements stripe={stripePromise}>
            <StripeForm
              showCheckMark={false}
              namePlaceHolder="Enter the name on the card"
              nameTitle="Cardholder"
              submitForm={submitForm}
              inputStyle="bg-grey w-full h-[40px] mt-2 mb-4 relative pl-[10px] rounded placeholder:text-[14px] focus-visible:outline focus-visible:outline-orange_v2"
            />
          </Elements>
        </div>
      </div>
    </div>
  )
}
