import { initializeApp } from "firebase/app"
import { getAnalytics, logEvent } from "firebase/analytics"

const firebaseConfig = {
  apiKey: process.env.FIREBASE_API_KEY,
  authDomain: process.env.FIREBASE_AUTH_DOMAIN,
  projectId: process.env.FIREBASE_PROJECT_ID,
  storageBucket: process.env.FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.FIREBASE_APP_ID,
  measurementId: process.env.FIREBASE_MEASUREMENT_ID,
}
const isAnalyticsEnabled = () => process.env.MODE === "production"

// Initialize Firebase
export function initializeFirebase() {
  // console.log(
  //     "Initializing firebase",
  //     "Analytics:",
  //     isAnalyticsEnabled() ? "enabled" : "disabled"
  // );
  if (isAnalyticsEnabled()) {
    try {
      const app = initializeApp(firebaseConfig)
      const analytics = getAnalytics(app)
    } catch (error) {
      console.error(error)
    }
  }
}

const FIREBASE_TYPES = {
  VIEW_ITEM_LIST: "view_item_list",
  SELECT_ITEM: "select_item",
  VIEW_ITEM: "view_item",
  ADD_TO_CART: "add_to_cart",
  BEGIN_CHECKOUT: "begin_checkout",
  PURCHASE: "purchase",
}

// View project list
/*When the user finishes selecting the strategies and the projects are filtered*/
export function sendEventViewProjectList(data) {
  try {
    // data: name, id, projects
    const projects = data.map((project) => {
      return {
        item_id: project.id,
        item_name: project.projectName,
        item_category: project.strategy,
      }
    })

    // Prepare ecommerce params
    const params = {
      // item_list_id: "L001",
      // item_list_name: "List projects",
      items: projects,
    }

    // Log event
    const analytics = getAnalytics()
    logEvent(analytics, FIREBASE_TYPES.VIEW_ITEM_LIST, params)
  } catch (error) {
    console.error(error)
  }
}

// Selecting project
export function sendEventSelectProject(data) {
  try {
    const project = {
      item_id: data.id,
      item_name: data.projectName,
      item_category: data.strategy,
    }
    // Prepare ecommerce event params
    const params = {
      item_list_id: "L002",
      item_list_name: "Selected project",
      items: [project],
    }

    // Log event
    const analytics = getAnalytics()
    logEvent(analytics, FIREBASE_TYPES.SELECT_ITEM, params)
  } catch (error) {
    console.error(error)
  }
}
// Viewing projects
/*When the user selects a project*/
export function sendEventViewProjectDetails(data) {
  try {
    const project = {
      item_id: data.project?.id,
      item_name: data.project?.projectName,
      item_category: data.project?.strategy,
    }

    // Prepare ecommerce event params
    const params = {
      currency: "CAD",
      value: 0, //no value for a carbon offset project
      items: [project],
    }

    // Log event
    const analytics = getAnalytics()
    logEvent(analytics, FIREBASE_TYPES.VIEW_ITEM, params)
  } catch (error) {
    console.error(error)
  }
}
export function sendEventAddToCart(data) {
  try {
    const price = data.billingFrequency === "annually" ? data?.subscriptionPlan.yearlyFee : data?.subscriptionPlan.monthlyFee
    const project = {
      item_id: data.project?.id,
      item_name: data.project?.projectName,
      item_category: data.project?.strategy,
      item_brand: data.subscriptionPlan?.name ? data.subscriptionPlan.name.toUpperCase() : "",
      price: price,
      quantity: 1,
    }
    // Prepare ecommerce bundle
    const params = {
      currency: "CAD",
      value: price,
      items: [project],
    }

    // Log event when a product is added to the cart
    const analytics = getAnalytics()
    logEvent(analytics, FIREBASE_TYPES.ADD_TO_CART, params)
  } catch (error) {
    console.error(error)
  }
}
export function sendEventBeginCheckout(data) {
  try {
    if (data?.paymentInfo) {
      const price = data.paymentInfo.total
      const project = {
        item_id: data.project?.id,
        item_name: data.project?.projectName,
        item_category: data.project?.strategy,
        item_brand: data.subscriptionPlan?.name ? data.subscriptionPlan.name.toUpperCase() : "",
        price: price,
        quantity: 1,
      }

      const params = {
        currency: "CAD",
        value: price, // Total Revenue
        coupon: data?.promoCode,
        items: [project],
      }

      // Log event
      const analytics = getAnalytics()
      logEvent(analytics, FIREBASE_TYPES.BEGIN_CHECKOUT, params)
    }
  } catch (error) {
    console.error(error)
  }
}

export function sendEventPurchase(data) {
  try {
    if (data?.state?.paymentInfo && data?.token) {
      const price = data.state.paymentInfo?.total
      const isOTS = data.oneTimeSupport && data.oneTimeSupport === true
      const project = {
        item_id: data.project?.id,
        item_name: data.project?.projectName,
        item_category: data.project?.strategy,
        item_brand: isOTS ? "ONE TIME SUPPORT" : data.state.subscriptionPlan?.name ? data.state.subscriptionPlan.name.toUpperCase() : "",
        price: isOTS ? data.state.paymentInfo.transactionCost : price,
        quantity: 1,
      }

      //Prepare ecommerce bundle
      const params = {
        transaction_id: data.token,
        affiliation: "Stripe",
        currency: "CAD",
        value: price, // Total Revenue
        tax: data.state.paymentInfo?.tax,
        coupon: data.state.promoCode || "",
        items: [project],
      }

      // Log event
      const analytics = getAnalytics()
      logEvent(analytics, FIREBASE_TYPES.PURCHASE, params)
    }
  } catch (error) {
    console.error(error)
  }
}
/* 
1. Support this project
2. Profile selected (personal/business)
3. Subscription type selected (individual,couple,family)
4. Business tracking employee size
5. Purchase (pay now button)
*/

// Get started and already a member button
// export function sendEventMemberStatus(data) {
//     //member or guest
//     const analytics = getAnalytics();
//     logEvent(analytics, "member_status", { status: data.status });
// }
