import React, { useEffect, useState } from "react"
import StripeForm from "@/components/Stripe"
import closeIcon from "@/assets/img/icon_close_orange.svg"
import { Elements } from "@stripe/react-stripe-js"
import icon_close_green from "@/assets/svg/icon_close_orange.svg"
import { ToggleNewCreditCardModal } from "@/store/actions/modalActions"
import { useDispatch, useSelector } from "react-redux"
import { getPaymentSources, getPayOnHoldSecret } from "../../../store/actions/payment"
import { filterProjects } from "../../../store/actions/projectsActions"
import { TogglePayOnHoldModal } from "@/store/actions/modalActions"
import { getMySubscription } from "../../../store/actions/accounts"

export default function NewCreditCardModal({}) {
  const dispatch = useDispatch()
  const stripePromise = useSelector((state) => state.account.stripePromise)
  const strategies = useSelector((state) => state.projects.selectedStrategies)

  const submitForm = () => {
    dispatch(getPaymentSources()).then(() => {
      dispatch(filterProjects({ strategies: strategies }))
    })
  }
  const mySubscription = useSelector((state) => state.account.mySubscription)

  return (
    <div className="w-screen h-screen justify-center flex overflow-x-hidden items-center overflow-y-auto fixed inset-0 z-[999] outline-none focus:outline-none bg-darker-grey/60">
      <div className="flex flex-col bg-lighter-grey w-[460px] h-fit px-8 rounded pb-8">
        {mySubscription?.status?.toLowerCase() !== "onhold" && (
          <div className="flex justify-end mt-3 -mr-4">
            <img src={icon_close_green} className="cursor-pointer invisible"></img>
          </div>
        )}
        <p
          className={
            "uppercase font-bold text-[36px] relative leading-tight w-full" + (mySubscription?.status?.toLowerCase() === "onhold" ? " mt-8 " : " -mt-6 ")
          }>
          <div className="flex justify-between">
            New Card
            <img
              src={closeIcon}
              alt="closeIcon"
              className="cursor-pointer"
              onClick={() => {
                dispatch(ToggleNewCreditCardModal(false))
                dispatch(getMySubscription())
              }}
            />
          </div>
        </p>
        <p className="uppercase font-semibold text-[14px] relative leading-tight mt-5 text-darker-grey/30">Payment</p>
        <div className="mt-4 bg-white p-3">
          <Elements stripe={stripePromise}>
            <StripeForm
              showPromoCode={false}
              submitForm={submitForm}
              nameTitle="Cardholder"
              namePlaceHolder="What's the cardholder's name?"
              buttonName="Submit"
              buttonStyle="flex justify-end  "
              inputStyle="w-full bg-grey h-[40px] mt-2 mb-4 relative pl-[10px] rounded placeholder:text-[14px] focus-visible:outline focus-visible:outline-orange_v2"
            />
          </Elements>
        </div>
      </div>
    </div>
  )
}
