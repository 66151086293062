import React from "react"
import { useDispatch } from "react-redux"

import { ToggleAccountOnHoldModal, ToggleNewCreditCardModal } from "../../store/actions/modalActions"

export default function AccountOnHoldModal({}) {
  const dispatch = useDispatch()

  return (
    <>
      <div className="justify-centeritems-center flex overflow-x-hidden items-center overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative w-auto my-6 mx-auto max-w-3xl">
          {/*content*/}
          <div className="border-0 rounded-lg rounded shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            {/*body*/}
            <div className="relative bg-lighter-grey w-fit h-fit rounded">
              <div className="flex text-[30px] whitespace-pre justify-start mx-7 mt-4">
                <div className="font-bold">Account on Hold</div>
              </div>
              <div className="text-base text-darker-grey/60 mt-2 mx-7">Something went wrong with your payment. Please update your payment method</div>
              <div className="flex justify-end mr-7 gap-2 mb-4">
                <div className="grid justify-center mt-[32px]">
                  <div
                    onClick={() => {
                      dispatch(ToggleAccountOnHoldModal(false))
                      dispatch(ToggleNewCreditCardModal())
                    }}
                    className="bg-[#EC3254] text-white font-semibold text-base w-[232px] h-[54px] rounded grid justify-center items-center cursor-pointer">
                    Update
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  )
}
