import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { removeNotification } from "../../store/actions/notifications";
import icon_close_green from "@/assets/img/icon_close_green.svg";
import { ToggleRemoveNotificationModal } from "../../store/actions/modalActions";

export default function RemoveNotificationModal({}) {
	const dispatch = useDispatch();
	const currentNotification = useSelector(
		(state) => state.notifications.currentNotification
	);
	return (
		<>
			<div className="justify-centeritems-center flex overflow-x-hidden items-center overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
				<div className="relative w-auto my-6 mx-auto max-w-3xl">
					{/*content*/}
					<div className="border-0 rounded-lg rounded shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
						{/*body*/}
						<div className="relative bg-lighter-grey w-[872px] h-[232px] rounded">
							<div className="flex justify-end mt-3 mr-4">
								<img
									src={icon_close_green}
									onClick={() => {
										dispatch(ToggleRemoveNotificationModal());
									}}
									className="cursor-pointer"
								></img>
							</div>
							<div className="flex text-[30px] whitespace-pre justify-start mx-7 -mt-4">
								<div className="font-bold">Remove notification?</div>
							</div>
							<div className="text-base text-darker-grey/60 mt-2 mx-7">
								Are you sure you want to remove this notification?
							</div>
							<div className="flex justify-end mr-7 gap-2">
								<div className="grid justify-center mt-[32px]">
									<div
										onClick={() => {
											dispatch(ToggleRemoveNotificationModal());
										}}
										className="font-semibold border text-base w-[232px] h-[54px] rounded grid justify-center items-center cursor-pointer"
									>
										Cancel
									</div>
								</div>
								<div className="grid justify-center mt-[32px]">
									<div
										onClick={() => {
											dispatch(removeNotification(currentNotification.id)).then(() => {
												dispatch(ToggleRemoveNotificationModal());
											});
										}}
										className="bg-[#EC3254] text-white font-semibold text-base w-[232px] h-[54px] rounded grid justify-center items-center cursor-pointer"
									>
										Confirm
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
		</>
	);
}
