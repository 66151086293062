import React, { useState, useEffect } from "react"
import InputField from "@/components/InputField"
import { oneTimeSupportCalculateGuest } from "@/store/actions/subscriptionsActions"
import { getInvoicesEstimateGuest } from "@/store/actions/payment"
import { useDispatch, useSelector } from "react-redux"
import { isValidEmail } from "@/utils/helper"
import { useParams } from "react-router-dom"
// import "./index.css"
import closeIcon from "@/assets/img/icon_close_orange.svg"

export default function SupportDetail(props) {
  const dispatch = useDispatch()
  const [carbonOffset, setCarbonOffset] = useState(0)
  const [annualCarbonOffset, setAnnualCarbonOffset] = useState(0)
  const cards = useSelector((state) => state.payment.cards)
  const profile = useSelector((state) => state.account.profile)
  const mySubscription = useSelector((state) => state.account.mySubscription)
  const personal = useSelector((state) => state.subscriptions.personal)
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [beneficiaryName, setBeneficiaryName] = useState("")
  const [beneficiaryEmail, setBeneficiaryEmail] = useState("")
  const [customAmount, setCustomAmount] = useState(0)
  const [offsetAmount, setOffsetAmount] = useState(0)
  const [totalCustomAmount, setTotalCustomAmount] = useState("")
  const [totalCarbonOffset, setTotalCarbonOffset] = useState("")
  const selectedProfile = personal.find((subscription) => subscription.id === mySubscription.subscriptionTypeId)
  const [paymentInfo, setPaymentInfo] = useState()
  const project = useSelector((state) => state.projects.project)
  const otp = useSelector((state) => state.otp)
  const [selectedOption, setSelectedOption] = useState("carbon-offset")
  const [disabled, setDisabled] = useState(true)
  const [emailError, setEmailError] = useState("")
  const [beneficiaryEmailError, setBeneficiaryEmailError] = useState(false)
  const [minOffset, setMinOffset] = useState(250)
  const [minCustom, setMinCustom] = useState(20)

  let { id } = useParams()
  const getEstimate = () => {
    dispatch(
      getInvoicesEstimateGuest({
        projectId: id,
        name,
        email,
        beneficiaryName,
        beneficiaryEmail,
        offsetAmount: selectedOption == "carbon-offset" ? offsetAmount : 0,
        customAmount: selectedOption == "custom" ? customAmount : 0,
        postalCode: otp.postalCode,
        countryCode: otp.country?.countryCode,
      })
    )
      .then((res) => {
        dispatch({ type: "otp/updateUserAction", payload: "summary" })
        dispatch({ type: "otp/updateSummary", payload: res })
        dispatch({ type: "otp/updatePaymentInfo", payload: res })
        setPaymentInfo(res)
      })
      .catch((e) => {
        return
      })
  }
  const updateSubtotalOnInput = async (value) => {
    if (value)
      dispatch(
        oneTimeSupportCalculateGuest({
          name: name,
          email: email,
          beneficiaryName,
          beneficiaryEmail,
          offsetAmount: selectedOption == "carbon-offset" ? value : null,
          customAmount: selectedOption == "custom" ? value : null,
          postalCode: otp.postalCode,
          countryCode: otp.country?.countryCode,
        })
      ).then((res) => {
        dispatch({ type: "otp/tempCustom", payload: res.totalCustomAmount })
        setTotalCustomAmount(res.totalCustomAmount)
        dispatch({ type: "otp/tempOffset", payload: res.totalCarbonOffset })
        setTotalCarbonOffset(res.totalCarbonOffset)
        setMinCustom(res.minimumChargeAmount)
        setMinOffset(res.minimumOffsetAmount)
      })
  }

  useEffect(() => {
    dispatch(
      oneTimeSupportCalculateGuest({
        name: name,
        email: email,
        beneficiaryName,
        beneficiaryEmail,
        offsetAmount: selectedOption == "carbon-offset" ? 0 : null,
        customAmount: selectedOption == "custom" ? 0 : null,
        postalCode: otp.postalCode,
        countryCode: otp.country?.countryCode,
      })
    ).then((res) => {
      setMinCustom(res.minimumChargeAmount)
      setMinOffset(res.minimumOffsetAmount)
    })
  }, [])

  useEffect(() => {
    if (otp.isForSomeoneElse) {
      if (selectedOption == "carbon-offset") {
        if (name && email && beneficiaryEmail && beneficiaryName && customAmount == 0 && offsetAmount >= minOffset) setDisabled(false)
        else setDisabled(true)
      } else if (selectedOption == "custom") {
        if (name && email && beneficiaryEmail && beneficiaryName && customAmount >= minCustom && offsetAmount == 0) setDisabled(false)
        else setDisabled(true)
      }
    } else {
      if (selectedOption == "carbon-offset") {
        if (name && email && customAmount == 0 && offsetAmount >= minOffset) setDisabled(false)
        else setDisabled(true)
      } else if (selectedOption == "custom") {
        if (name && email && customAmount >= minCustom && offsetAmount == 0) setDisabled(false)
        else setDisabled(true)
      }
    }
  }, [name, email, beneficiaryEmail, beneficiaryName, customAmount, offsetAmount, minOffset, minCustom])

  const nextStep = async () => {
    if (!isValidEmail(email)) {
      setEmailError("Email Formatted Incorrectly")
      return
    } else {
      setEmailError("")
    }
    if (!isValidEmail(beneficiaryEmail) && otp?.isForSomeoneElse) {
      setBeneficiaryEmailError("Email Formatted Incorrectly")
      return
    } else {
      setBeneficiaryEmailError("")
    }
    getEstimate()
  }

  return (
    <>
      <div className="w-screen h-screen flex justify-center  overflow-x-hidden items-center overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-darker-grey/60 ">
        <div className="max-w-[882px] w-screen bg-lighter-grey rounded py-4 relative overflow-y-auto sm:h-screen">
          <div className="flex justify-between ">
            <div className="px-8 font-bold text-[30px] text-black z-50">Support Details</div>
            <div className="grid place-items-start ">
              <img src={closeIcon} alt="closeIcon" className="pr-4 cursor-pointer" onClick={() => dispatch({ type: "otp/closeModal" })} />
            </div>
          </div>
          <div className="flex  p-8 gap-4 sm:flex-col  max-w-[882px] w-screen bg-lighter-grey rounded py-4 relative overflow-y-auto sm:h-screen ">
            <div className="w-full desktop:grid grid-cols-2 gap-4">
              <div className="">
                <div className="w-full">
                  <div className="text-[14px] text-darker-grey/30 font-semibold uppercase ">Sender</div>
                  <div className={"border-2 border-gray-400 bg-white w-full  rounded-[15px] items-center px-4 py-4 mt-2  "}>
                    <div className="mt-[32px]">
                      <div className="font-bold text-base my-2">Name</div>
                      <InputField
                        onChange={(v) => {
                          setName(v)
                          dispatch({ type: "otp/updateSenderName", payload: v })
                        }}
                        inputStyle="w-full h-14 bg-grey rounded px-4"
                        placeholder="Your Name"
                      />
                      <div className="font-bold text-base my-2">Email</div>
                      <InputField
                        onChange={(v) => {
                          setEmail(v)
                          dispatch({ type: "otp/updateSenderEmail", payload: v })
                        }}
                        inputStyle="w-full h-14 bg-grey rounded px-4"
                        placeholder="Your email"
                      />
                      <p className="font-semibold text-red-500 text-sm mt-2">{emailError}</p>
                    </div>
                  </div>
                </div>
              </div>
              {otp.isForSomeoneElse && (
                <div className="">
                  <div className="w-full">
                    <div className="text-[14px] text-darker-grey/30 font-semibold uppercase ">Beneficiary</div>
                    <div className={"border-2 border-gray-400 bg-white w-full  rounded-[15px] items-center px-4 py-4 mt-2  "}>
                      <div className="mt-[32px]">
                        <div className="font-bold text-base my-2">Recipient Name</div>
                        <InputField
                          onChange={(v) => {
                            setBeneficiaryName(v)
                            dispatch({ type: "otp/updateBeneficiaryName", payload: v })
                          }}
                          inputStyle="w-full h-14 bg-grey rounded px-4"
                          placeholder="Enter Their Name"
                        />
                        <div className="font-bold text-base my-2">Recipient Email</div>
                        <InputField
                          onChange={(v) => {
                            setBeneficiaryEmail(v)
                            dispatch({ type: "otp/updateBeneficiaryEmail", payload: v })
                          }}
                          inputStyle="w-full h-14 bg-grey rounded px-4"
                          placeholder="Enter Their Email"
                        />
                        <p className="font-semibold text-red-500 text-sm mt-2">{beneficiaryEmailError}</p>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div className="">
                <div className="text-[14px] text-darker-grey/30 font-semibold uppercase ">Amount of support:</div>
                <div className={"bg-white w-full rounded-[20px] mt-2 px-4 grid pt-4 pb-2 border-2 border-gray-500"}>
                  <div className="flex justify-between h-[51px] gap-4">
                    <div
                      onClick={() => {
                        setSelectedOption("carbon-offset")
                        setCustomAmount(0)
                        setDisabled(true)
                      }}
                      className={"uppercase w-1/2 h-[51px] justify-center grid content-center rounded-[40px] cursor-pointer font-semibold" + (selectedOption === "carbon-offset" ? " bg-gray-900 text-white " : " bg-gray-200 text-black ")}>
                      Offset Amount
                    </div>
                    <div
                      onClick={() => {
                        setSelectedOption("custom")
                        setOffsetAmount(0)
                        setDisabled(true)
                      }}
                      className={"uppercase h-[51px] justify-center grid content-center rounded-[40px] cursor-pointer font-semibold" + (profile?.userType === "Business" ? " w-full " : "  w-1/2 ") + (selectedOption === "custom" ? " bg-gray-900 text-white " : " bg-gray-200 text-black ")}>
                      Dollar Amount
                    </div>
                  </div>

                  <>
                    {selectedOption == "carbon-offset" ? (
                      <div className="input-box mt-2">
                        <span className="prefix">Kg</span>
                        <input
                          type="number"
                          // placeholder=""
                          onChange={(e) => {
                            setOffsetAmount(e.target.value)
                            updateSubtotalOnInput(e.target.value)
                          }}
                          onFocus={() => {}}
                          value={Number(offsetAmount).toString()}
                        />
                      </div>
                    ) : (
                      <div className="input-box mt-2">
                        <span className="prefix">$</span>
                        <input
                          type="number"
                          // placeholder=""
                          onChange={(e) => {
                            setCustomAmount(e.target.value)
                            updateSubtotalOnInput(e.target.value)
                          }}
                          onFocus={() => {}}
                          value={Number(customAmount).toString()}
                        />
                      </div>
                    )}
                    {selectedOption == "carbon-offset" ? <div className="text-[12px] mt-2">{minOffset} kg minimum</div> : <div className="text-[12px] mt-2">${minCustom} minimum</div>}
                    <div className="border-dashed border-b border-[#E0E5EB] my-2"></div>
                    <div className="flex justify-between text-[12px] mt-2">
                      <p className="text-[12px]">Your support total:</p>
                      <div className="font-bold text-[12px]">$ {Number(totalCustomAmount ? totalCustomAmount : 0).toFixed(2)}</div>
                    </div>
                    <div className="flex justify-between text-[12px] mt-2 pb-4">
                      <p className="text-[12px]">Carbon offset:</p>
                      <div className="font-bold text-[12px]">{Number(totalCarbonOffset ? totalCarbonOffset : 0).toFixed(2)} kg</div>
                    </div>
                    {/* <div className="flex justify-between text-[12px] mt-2">
                  <p className="text-[12px]">Service Fee:</p>
                  <div className="font-bold text-[12px]">${Number(paymentInfo?.stripeFee ? paymentInfo?.stripeFee : 0).toFixed(2)}</div>
                </div> */}
                  </>
                </div>
              </div>
            </div>
          </div>
          <div className="flex justify-end  bg-lighter-grey w-full max-w-[882px]">
            <button className={`uppercase max-w-[505px]  h-[54px] px-4 py-2 mx-2 ${disabled ? "bg-gray-200" : "bg-[#E9664E]"}  justify-center grid content-center rounded-[32px] cursor-pointer text-white font-semibold disabled:bg-dark-grey disabled:cursor-default`} onClick={disabled ? undefined : nextStep}>
              Proceed To Payment
            </button>
          </div>
          <div className="grid grid-cols-2 gap-6 "></div>
        </div>
      </div>
    </>
  )
}
