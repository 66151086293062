import React, { useEffect, useState } from "react"
import closeIcon from "@/assets/img/icon_close_green.svg"
import { useDispatch, useSelector } from "react-redux"
import { ToggleCancelSubscriptionFeedbackModal } from "@/store/actions/modalActions"
import { cancelSubscription, getCancellationReasons } from "../../store/actions/subscriptionsActions"
import { getMySubscription } from "../../store/actions/accounts"

export default function CancelSubscriptionFeedbackModal() {
  const dispatch = useDispatch()
  const mySubscription = useSelector((state) => state.account.mySubscription)
  const [reasons, setReasons] = useState([])
  const [selected, setSelected] = useState("")
  const [message, setMessage] = useState("")

  useEffect(() => {
    dispatch(getCancellationReasons()).then((res) => {
      let data = res.map((reason) => {
        return { ...reason, selected: false }
      })
      setReasons(data)
    })
  }, [])
  console.log(selected, !selected)
  return (
    <div className="w-screen h-screen justify-center flex overflow-x-hidden items-center overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-darker-grey/60">
      <div className="flex flex-col bg-lighter-grey w-[872px] h-[600px] rounded">
        <div className="flex justify-end">
          <img src={closeIcon} alt="closeIcon" className="cursor-pointer" onClick={() => dispatch(ToggleCancelSubscriptionFeedbackModal())} />
        </div>
        <div className="px-8 -mt-3">
          <div className="font-bold text-[30px]">Feedback</div>
          <div className="font-bold text-[24px] mt-4">Tell us why you're cancelling your subscription</div>
          <div className="flex flex-col gap-4 mt-4">
            {reasons?.map((reason) => {
              return (
                <label key={reason.reasonId} className="flex gap-2 text-[#788088] font-normal text-sm items-center">
                  <input
                    className="border-grey border-2 w-6 h-6 shadow-lighter-grey"
                    type="radio"
                    value={reason.reason}
                    checked={selected === reason.reasonId}
                    onChange={() => {
                      setSelected(reason.reasonId)
                    }}
                  />{" "}
                  {reason.reason}
                </label>
              )
            })}
          </div>

          <div className="font-semibold mt-4">Message</div>
          <textarea
            placeholder="Your feedback matters. Is there anything else you'd like us to know?"
            rows={5}
            cols={101}
            disabled={selected !== 4}
            className="bg-grey outline-none p-2 mt-2  w-full rounded  "
            onChange={(e) => setMessage(e.target.value)}></textarea>

          <div className="flex justify-end mt-2">
            <button
              disabled={selected === "" || (selected === 4 && message.length === 0)}
              className={
                "cursor-pointer flex justify-center items-center h-[54px] w-[232px] font-semibold text-base rounded-full " +
                (selected === "" || (selected === 4 && message.length === 0) ? " bg-grey text-darker-grey/60" : " bg-[#E9664E] text-white ")
              }
              onClick={() => {
                let data = { reasonId: selected, reason: message }
                dispatch(cancelSubscription(mySubscription?.id, data)).then(() => {
                  dispatch(ToggleCancelSubscriptionFeedbackModal())
                  dispatch(getMySubscription())
                })
              }}>
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
