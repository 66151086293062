import React, { useEffect, useState } from "react"
import closeIcon from "@/assets/img/icon_close_orange.svg"
import { useDispatch, useSelector } from "react-redux"
import { ToggleSuccessModal, ToggleReplacementProjectModal } from "@/store/actions/modalActions"
import { filterProjects, switchProject } from "../../store/actions/projectsActions"
import { getProfile } from "../../store/actions/accounts"
// import { getNavbarStatusBar } from "../../store/actions/notifications";
import { getNextBillingDate } from "../../store/actions/subscriptionsActions"

export default function ReplaceProjectModal({}) {
  const dispatch = useDispatch()
  const profile = useSelector((state) => state.account.profile)
  const project = useSelector((state) => state.projects.project)
  const [nextBillingDate, setNextBillingDate] = useState("")
  const selectedStrategies = useSelector((state) => state.projects.selectedStrategies)
  useEffect(() => {
    dispatch(getNextBillingDate()).then((res) => {
      setNextBillingDate(res?.nextBillingDate)
    })
  }, [])
  return (
    <div className="w-screen h-screen justify-center flex overflow-x-hidden items-center overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-darker-grey/60">
      <div className="flex flex-col bg-lighter-grey laptop:w-[872px] max-w-[872px] w-fit h-fit px-8 pb-8 rounded">
        <div className="flex justify-end">
          <img src={closeIcon} alt="closeIcon" className="pt-4 cursor-pointer" onClick={() => dispatch(ToggleReplacementProjectModal())} />
        </div>
        <div>
          <div className="pt-4 text-[30px] w-full flex justify-center text-center">You're currently supporting</div>
          <div className="font-bold text-[30px] w-full text-center">{profile?.currentlySupportedProject?.projectName}</div>
        </div>
        <div className="pt-4 text-base w-full grid text-center justify-center text-darker-grey/60">
          Are you sure you want to switch the project you support?
        </div>

        <div className="grid w-full justify-center mt-8">
          <div className="max-w-[396px] laptop:w-[396px] px-4 h-fit bg-white rounded p-2">
            <div className="text-base font-bold">Next billing cycle</div>
            <div className="text-sm text-darker-grey/60 flex justify-between mt-1">
              <div className="font-bold">Next billing cycle starts on:</div>
              <div>{nextBillingDate}</div>
            </div>
            <div className="text-sm text-darker-grey/60 mt-2">Your support will go toward this project starting form next billing cycle.</div>
          </div>
        </div>

        <div className="grid justify-center mt-8">
          <button
            className="uppercase laptop:w-[252px] px-4 h-[54px] bg-orange_v2 rounded-[40px] cursor-pointer text-white font-semibold"
            onClick={() => {
              dispatch(switchProject(project?.id)).then(() => {
                dispatch(getProfile())
                dispatch(ToggleReplacementProjectModal())
                dispatch(filterProjects({ strategies: selectedStrategies }))
                // dispatch(getNavbarStatusBar());
                dispatch(
                  ToggleSuccessModal(
                    <>
                      <div className="flex justify-center pt-[400px]">Your subscription will be switched on the next billing cycle </div>
                      {/* <br />
											<div className="flex justify-center mt-[-50px]">
												replaced your project
											</div> */}
                    </>
                  )
                )
              })
            }}>
            Confirm Replacement
          </button>
        </div>
      </div>
    </div>
  )
}
