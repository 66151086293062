import * as actionTypes from "../action-types"
import axios from "@/utils/request.js"

let token = sessionStorage.getItem("access_token")

const multipartHeader = {
  headers: {
    Authorization: `Bearer ${token}`,
    "content-type": "multipart/form-data",
  },
}

export function filterProjects(data) {
  return (dispatch) =>
    new Promise(async (resolve, reject) => {
      try {
        dispatch({ type: actionTypes.SHOW_LOADING, payload: true })
        let res = await axios.post(`/projects/strategies`, data)
        dispatch({
          type: actionTypes.GET_FILTERED_PROJECTES,
          payload: { projects: res.data },
        })
        // resolve("success")
        resolve(res.data)
      } catch (error) {
      } finally {
        dispatch({ type: actionTypes.HIDE_LOADING, payload: false })
      }
    })
}

export function updateSelectedStrategiesOnSignUp(data) {
  return function (dispatch) {
    dispatch({ type: actionTypes.UPDATE_SELECTED_STRATEGIES, payload: data })
  }
}

export function updateSelectedStrategies(data) {
  return (dispatch) =>
    new Promise(async (resolve, reject) => {
      try {
        dispatch({ type: actionTypes.SHOW_LOADING, payload: true })
        dispatch({ type: actionTypes.UPDATE_SELECTED_STRATEGIES, payload: data })
        let res = await axios.put(`/profile/strategies`, { strategies: data })
        // resolve(res.data)
      } catch (error) {
      } finally {
        dispatch({ type: actionTypes.HIDE_LOADING, payload: false })
      }
    })
}

export function getAllStrategies() {
  return (dispatch) =>
    new Promise(async (resolve, reject) => {
      try {
        dispatch({ type: actionTypes.SHOW_LOADING, payload: true })
        let res = await axios.get(`/strategies`)
        dispatch({
          type: actionTypes.GET_ALL_STRATEGIES,
          payload: res.data,
        })
        resolve(res.data)
      } catch (error) {
      } finally {
        dispatch({ type: actionTypes.HIDE_LOADING, payload: false })
      }
    })
}

export function getDefaultProject() {
  return (dispatch) =>
    new Promise(async (resolve, reject) => {
      try {
        dispatch({ type: actionTypes.SHOW_LOADING, payload: true })
        let res = await axios.get(`/projects/guest/project-of-the-month`)
        dispatch({ type: actionTypes.GET_PROJECT, payload: res.data })
        resolve(res.data)
      } catch (error) {
      } finally {
        dispatch({ type: actionTypes.HIDE_LOADING, payload: false })
      }
    })
}

export function getProject(id) {
  return (dispatch) =>
    new Promise(async (resolve, reject) => {
      try {
        dispatch({ type: actionTypes.SHOW_LOADING, payload: true })
        let res = await axios.get(`/projects/${id}`)
        dispatch({ type: actionTypes.GET_PROJECT, payload: res.data })
        resolve(res.data)
      } catch (error) {
      } finally {
        dispatch({ type: actionTypes.HIDE_LOADING, payload: false })
      }
    })
}

export function getAllProjects() {
  return (dispatch) =>
    new Promise(async (resolve, reject) => {
      try {
        dispatch({ type: actionTypes.SHOW_LOADING, payload: true })
        let res = await axios.get(`/projects`)
        dispatch({ type: actionTypes.GET_ALL_PROJECTS, payload: res.data })
        resolve()
      } catch (error) {
      } finally {
        dispatch({ type: actionTypes.HIDE_LOADING, payload: false })
      }
    })
}

export function getProjectOfTheMonth() {
  return (dispatch) =>
    new Promise(async (resolve, reject) => {
      try {
        dispatch({ type: actionTypes.SHOW_LOADING, payload: true })
        let res = await axios.get(`/projects/project-of-the-month`)
        dispatch({
          type: actionTypes.GET_PROJECT_OF_THE_MONTH,
          payload: res.data,
        })
        resolve()
      } catch (error) {
      } finally {
        dispatch({ type: actionTypes.HIDE_LOADING, payload: false })
      }
    })
}

export function getMyProjects() {
  return async (dispatch) => {
    try {
      dispatch({ type: actionTypes.SHOW_LOADING, payload: true })
      let res = await axios.get(`/profile/my-projects`)
      dispatch({ type: actionTypes.GET_MY_PROJECTS, payload: res.data })
    } catch (error) {
    } finally {
      dispatch({ type: actionTypes.HIDE_LOADING, payload: false })
    }
  }
}

export function switchProject(id) {
  return (dispatch) =>
    new Promise(async (resolve, reject) => {
      try {
        dispatch({ type: actionTypes.SHOW_LOADING, payload: true })
        await axios.put(`/projects/switch/${id}`)
        resolve("success")
      } catch (error) {
      } finally {
        dispatch({ type: actionTypes.HIDE_LOADING, payload: false })
      }
    })
}
