import React, { useState } from "react"
import closeIcon from "@/assets/img/icon_close_orange.svg"
import { ToggleSuccessModal, ToggleOneTimeSupportModal } from "@/store/actions/modalActions"
import { useStripe } from "@stripe/react-stripe-js"
import { useDispatch, useSelector } from "react-redux"
import { getPersonalSubscription } from "../../../store/actions/subscriptionsActions"
import { useEffect } from "react"
import { getPaymentSources } from "../../../store/actions/payment"
import { getMySubscription } from "../../../store/actions/accounts"
import Autocomplete from "@mui/material/Autocomplete"
import { TextField, Box } from "@mui/material"
import { getCountries } from "@/store/actions/accounts"
import { ConnectingAirportsOutlined } from "@mui/icons-material"

export default function OneTimeSupportModal(props) {
  const dispatch = useDispatch()
  const [disabled, setDisabled] = useState(true)
  const [countries, setCountries] = useState([])
  const otp = useSelector((state) => state.otp)
  const nextStep = async () => {
    //next page support details
    console.log("next step")
    dispatch({ type: "otp/updateUserAction", payload: "support-detail" })
  }

  useEffect(() => {
    if (otp.country == null) setDisabled(true)
    if (otp.country?.countryId) setDisabled(false)
    if (otp.country?.countryCode == "US" && otp?.postalCode == "") setDisabled(true)
    if (otp.country?.countryCode == "CA" && otp?.postalCode == "") setDisabled(true)
  }, [otp.country?.countryId, otp?.postalCode])

  useEffect(() => {
    dispatch(getCountries()).then((res) => setCountries(res.data))
  }, [])

  return (
    <>
      <div className="w-screen h-screen justify-center flex overflow-x-hidden items-center overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-darker-grey/60">
        <div className={"max-w-[582px] w-screen bg-lighter-grey rounded py-4 relative overflow-y-auto sm:h-screen"}>
          <div className="flex justify-between">
            <div className="px-8 font-bold text-[30px]">Select Your Country</div>
            <div className=" grid place-items-center">
              <img src={closeIcon} alt="closeIcon" className=" cursor-pointer" onClick={() => dispatch({ type: "otp/closeModal" })} />
            </div>
          </div>
          <div className="flex flex-col  px-8">
            <p className="text-[16px] mt-[10px] relative leading-tight">Please choose your country of residence </p>
            <div className="flex gap-2 my-4">
              <Autocomplete
                fullWidth
                id="country-select-demo"
                options={countries}
                onChange={(event, newValue) => {
                  dispatch({ type: "otp/addCountry", payload: newValue })
                }}
                autoHighlight
                getOptionLabel={(option) => option.countryName}
                renderOption={(props, option) => (
                  <Box component="li" sx={{ "& > img": { mr: 2, flexShrink: 0 } }} {...props}>
                    <img loading="lazy" width="20" src={option.flagUrl} alt="" />
                    {option.countryName}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Choose a country"
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "new-password", // disable autocomplete and autofill
                    }}
                  />
                )}
              />
              <TextField
                onChange={(e) => dispatch({ type: "otp/addPostalCode", payload: e.target.value })}
                fullWidth
                id="filled-basic"
                variant="outlined"
                placeholder={`${
                  otp.country?.countryCode == "US"
                    ? "Zip Code (Required)"
                    : otp.country?.countryCode == "CA"
                    ? "Postal Code (Required)"
                    : "Postal Code (Optional)"
                }`}
              />
            </div>
          </div>
          <div className="flex justify-end">
            <button
              className={`uppercase max-w-[505px]  h-[54px] px-4 py-2 mx-2 ${
                disabled ? "bg-gray-200" : "bg-[#E9664E]"
              }  justify-center grid content-center rounded-[32px] cursor-pointer text-white font-semibold disabled:bg-dark-grey disabled:cursor-default`}
              onClick={disabled ? undefined : nextStep}>
              Continue
            </button>
          </div>
        </div>
      </div>
    </>
  )
}
