import * as actionTypes from "../action-types"
import axios from "@/utils/request.js"

export function getEvents() {
  return async (dispatch) => {
    try {
      dispatch({ type: actionTypes.SHOW_LOADING, payload: true })
      let res = await axios.get(`/events`)
      dispatch({ type: actionTypes.GET_ALL_EVENTS, payload: res.data })
    } catch (error) {
    } finally {
      dispatch({ type: actionTypes.HIDE_LOADING, payload: false })
    }
  }
}

export function getEvent(id) {
  return (dispatch) =>
    new Promise(async (resolve, reject) => {
      try {
        dispatch({ type: actionTypes.SHOW_LOADING, payload: true })
        let res = await axios.get(`/events/${id}`)
        dispatch({ type: actionTypes.GET_EVENT, payload: res.data })
        resolve()
      } catch (error) {
      } finally {
        dispatch({ type: actionTypes.HIDE_LOADING, payload: false })
      }
    })
}

export function getEventsByStrategies(data) {
  return async (dispatch) => {
    try {
      dispatch({ type: actionTypes.SHOW_LOADING, payload: true })
      let res = await axios.post(`/events/strategies`, data)
      dispatch({ type: actionTypes.GET_EVENTS_BY_STRATEGIES, payload: res.data })
    } catch (error) {
    } finally {
      dispatch({ type: actionTypes.HIDE_LOADING, payload: false })
    }
  }
}

export function getAttendedEvents() {
  return async (dispatch) => {
    try {
      dispatch({ type: actionTypes.SHOW_LOADING, payload: true })
      let res = await axios.get(`/profile/my-events/attended`)
      dispatch({ type: actionTypes.GET_MY_ATTENDED_EVENTS, payload: res.data })
    } catch (error) {
    } finally {
      dispatch({ type: actionTypes.HIDE_LOADING, payload: false })
    }
  }
}

export function getUpcomingEvents() {
  return async (dispatch) => {
    try {
      dispatch({ type: actionTypes.SHOW_LOADING, payload: true })
      let res = await axios.get(`/profile/my-events/upcoming`)
      dispatch({ type: actionTypes.GET_MY_UPCOMING_EVENTS, payload: res.data })
    } catch (error) {
    } finally {
      dispatch({ type: actionTypes.HIDE_LOADING, payload: false })
    }
  }
}

export function registerEvent(id) {
  return async (dispatch) => {
    return new Promise(async (resolve, reject) => {
      try {
        dispatch({ type: actionTypes.SHOW_LOADING, payload: true })
        let res = await axios.get(`/events/register/${id}`)
        resolve()
      } catch (error) {
        reject(error)
      } finally {
        dispatch({ type: actionTypes.HIDE_LOADING, payload: false })
      }
    })
  }
}

export function unRegisterEvent(id) {
  return async (dispatch) => {
    return new Promise(async (resolve, reject) => {
      try {
        dispatch({ type: actionTypes.SHOW_LOADING, payload: true })
        await axios.get(`/events/unregister/${id}`)
        resolve()
      } catch (error) {
      } finally {
        dispatch({ type: actionTypes.HIDE_LOADING, payload: false })
      }
    })
  }
}
