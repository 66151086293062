import * as actionTypes from "../action-types"
import axios from "@/utils/request.js"

export function getPersonalSubscription() {
  return (dispatch) =>
    new Promise(async (resolve, reject) => {
      try {
        dispatch({ type: actionTypes.SHOW_LOADING, payload: true })
        let res = await axios.get(`/subscription-types/personal`)
        dispatch({ type: actionTypes.GET_PERSONAL_SUBSCRIPTIONS, payload: res.data })
        resolve()
      } catch (error) {
      } finally {
        dispatch({ type: actionTypes.HIDE_LOADING, payload: false })
      }
    })
}

export function selectedSubscriptionAction(data) {
  return function (dispatch) {
    dispatch({ type: actionTypes.SELECTED_SUBSCRIPTION, payload: data })
  }
}

export function getBusinessSubscription(data) {
  return (dispatch) =>
    new Promise(async (resolve, reject) => {
      try {
        dispatch({ type: actionTypes.SHOW_LOADING, payload: true })
        let res = await axios.get(`/subscription-types/business/${data}`)
        dispatch({
          type: actionTypes.GET_BUSINESS_SUBSCRIPTIONS,
          payload: res.data,
        })
        resolve()
      } catch (error) {
      } finally {
        dispatch({ type: actionTypes.HIDE_LOADING, payload: false })
      }
    })
}

export function selectedBillFrequency(data) {
  return function (dispatch) {
    dispatch({ type: actionTypes.SELECTED_BILLING_FREQUENCY, payload: data })
  }
}

export function getSubscriptions() {
  return (dispatch) =>
    new Promise(async (resolve, reject) => {
      try {
        dispatch({ type: actionTypes.SHOW_LOADING, payload: true })
        let res = await axios.get(`/subscriptions`)
        dispatch({
          type: actionTypes.GET_SUBSCRIPTIONS,
          payload: res.data,
        })
        resolve()
      } catch (error) {
      } finally {
        dispatch({ type: actionTypes.HIDE_LOADING, payload: false })
      }
    })
}

export function getOrderHistory(PageNo = 0, PerPage = 4) {
  return (dispatch) =>
    new Promise(async (resolve, reject) => {
      try {
        dispatch({ type: actionTypes.SHOW_LOADING, payload: true })
        let res = await axios.get(`/profile/my-orderhistory?PageNo=${PageNo}&PerPage=${PerPage}`)
        dispatch({
          type: actionTypes.GET_ORDER_HISTORY,
          payload: {
            items: res.items,
            totalPages: res.totalPages,
            totalItems: res.totalItems,
          },
        })
        resolve()
      } catch (error) {
      } finally {
        dispatch({ type: actionTypes.HIDE_LOADING, payload: false })
      }
    })
}

export function oneTimeSupportPay(data) {
  return (dispatch) =>
    new Promise(async (resolve, reject) => {
      try {
        dispatch({ type: actionTypes.SHOW_LOADING, payload: true })
        let res = await axios.post(`/subscriptions/one-time-support-payment`, data)
        resolve(res.data)
      } catch (error) {
      } finally {
        dispatch({ type: actionTypes.HIDE_LOADING, payload: false })
      }
    })
}

export function oneTimeSupportPayGuest(data) {
  return (dispatch) =>
    new Promise(async (resolve, reject) => {
      try {
        dispatch({ type: actionTypes.SHOW_LOADING, payload: true })
        let res = await axios.post(`/subscriptions/one-time-support-payment/guest`, data)
        resolve(res.data)
      } catch (error) {
      } finally {
        dispatch({ type: actionTypes.HIDE_LOADING, payload: false })
      }
    })
}

export function cancelSubscription(subscriptionId, data) {
  return (dispatch) =>
    new Promise(async (resolve, reject) => {
      try {
        dispatch({ type: actionTypes.SHOW_LOADING, payload: true })
        let res = await axios.delete(`/subscriptions/${subscriptionId}`, { data })
        resolve()
      } catch (error) {
      } finally {
        dispatch({ type: actionTypes.HIDE_LOADING, payload: false })
      }
    })
}

export function changeSubscriptionProfile(subscriptionId, data) {
  return (dispatch) =>
    new Promise(async (resolve, reject) => {
      try {
        dispatch({ type: actionTypes.SHOW_LOADING, payload: true })
        let res = await axios.put(`/subscriptions/${subscriptionId}`, data)
        resolve()
      } catch (error) {
      } finally {
        dispatch({ type: actionTypes.HIDE_LOADING, payload: false })
      }
    })
}

export function getCancellationReasons() {
  return (dispatch) =>
    new Promise(async (resolve, reject) => {
      try {
        dispatch({ type: actionTypes.SHOW_LOADING, payload: true })
        let res = await axios.get(`/subscriptions/cancellation-reasons`)
        resolve(res.data)
      } catch (error) {
      } finally {
        dispatch({ type: actionTypes.HIDE_LOADING, payload: false })
      }
    })
}

export function reSubscribe() {
  return (dispatch) =>
    new Promise(async (resolve, reject) => {
      try {
        dispatch({ type: actionTypes.SHOW_LOADING, payload: true })
        await axios.put(`/subscriptions/re-subscribe`)
        resolve()
      } catch (error) {
      } finally {
        dispatch({ type: actionTypes.HIDE_LOADING, payload: false })
      }
    })
}

export function getNextBillingDate() {
  return (dispatch) =>
    new Promise(async (resolve, reject) => {
      try {
        dispatch({ type: actionTypes.SHOW_LOADING, payload: true })
        let res = await axios.get(`/subscriptions/get-next-billing-date`)
        resolve(res.data)
      } catch (error) {
      } finally {
        dispatch({ type: actionTypes.HIDE_LOADING, payload: false })
      }
    })
}

export function oneTimeSupportCalculate(data) {
  // {
  //   "supportDuration": "custom",
  //   "totalSupport": 300
  // }
  return (dispatch) =>
    new Promise(async (resolve, reject) => {
      try {
        dispatch({ type: actionTypes.SHOW_LOADING, payload: true })
        let res = await axios.post(`/subscriptions/one-time-support/calculate`, data)
        resolve(res.data?.totalCarbonOffset)
      } catch (error) {
      } finally {
        dispatch({ type: actionTypes.HIDE_LOADING, payload: false })
      }
    })
}

export function oneTimeSupportCalculateGuest(data) {
  // {
  //   "supportDuration": "custom",
  //   "totalSupport": 300
  // }
  return (dispatch) =>
    new Promise(async (resolve, reject) => {
      try {
        // dispatch({ type: actionTypes.SHOW_LOADING, payload: true })
        let res = await axios.post(`/subscriptions/one-time-support/calculate/guest`, data)
        resolve(res.data)
      } catch (error) {
      } finally {
        // dispatch({ type: actionTypes.HIDE_LOADING, payload: false })
      }
    })
}
