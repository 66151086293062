import React, { useMemo, useState } from "react"
import { useStripe, useElements, CardNumberElement, CardCvcElement, CardExpiryElement, PaymentElement } from "@stripe/react-stripe-js"
import InputField from "@/components/InputField"
import { toast } from "react-toastify"
import { ToggleSuccessModal, ToggleNewCreditCardModal, ToggleAccountOnHoldModal } from "@/store/actions/modalActions"
import { useDispatch, useSelector } from "react-redux"
import { getMySubscription } from "@/store/actions/accounts"
import { getStripeToken } from "@/store/actions/stripe"

import useResponsiveFontSize from "./useResponsiveFontSize"
import "./index.css"
import icon_question_grey from "@/assets/img/icon_question_grey.svg"
import icon_question_white from "@/assets/img/icon_question_white.svg"
import { createPopper } from "@popperjs/core"
import { addNewCreditCard, putSubscription, payOnHold } from "../../store/actions/payment"
import masterCardIcon from "@/assets/img/mc_symbol.svg"
import { ToggleFinishPaymentModal, TogglePayOnHoldModal } from "../../store/actions/modalActions"

const useOptions = () => {
  const fontSize = useResponsiveFontSize()
  const options = useMemo(
    () => ({
      showIcon: true,
      style: {
        base: {
          fontSize,
          color: "#424770",
          letterSpacing: "0.025em",
          fontFamily: "Source Code Pro, monospace",
          "::placeholder": {
            color: "#aab7c4",
          },
        },
        invalid: {
          color: "#9e2146",
        },
      },
    }),
    [fontSize]
  )

  return options
}

const StripeForm = ({
  inputStyle,
  showFirstName = true,
  showPromoCode = false,
  submitForm,
  nameTitle = "First name",
  namePlaceHolder = "What's Your Full Name?",
  buttonName = "Pay Now",
  buttonStyle = "flex justify-end",
  showCheckMark = true,
}) => {
  const stripe = useStripe()
  const elements = useElements()
  const options = useOptions()
  const [cardHolder, setCardHolder] = useState("")
  const [promoCode, setPromoCode] = useState("")
  const dispatch = useDispatch()

  const btnRef = React.createRef()
  const popoverRef = React.createRef()
  const [cardNumberElement, setCardNumberElement] = useState(false)
  const [cardExpiryElement, setCardExpiryElement] = useState(false)
  const [cardCvcElement, setCardCvcElement] = useState(false)

  const [popoverShow, setPopoverShow] = React.useState(false)
  const mySubscription = useSelector((state) => state.account.mySubscription)
  const [checked, setChecked] = useState(true)

  const openPopover = () => {
    createPopper(btnRef.current, popoverRef.current, {
      placement: "top-end",
      strategy: "fixed",
    })
    setPopoverShow(true)
  }
  const closePopover = () => {
    setPopoverShow(false)
  }

  const handleSubmit = async (event) => {
    event.preventDefault()
    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return
    }

    const payload = await stripe.createToken(elements.getElement(CardNumberElement))
    //   console.log(payload)
    // console.log(mySubscription)
    if (payload && submitForm) {
      if (buttonName === "Pay Now") {
        // sign up page says paynow
        submitForm({ stripeToken: payload.token?.id })
      } else {
        if (mySubscription?.status?.toLowerCase() === "onhold") {
          dispatch(payOnHold({ stripeToken: payload.token?.id, setToDefault: true }, mySubscription?.id)).then((res) => {
            if (res?.data?.paymentIntentSecret) {
              stripe
                .confirmCardPayment(res?.data?.paymentIntentSecret)
                .then((res) => {
                  if (res?.paymentIntent?.status == "succeeded") {
                    dispatch(ToggleFinishPaymentModal(false))
                    dispatch(ToggleAccountOnHoldModal(false))
                    dispatch(TogglePayOnHoldModal(false))
                  } else {
                    toast.error(res?.error?.message)
                  }
                })
                .finally(() => {
                  dispatch(ToggleNewCreditCardModal(false))
                  dispatch(ToggleFinishPaymentModal(false))
                  dispatch(getMySubscription())
                })
            } else {
              dispatch(ToggleFinishPaymentModal(false))
              dispatch(TogglePayOnHoldModal(false))
              dispatch(getMySubscription())
              dispatch(ToggleNewCreditCardModal(false))
            }
          })
        } else if (mySubscription?.status === "SubscriptionPendingAuthorization") {
          let data = { stripeToken: payload.token?.id, setToDefault: true }
          dispatch(putSubscription(mySubscription?.id, data)).then((res) => {
            if (res?.data?.paymentIntentSecret) {
              console.log(res?.data?.paymentIntentSecret)
              stripe
                .confirmCardPayment(res?.data?.paymentIntentSecret, {
                  // payment_method: { card: cardElement },
                })
                .then((res) => {
                  if (res?.paymentIntent?.status == "succeeded") {
                    dispatch(ToggleFinishPaymentModal(false))
                    dispatch(TogglePayOnHoldModal(false))
                  } else {
                    toast.error(res?.error?.message)
                  }
                })
                .finally(() => {
                  setTimeout(() => {
                    dispatch(getMySubscription())
                    console.log("Delayed for 2 second.")
                  }, "2000")
                  dispatch(ToggleNewCreditCardModal(false))
                  dispatch(ToggleFinishPaymentModal(false))
                })
            } else {
              dispatch(ToggleFinishPaymentModal(false))
              dispatch(TogglePayOnHoldModal(false))
            }
          })
        } else {
          dispatch(
            addNewCreditCard({
              stripeToken: payload.token?.id,
              setToDefault: checked,
            })
          ).then(() => {
            submitForm()
            dispatch(ToggleNewCreditCardModal(false))
          })
        }
      }
    }
  }
  // console.log(checked)

  return (
    <div className="">
      <form onSubmit={handleSubmit}>
        {/* <PaymentElement /> */}
        {showFirstName && (
          <>
            <p className="font-bold text-[16px] relative leading-tight ">{nameTitle}</p>

            <InputField
              placeholder={namePlaceHolder}
              value={cardHolder}
              onChange={(value) => {
                setCardHolder(value)
              }}
              inputStyle={inputStyle}
            />
          </>
        )}

        <label>
          Card number
          <CardNumberElement
            options={options}
            // onReady={() => {
            // 	console.log("CardNumberElement [ready]");
            // }}
            onChange={(event) => {
              if (event.complete && !event.error) {
                setCardNumberElement(true)
              } else {
                setCardNumberElement(false)
              }
            }}
            // onBlur={() => {
            // 	console.log("CardNumberElement [blur]");
            // }}
            // onFocus={() => {
            // 	console.log("CardNumberElement [focus]");
            // }}
          />
        </label>
        <div className="grid mobile:gap-4 mobile:grid-cols-2">
          <label className="">
            Expiration date
            <CardExpiryElement
              options={options}
              // onReady={() => {
              // 	console.log("CardNumberElement [ready]");
              // }}
              onChange={(event) => {
                if (event.complete && !event.error) {
                  setCardCvcElement(true)
                } else {
                  setCardCvcElement(false)
                }
              }}
              // onBlur={() => {
              // 	console.log("CardNumberElement [blur]");
              // }}
              // onFocus={() => {
              // 	console.log("CardNumberElement [focus]");
              // }}
            />
          </label>
          <label className="">
            <div className="flex items-center justify-between">
              Security code
              <div className="w-11 h-11 cursor-pointer -my-10 -mx-2">
                <img
                  ref={btnRef}
                  onClick={() => {
                    popoverShow ? closePopover() : openPopover()
                  }}
                  onMouseEnter={() => {
                    openPopover()
                  }}
                  onMouseLeave={() => {
                    closePopover()
                  }}
                  src={icon_question_grey}
                  alt="icon_question_grey"></img>
              </div>
              <div
                className={"bg-black rounded w-[260px] text-xs h-[57px] flex items-center text-center z-100 pr-3" + (popoverShow ? " " : " hidden ")}
                ref={popoverRef}>
                <img src={icon_question_white} alt="icon_question_white"></img>
                <div className="text-xs h-[57px] text-white grid items-center">Check for your CVC at the back of your credit or debit card</div>
              </div>
            </div>
            <CardCvcElement
              options={options}
              // onReady={() => {
              // 	console.log("CardNumberElement [ready]");
              // }}
              onChange={(event) => {
                if (event.complete && !event.error) {
                  setCardExpiryElement(true)
                } else {
                  setCardExpiryElement(false)
                }
              }}
              // onBlur={() => {
              // 	console.log("CardNumberElement [blur]");
              // }}
              // onFocus={() => {
              // 	console.log("CardNumberElement [focus]");
              // }}
            />
          </label>
        </div>
        {showPromoCode && (
          <InputField
            placeholder="Promo Code Goes Here"
            value={promoCode}
            onChange={(value) => {
              setPromoCode(value)
            }}
            inputStyle={inputStyle}
            postfix={true}
          />
        )}

        {mySubscription?.status?.toLowerCase() !== "onhold"
          ? showCheckMark && (
              <>
                <input
                  type="checkbox"
                  id="markAsDefault"
                  name="mark as default"
                  value={checked}
                  defaultChecked={checked}
                  onChange={() => {
                    setChecked(!checked)
                  }}
                />
                <label htmlFor="markAsDefault"> Make as Default</label>
              </>
            )
          : null}

        <div className={buttonStyle}>
          <button
            type="submit"
            disabled={!stripe || !cardNumberElement || !cardExpiryElement || !cardCvcElement || !cardHolder}
            data-bs-target="#exampleModal"
            data-bs-toggle="modal"
            className="uppercase w-[170px] h-[54px] relative mt-6 bg-orange_v2 justify-center grid content-center rounded-full cursor-pointer text-white font-semibold disabled:bg-dark-grey disabled:cursor-default">
            {buttonName}
          </button>
        </div>
      </form>
    </div>
  )
}

export default StripeForm
