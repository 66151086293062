import { Link, useLocation, useNavigate } from "react-router-dom"
import Icon_share_grey from "@/assets/img/icon_share_grey.svg"
import styles from "@/components/Card/projectDescription.module.css"
import React, { useEffect } from "react"
import { ToggleReplacementProjectModal } from "@/store/actions/modalActions"
import { useDispatch, useSelector } from "react-redux"
import { createPopper } from "@popperjs/core"
import icon_alert_yellow from "@/assets/img/icon_alert_yellow.svg"
import icon_link from "@/assets/img/icon_link.svg"
import handHeart from "@/assets/img/hand-heart.svg"
import { toast } from "react-toastify"
import { getProject } from "@/store/actions/projectsActions"
import { getDateWithoutPostfix } from "../../utils/helper"

import { Energy, Air, Earth, Water, Innovate } from "@/pages/Strategy/strategyConstant.js"

export default function ProjectCard({ id, width, project, height }) {
  const location = useLocation()
  const dispatch = useDispatch()
  const profile = useSelector((state) => state.account)
  const isEvent = location.pathname.includes("/events")
  // const isEvent = true;
  const navigate = useNavigate()
  const myProjects = location.pathname.includes("/projects/mine")
  const giftedProjects = location.pathname.includes("/projects/gifted")
  const closedProjects = location.pathname.includes("/projects/closed")

  const expiredBtnRef = React.createRef()
  const expiredPopoverRef = React.createRef()
  const [expiredPopoverShow, setExpiredPopoverShow] = React.useState(false)

  const shareBtnRef = React.createRef()
  const sharePopoverRef = React.createRef()
  const [sharePopoverShow, setSharePopoverShow] = React.useState(false)

  const openPopover = (btnRef, popoverRef, buttonName = "expired", placement = "bottom-end") => {
    createPopper(btnRef.current, popoverRef.current, {
      placement: placement,
      strategy: "fixed",
    })
    if (buttonName === "expired") {
      setExpiredPopoverShow(true)
    } else {
      setSharePopoverShow(true)
    }
  }
  const closePopover = (buttonName = "expired") => {
    if (buttonName === "expired") {
      setExpiredPopoverShow(false)
    } else {
      setSharePopoverShow(false)
    }
  }

  useEffect(() => {
    if (location.pathname.includes("notifications") && location.state?.notification?.data) {
      dispatch(getProject(location.state.notification.data?.projectId))
    }
  }, [location])

  return (
    <div className={"rounded cursor-pointer  relative border border-[#E0E5EB] " + (width ? width : "w-full ") + (myProjects || giftedProjects || closedProjects ? " h-[500px] " : height ? height : " h-[442px] ")}>
      <img src={project?.bannerURL} alt="project banner" className="h-1/4 w-full  object-cover object-center rounded-t" />
      <div className="flex absolute w-[98%] h-[24px] top-2 justify-end gap-2">
        {project?.isNewProject && (
          <div className="w-[42px] h-[24px] rounded-[4px] bg-[#212427] top-2 left-2 grid items-center">
            <div className="font-bold text-[10px] text-center text-white uppercase">NEW</div>
          </div>
        )}
        {project?.strategy && (
          <div className={"w-fit px-4 h-[24px] rounded-[4px] left-2 grid items-center " + (project?.strategy === Air && " bg-[#66B5AE] ") + (project?.strategy === Earth && " bg-[#D96E56] ") + (project?.strategy === Water && " bg-[#3574AE] ") + (project?.strategy === Energy && " bg-[#E9C54C] ") + (project?.strategy === Innovate && " bg-[#362C5E] ")}>
            <div className="text-[10px] text-center text-white uppercase">{project.strategy}</div>
          </div>
        )}
        {/* <div className="w-fit px-4 h-[24px] rounded-[4px] left-2 grid items-center bg-[#212427]">
          <div className="text-[10px] text-center text-white uppercase">{project?.isProfitProject ? "profit" : "non-profit"}</div>
        </div> */}
      </div>

      <div>
        <img src={project?.badgeIconURL} alt="project badge icon" className="h-[54px] w-[54px] object-cover object-center mt-[-20px] ml-[15px]"></img>
        {isEvent
          ? null
          : project?.strategy === profile?.profile?.currentlySupportedProject?.strategy && (
              <>
                <>
                  <div className="ml-[100px] mr-2 p-1 rounded -mt-[25px] bg-[#EEF1F3] text-[#788088] text-[10px] font-semibold uppercase">NEXT BADGE TO BE EARNED</div>
                </>
              </>
            )}
      </div>

      <div className="mx-4 mt-4">
        <div className="text-[#B5BBC0] text-[14px]">{project?.country}</div>
        <div className="flex items-center justify-between h-[44px]">
          <div className={"text-[20px] font-bold truncate " + (width ? width : "w-fit")}>{project?.projectName}</div>
          <div className="flex items-center w-fit min-w-fit justify-end">
            {(project?.isSupporting || project?.isCurrentlySupporting) && <img src={handHeart} alt="supporting" title="currently supporting" className="w-[44px] h-[44px]" />}
            {project?.isClosingSoon && !project?.isClosed && (
              <>
                <div
                  className="w-[44px] h-[44px] hover:bg-[#F89D14]/5 rounded-full"
                  ref={expiredBtnRef}
                  onClick={() => {
                    expiredPopoverShow ? closePopover() : openPopover(expiredBtnRef, expiredPopoverRef)
                  }}
                  onMouseEnter={() => {
                    openPopover(expiredBtnRef, expiredPopoverRef)
                  }}
                  onMouseLeave={() => {
                    closePopover()
                  }}>
                  <img src={icon_alert_yellow} alt="closed soon" className="w-[44px] h-[44px]"></img>
                </div>
                <div className={"bg-[#212427] rounded w-[256px] text-xs text-white h-[57px] grid items-center px-5 text-center z-50 " + (expiredPopoverShow ? " " : " hidden ")} ref={expiredPopoverRef}>
                  <div className="text-xs text-white h-[57px] grid items-center">{project?.isSupporting ? "This project you are currently supporting is closing within 30 days" : "This project is closing within 30 days"}</div>
                </div>
              </>
            )}
          </div>
        </div>
        <div className={`text-darker-grey/60 text-[14px]  mt-[8px] min-h-[84px] ${styles.project_description}`}>{location.pathname.includes("/notifications") ? project?.overview : project?.description}</div>
      </div>

      {/* tags will be different */}
      {project?.isProjectOfTheMonth && (
        <div className={"w-[182px] h-[22px] bg-[#CF6753] relative mt-2 " + styles.before_content}>
          <div className="h-full grid items-center text-[10px] px-1 tracking-[2px] text-white font-semibold uppercase">PROJECT OF THE MONTH</div>
        </div>
      )}

      {(myProjects || giftedProjects || closedProjects) && (
        <div className="mt-4 mx-4 ">
          <div className="w-full rounded h-fit grid items-center">
            <div className={"text-[14px] px-4 py-2 rounded " + (myProjects && project?.isCurrentlySupporting && " text-[#48C4A1] bg-currently-supporting ") + (myProjects && project?.isSwitching ? " text-[#F09D21] bg-[#FFEBD0] " : project?.previouslySupported && " text-[#3D79FF] bg-[#E2EBFF] ") + (giftedProjects && " text-[#F89D14] bg-[#FEF0DC] ") + (closedProjects && project?.endOfSupport && " text-[#FF7878] bg-[#FF7878]/20 ")}>
              {myProjects && project?.isCurrentlySupporting && (
                <span>
                  Supporting since <span className="font-bold ">{getDateWithoutPostfix(project?.supportingSince)}.&nbsp;</span>
                </span>
              )}
              {myProjects && project?.isSwitching ? <span>Switching to this project on {getDateWithoutPostfix(project?.switchingDate)}.&nbsp;</span> : project?.previouslySupported && <span>Supported this project in the past.&nbsp;</span>}
              {closedProjects && project?.endOfSupport && (
                <span>
                  This project closed in <span className="font-bold ">{getDateWithoutPostfix(project?.closedDate)}. &nbsp;</span>
                </span>
              )}
              {giftedProjects && (
                <span>
                  Supported this project on <span className="font-bold">{getDateWithoutPostfix(project?.supportedDate)} </span>
                  on behalf of <span className="font-bold">{project?.giftedTo}. &nbsp;</span>
                </span>
              )}
            </div>
          </div>
          {/* <div className="flex mt-4">
						<div className="flex items-center whitespace-pre text-[#FF7878] text-[14px]">
							This project will close in
							<div className="font-bold "> 30 days</div>
						</div>
					</div> */}
        </div>
      )}

      <div className="bottom-[23px] flex justify-between w-full absolute">
        {location?.pathname !== "/projects" && location?.pathname !== "/projects/mine" && location?.pathname !== "/projects/gifted" && location?.pathname !== "/projects/closed" ? (
          // this is to make something on the left,
          // then justify-between won't need to change for the button on the right
          <div className="w-[48px] h-[48px]"></div>
        ) : (
          <>
            <img
              ref={shareBtnRef}
              onClick={() => {
                sharePopoverShow ? closePopover("share") : openPopover(shareBtnRef, sharePopoverRef, "share", "top-start")
              }}
              src={Icon_share_grey}
              alt="project_img_example"
              className="object-cover object-center rounded-t w-[48px] h-[48px] cursor-pointer"
            />
            <div
              ref={sharePopoverRef}
              onClick={() => {
                navigator.clipboard.writeText(`${window.location.href}/${project?.id}`)
                closePopover("share")
                toast.success("Copied")
              }}
              className={"w-40 h-12 flex bg-white shadow-share items-center gap-4 rounded cursor-pointer" + (sharePopoverShow ? " " : " hidden ")}>
              <img src={icon_link} alt="icon_link" />
              <p className="text-sm">Copy Link</p>
            </div>
          </>
        )}
        {location?.pathname !== "/events/attended" && (
          <div className="mr-4 flex gap-2 ">
            <button onClick={() => navigate(`/one-time-guest/${project.id}`)} className={" py-2 px-8 uppercase rounded-full bg-[#E9664E] cursor-pointer font-semibold" + (location.pathname === "/projects" || isEvent ? "  text-white " : "  text-white ")}>
              {isEvent ? "View Event" : "View Details"}
            </button>
          </div>
        )}
      </div>
    </div>
  )
}
