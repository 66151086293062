import React, { useState, useRef, useEffect, memo } from "react"
import { connect } from "react-redux"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { TextField } from "@mui/material"
import * as action_types from "@/store/action-types"
import * as actions from "@/store/actions/accounts"
import Box from "@mui/material/Box"
import Autocomplete from "@mui/material/Autocomplete"
import { toast } from "react-toastify"

const SelectYourCountry = (props) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [countries, setCountries] = useState([])

  const loadData = async () => {
    const result = await props.getCountries()
    setCountries(result?.data)
    console.log(result.data)
  }

  const validatePostalCode = async () => {
    console.log(props.country)
    if (!props.country?.countryId) {
      toast.error("Please select a country")
      return
    } else if (props.country.countryCode == "US" && !props.country.postalCode) {
      toast.error("Please input your postal code")
      return
    } else if (props.country.countryCode == "CA" && !props.country.postalCode) {
      toast.error("Please input your postal code")
      return
    }
    props
      .validatePostalCode({
        countryCode: props.country.countryCode,
        postalCode: props.country.postalCode,
      })
      .then((res) => {
        if (res?.data?.isValid) navigate("/sign-up/SettingYourProfile")
        else toast.error(res?.data?.message)
      })
  }

  useEffect(() => {
    loadData()
  }, [])

  return (
    <div className="flex flex-col ">
      <p className="font-bold text-[36px] mt-[30px] relative leading-tight">Select Your Country</p>
      <p className="text-[16px] mt-[10px] relative leading-tight">Please set you country to define your taxes</p>
      <div className="flex gap-2 my-4">
        <Autocomplete
          fullWidth
          id="country-select-demo"
          options={countries}
          onChange={(event, newValue) => {
            dispatch({
              type: action_types.SAVE_SIGNUP_COUNTRY_POSTALCODE,
              payload: {
                countryId: newValue.countryId,
                countryName: newValue.countryName,
                countryCode: newValue.countryCode,
              },
            })
          }}
          autoHighlight
          getOptionLabel={(option) => option.countryName}
          renderOption={(props, option) => (
            <Box component="li" sx={{ "& > img": { mr: 2, flexShrink: 0 } }} {...props}>
              <img loading="lazy" width="20" src={option.flagUrl} alt="" />
              {option.countryName}
            </Box>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder="Choose a country"
              inputProps={{
                ...params.inputProps,
                autoComplete: "new-password", // disable autocomplete and autofill
              }}
            />
          )}
        />
        {(props.country.countryCode == "US" || props.country.countryCode == "CA") && (
          <TextField
            onChange={(e) => {
              dispatch({
                type: action_types.SAVE_SIGNUP_COUNTRY_POSTALCODE,
                payload: { postalCode: e.target.value },
              })
            }}
            fullWidth
            id="filled-basic"
            variant="outlined"
            placeholder={`${
              props.country.countryCode == "US"
                ? "Zip Code (Required)"
                : props.country.countryCode == "CA"
                ? "Postal Code (Required)"
                : "Postal Code (Optional)"
            }`}
          />
        )}
      </div>
      <button
        className="uppercase max-w-[505px] laptop:w-[505px] h-[54px] relative mt-10 bg-orange_v2 justify-center grid content-center rounded-[32px] cursor-pointer text-white font-semibold disabled:bg-dark-grey disabled:cursor-default"
        onClick={validatePostalCode}>
        Continue
      </button>
    </div>
  )
}

export default connect((state) => state.account, actions)(SelectYourCountry)
