import * as actionTypes from "../action-types"
import axios from "@/utils/request.js"

export function getCarTypes() {
  return async (dispatch) =>
    new Promise(async (resolve, reject) => {
      try {
        dispatch({ type: actionTypes.SHOW_LOADING, payload: true })
        let res = await axios.get(`/banff/vehicles`)
        dispatch({ type: actionTypes.GET_EVENT, payload: res.data })
        resolve(res?.data)
      } catch (error) {
      } finally {
        dispatch({ type: actionTypes.HIDE_LOADING, payload: false })
      }
    })
}

export function banffMarathonCalculator(data) {
  return async (dispatch) =>
    new Promise(async (resolve, reject) => {
      try {
        dispatch({ type: actionTypes.SHOW_LOADING, payload: true })
        let res = await axios.post(`/banff/user`, data)
        resolve(res?.data)
        dispatch({ type: actionTypes.HIDE_LOADING, payload: res.data })
      } catch (error) {
      } finally {
        dispatch({ type: actionTypes.HIDE_LOADING, payload: false })
      }
    })
}
