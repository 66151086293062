import React from "react"
import { useDispatch } from "react-redux"
import { ToggleSubmitFeedbackModal } from "../../store/actions/modalActions"

export default function SubmitFeedbackModal({}) {
  const dispatch = useDispatch()

  return (
    <>
      <div className="justify-centeritems-center flex overflow-x-hidden items-center overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative w-auto my-6 mx-auto max-w-3xl">
          {/*content*/}
          <div className="border-0 rounded-lg rounded shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            {/*body*/}
            <div className="relative bg-lighter-grey max-w-[872px] h-fit rounded py-4">
              <div className="flex text-[30px] justify-start mx-7">
                <div className="font-bold">You have successfully submitted the feedback. Thank you</div>
                {/* <div className="font-bold">Thank you</div> */}
              </div>
              <div className="flex justify-center mr-7 gap-2">
                <div className="grid justify-center mt-[32px]">
                  <div
                    onClick={() => {
                      dispatch(ToggleSubmitFeedbackModal())
                    }}
                    className="bg-[#E9664E] text-white font-semibold text-base py-2 px-8  rounded-full grid justify-center items-center cursor-pointer">
                    Ok
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  )
}
