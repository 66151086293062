import * as actionTypes from "../action-types"

export function ToggleSuccessModal(text = null, url = null, buttonText = null) {
  return function (dispatch) {
    dispatch({
      type: actionTypes.TOGGLE_SUCCESS_MODAL,
      payload: { text, url, buttonText },
    })
  }
}

export function ToggleReplacementProjectModal() {
  return function (dispatch) {
    dispatch({ type: actionTypes.TOGGLE_REPLACEMENT_PROJECT_MODAL })
  }
}
export function ToggleNewCreditCardModal(data) {
  console.log(data)
  return function (dispatch) {
    dispatch({ type: actionTypes.TOGGLE_NEW_CREDIT_CARD_MODAL, payload: data })
  }
}
export function ToggleOneTimeSupportModal() {
  return function (dispatch) {
    dispatch({ type: actionTypes.TOGGLE_ONE_TIME_SUPPORT_MODAL })
  }
}
export function ToggleEventCodeModal() {
  return function (dispatch) {
    dispatch({ type: actionTypes.TOGGLE_EVENT_CODE_MODAL })
  }
}

export function ToggleCancelSubscriptionModal() {
  return function (dispatch) {
    dispatch({ type: actionTypes.TOGGLE_CANCEL_SUBSCRIPTION_MODAL })
  }
}

export function ToggleRemovePaymentMothodModal(data = null) {
  return function (dispatch) {
    dispatch({
      type: actionTypes.TOGGLE_REMOVE_PAYMENT_METHOD_MODAL,
      payload: data,
    })
  }
}

export function ToggleCancelSubscriptionFeedbackModal() {
  return function (dispatch) {
    dispatch({ type: actionTypes.TOGGLE_CANCEL_SUBSCRIPTION_FEEDBACK_MODAL })
  }
}

export function ToggleMarkAllAsReadModal() {
  return function (dispatch) {
    dispatch({ type: actionTypes.TOGGLE_MARK_ALL_AS_READ })
  }
}

export function ToggleDeleteAllNotificationsModal() {
  return function (dispatch) {
    dispatch({ type: actionTypes.TOGGLE_DELETE_ALL_NOTIFICATIONS })
  }
}

export function ToggleRemoveNotificationModal() {
  return function (dispatch) {
    dispatch({ type: actionTypes.TOGGLE_REMOVE_NOTIFICATION })
  }
}
export function ToggleShowNotificationDetailModal() {
  return function (dispatch) {
    dispatch({ type: actionTypes.TOGGLE_SHOW_NOTIFICATION_DETAIL })
  }
}

export function ToggleDeleteAccountModal() {
  return function (dispatch) {
    dispatch({ type: actionTypes.TOGGLE_DELETE_ACCOUNT })
  }
}

export function ToggleCountrySelection(data) {
  return function (dispatch) {
    dispatch({ type: actionTypes.TOGGLE_COUNTRY_SELECTION, payload: data })
  }
}

export function ToggleAccountOnHoldModal(data) {
  return function (dispatch) {
    dispatch({ type: actionTypes.TOGGLE_ACCOUNT_ON_HOLD, payload: data })
  }
}

export function TogglePayOnHoldModal(data) {
  return function (dispatch) {
    dispatch({ type: actionTypes.TOGGLE_PAY_ON_HOLD, payload: data })
  }
}
export function ToggleFinishPaymentModal(data) {
  return function (dispatch) {
    dispatch({ type: actionTypes.TOGGLE_FINISH_PAYMENT, payload: data })
  }
}

export function ToggleReSubscriptionModal() {
  return function (dispatch) {
    dispatch({ type: actionTypes.TOGGLE_RE_SUBSCRIBE })
  }
}

export function ToggleCancelEventModal() {
  return function (dispatch) {
    dispatch({ type: actionTypes.TOGGLE_CANCEL_EVENT })
  }
}

export function ToggleSubmitFeedbackModal() {
  return function (dispatch) {
    dispatch({ type: actionTypes.TOGGLE_SUBMIT_FEEDBACK })
  }
}
