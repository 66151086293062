import * as actionTypes from "../action-types"
import { combineReducers } from "redux"
import loading from "./loading"
import modal from "./modal"
import account from "./account"
import projects from "./projects"
import subscriptions from "./subscriptions"
import events from "./events"
import payment from "./payment"
import settings from "./settings"
import notifications from "./notifications"
import otp from "./otpGuest"
let reducers = { loading, modal, account, projects, subscriptions, events, payment, settings, notifications, otp }
let combinedReducer = combineReducers(reducers)
const rootReducer = (state, action) => {
  if (action.type === actionTypes.LOGOUT) {
    return combinedReducer(undefined, action)
  }

  return combinedReducer(state, action)
}
export default rootReducer
