import React, { useEffect, useState } from "react"
import closeIcon from "@/assets/img/icon_close_orange.svg"
import StripeForm from "@/components/Stripe"
import { Elements, useStripe } from "@stripe/react-stripe-js"
import icon_close_green from "@/assets/svg/icon_close_orange.svg"
import { ToggleNewCreditCardModal } from "@/store/actions/modalActions"
import { useDispatch, useSelector } from "react-redux"
import { getPaymentSources, getPayOnHoldSecret } from "../../../store/actions/payment"
import { filterProjects } from "../../../store/actions/projectsActions"
import { TogglePayOnHoldModal } from "@/store/actions/modalActions"
import { ToggleFinishPaymentModal } from "../../../store/actions/modalActions"
import { getMySubscription } from "../../../store/actions/accounts"

export default function Index({}) {
  const dispatch = useDispatch()
  const stripe = useStripe()
  const stripePromise = useSelector((state) => state.account.stripePromise)
  const strategies = useSelector((state) => state.projects.selectedStrategies)
  const mySubscription = useSelector((state) => state.account.mySubscription)
  const submitForm = () => {
    //
  }

  return (
    <div className="w-screen h-screen justify-center flex overflow-x-hidden items-center overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-darker-grey/60">
      <div className="flex flex-col bg-lighter-grey w-[460px] h-fit px-8 rounded pb-8">
        <p className="font-bold text-[36px] mt-[30px] relative leading-tight">
          <div className="flex justify-between">
            Subscription Payment
            <img
              src={closeIcon}
              alt="closeIcon"
              className="cursor-pointer"
              onClick={() => {
                dispatch(ToggleFinishPaymentModal(false))
                dispatch(getMySubscription())
              }}
            />
          </div>
        </p>
        <p className="text-[16px] mt-[10px] relative leading-tight mb-4">Please fill your payment information</p>
        <div className="mt-4 bg-white p-3">
          <Elements stripe={stripePromise}>
            <StripeForm
              showPromoCode={false}
              submitForm={submitForm}
              nameTitle="Cardholder"
              namePlaceHolder="What's the cardholder's name?"
              buttonName="Submit"
              buttonStyle="flex justify-end  "
              inputStyle="w-full bg-grey h-[40px] mt-2 mb-4 relative pl-[10px] rounded placeholder:text-[14px] focus-visible:outline focus-visible:outline-orange_v2"
            />
          </Elements>
        </div>
      </div>
    </div>
  )
}
