import React, { useState, useRef, useEffect, memo } from "react"
import background from "@/assets/img/BM-background.jpg"
import Joi from "joi"
import { connect } from "react-redux"
import { produce } from "immer"
import { useNavigate } from "react-router-dom"
import InputField from "@/components/InputField"
// import Autocomplete from "@mui/material/Autocomplete"
import Autocomplete from "react-google-autocomplete"
import { TextField } from "@mui/material"
import Button from "@mui/material/Button"
import Checkbox from "@mui/material/Checkbox"
import FormGroup from "@mui/material/FormGroup"
import FormControlLabel from "@mui/material/FormControlLabel"
import FormControl from "@mui/material/FormControl"
import FormLabel from "@mui/material/FormLabel"
import InputLabel from "@mui/material/InputLabel"
import MenuItem from "@mui/material/MenuItem"
import Select from "@mui/material/Select"
import ButtonGroup from "@mui/material/ButtonGroup"
import AddIcon from "@mui/icons-material/Add"
import RemoveIcon from "@mui/icons-material/Remove"

const initialState = {}
const schema = Joi.object({})
const index = (props) => {
  const navigate = useNavigate()
  const [data, setData] = useState(initialState)
  const loadData = async () => {}
  useEffect(() => {
    loadData()
    return () => setData(initialState)
  }, [])
  return (
    <div
      className="flex w-full h-screen "
      style={{
        backgroundImage: `url(${background})`,
        backgroundPosition: "center",
      }}>
      <div className="m-auto laptop:w-1/3 bg-white rounded-8">
        <div className="grid place-items-center w-full p-4">
          <div>
            <p className=" text-sm text-[#EA664E] laptop:text-base flex-wrap">Banff Marathon</p>
            <p className="font-bold  md:pr-28 desktop:pr-40 text-xl laptop:text-4xl  relative leading-tight">Carbon Emissions Calculator</p>
            <p className=" text-sm  laptop:text-base flex-wrap">
              Calculate your carbon emissions to know your environmental impact and take meaningful actions towards sustainability.
            </p>
            <hr className="my-4"></hr>
            <p className="font-bold  md:pr-28 desktop:pr-40 text-xl laptop:text-4xl  relative leading-tight">How it works</p>
            <p className=" text-sm  laptop:text-base flex-wrap">
              Simply input a few variables into our driving emissions calculator. From there we'll show you how much carbon was generated from you driving to
              the race and we'll offset your emissions from your trip free of charge!
            </p>
          </div>
        </div>
        <div className="flex justify-end m-2">
          <div className="space-x-2">
            <Button
              onClick={() => navigate("/banff-marathon-calculator")}
              sx={{
                borderRadius: 999,
                textTransform: "none",
                fontWeight: "bold",
              }}
              autoCapitalize="true"
              variant="contained">
              continue
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default connect(null, null)(index)
