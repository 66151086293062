import React, { useEffect, useState } from "react"
import check_mark from "@/assets/img/Check_Orange.svg"
import Select from "../../Select"
import { ToggleNewCreditCardModal } from "@/store/actions/modalActions"
import { useDispatch, useSelector } from "react-redux"
import "./index.css"
import { oneTimeSupportCalculate } from "../../../store/actions/subscriptionsActions"
import { getInvoicesEstimate } from "../../../store/actions/payment"

export default function Page2({ selected, setSelected, customAmount, setCustomAmount, selectedOption, setSelectedOption, paymentInfo, setPaymentInfo }) {
  const dispatch = useDispatch()
  const [carbonOffset, setCarbonOffset] = useState(0)
  const [annualCarbonOffset, setAnnualCarbonOffset] = useState(0)
  const cards = useSelector((state) => state.payment.cards)
  const profile = useSelector((state) => state.account.profile)
  const mySubscription = useSelector((state) => state.account.mySubscription)
  const personal = useSelector((state) => state.subscriptions.personal)

  const selectedProfile = personal.find((subscription) => subscription.id === mySubscription?.subscriptionTypeId)
  // const [paymentInfo, setPaymentInfo] = useState();
  const project = useSelector((state) => state.projects.project)

  useEffect(() => {
    if (profile?.userType === "Business") {
      setSelectedOption("custom")
    }
  }, [profile])

  const getEstimate = () => {
    dispatch(
      getInvoicesEstimate({
        projectId: project?.id,
        subscriptionTypeId: mySubscription?.subscriptionTypeId,
        employees: 0,
        billingFrequency: "annually",
        promoCode: "",
        userType: profile?.userType === "Business" ? "business" : "personal",
        customAmount: selectedOption === "annual" ? 0 : customAmount,
      })
    )
      .then((res) => {
        setPaymentInfo(res)
      })
      .catch((e) => {
        alert("not valid email")
        return
      })
  }

  useEffect(() => {
    if (selectedOption === "annual") {
      // setCustomAmount(0);
      if (annualCarbonOffset === 0) {
        dispatch(
          oneTimeSupportCalculate({
            supportDuration: selectedOption,
            totalSupport: customAmount,
          })
        ).then((res) => {
          setAnnualCarbonOffset(res)
          setCarbonOffset(res)
        })
      } else {
        setCarbonOffset(annualCarbonOffset)
      }
      if (profile?.userType !== "Business") {
        getEstimate()
      }
    } else {
      // setCarbonOffset(0);
      if (customAmount > 0) {
        const timeoutId = setTimeout(() => {
          dispatch(
            oneTimeSupportCalculate({
              supportDuration: selectedOption,
              totalSupport: customAmount,
            })
          ).then((res) => {
            setCarbonOffset(res)
          })
          getEstimate()
        }, 1000)
        return () => {
          clearTimeout(timeoutId)
        }
      } else {
        setCarbonOffset(0)
        setPaymentInfo()
      }
    }
    // "projectId": "c6ca2cf9-2fa4-42bb-9dad-9e6fcdf07ddc",
    // "subscriptionTypeId": "5bb839a7-ce2c-48ca-a09f-62fe70ec5123",
    // "employees": 0,
    // "billingFrequency": "annually",
    // "promoCode": "",
    // "userType": "personal",
    // "customAmount": 0
  }, [selectedOption, customAmount])

  // useEffect(() => {
  // 	if (selectedOption !== "annual") {
  // 	}
  // }, [customAmount]);

  return (
    <div className="px-8 pt-2">
      <div className="flex gap-4 sm:flex-col">
        <div className="w-full">
          {profile?.userType !== "Business" && selectedOption !== "custom" && (
            <>
              <div className="text-[14px] text-darker-grey/30 font-semibold uppercase">Your current profile:</div>
              <div className="border-2 border-gray-500 bg-white w-full h-[112px] rounded-[20px] mt-2 grid items-center grid-rows-3 px-4 py-4">
                <div className="flex justify-between">
                  <div>
                    <div className="font-bold text-[16px]">{selectedProfile?.name.split(" ")[0]}</div>
                    <div className="text-[14px] text-darker-grey/50">{selectedProfile?.name.split(" ")[1]}</div>
                  </div>
                  <div>
                    <div className="font-bold text-[16px]">${Number(selectedProfile?.yearlyFee).toFixed(2)}</div>
                    <div className="text-[14px] text-darker-grey/50 text-right">year</div>
                  </div>
                </div>
                <div className="border-dashed border-b w-full border-[#E0E5EB] pt-3"></div>
                <div className="text-[14px] text-darker-grey/50 flex">
                  <img src={check_mark} alt="check_mark" className="w-[10px] mr-1"></img>
                  Carbon Offset {selectedProfile?.carbonOffset}kgs of CO2 per year!
                </div>
              </div>
            </>
          )}
          <div className={selectedOption === "annual" ? "mt-4" : ""}>
            <div className="text-[14px] text-darker-grey/30 font-semibold uppercase ">Amount of support:</div>
            <div
              className={
                "bg-white w-full rounded-[20px] mt-2 px-4 grid pt-4 pb-2 h-fit border-2 border-gray-500"
                // +
                // (selectedOption === "annual" ? " h-[140px] " : " h-[178px] ")
              }>
              <div className="flex justify-between h-[51px] gap-4">
                {profile?.userType !== "Business" && (
                  <div
                    onClick={() => {
                      if (selectedOption !== "annual") {
                        setSelectedOption("annual")
                      }
                    }}
                    className={
                      "uppercase w-1/2 h-[51px] justify-center grid content-center rounded-[40px] cursor-pointer font-semibold" +
                      (selectedOption === "annual" ? " bg-gray-900 text-white " : " bg-gray-200 text-black ")
                    }>
                    1 YEAR
                  </div>
                )}
                <div
                  onClick={() => {
                    if (selectedOption !== "custom") {
                      setSelectedOption("custom")
                    }
                  }}
                  className={
                    "uppercase h-[51px] justify-center grid content-center rounded-[40px] cursor-pointer font-semibold" +
                    (profile?.userType === "Business" ? " w-full " : "  w-1/2 ") +
                    (selectedOption === "custom" ? " bg-gray-900 text-white " : " bg-gray-200 text-black ")
                  }>
                  Custom
                </div>
              </div>

              <>
                {selectedOption !== "annual" && (
                  <div className="input-box mt-2">
                    <span className="prefix">$</span>
                    <input
                      type="number"
                      // placeholder=""
                      onChange={(e) => {
                        setCustomAmount(e.target.value)
                      }}
                      onFocus={() => {}}
                      value={Number(customAmount).toString()}
                    />
                  </div>
                )}
                {selectedOption !== "annual" && <div className="text-[12px] mt-2">$5.5 Minimum - $500 Maximum</div>}
                <div className="border-dashed border-b border-[#E0E5EB] my-2"></div>
                <div className="flex justify-between text-[12px] mt-2">
                  <p className="text-[12px]">Your support total:</p>
                  <div className="font-bold text-[12px]">${Number(paymentInfo?.transactionCost ? paymentInfo?.transactionCost : 0).toFixed(2)}</div>
                </div>
                <div className="flex justify-between text-[12px] mt-2">
                  <p className="text-[12px]">Service Fee:</p>
                  <div className="font-bold text-[12px]">${Number(paymentInfo?.stripeFee ? paymentInfo?.stripeFee : 0).toFixed(2)}</div>
                </div>
                <div className="flex justify-between text-[12px] mt-2">
                  <p className="text-[12px]">Tax</p>
                  <div className="font-bold text-[12px]">${Number(paymentInfo?.tax ? paymentInfo?.tax : 0).toFixed(2)}</div>
                </div>
                <div className="flex justify-between text-[12px] mt-2">
                  <p className="text-[12px]">Total Cost:</p>
                  <div className="font-bold text-[12px]">${Number(paymentInfo?.total ? paymentInfo?.total : 0).toFixed(2)}</div>
                </div>
                {/* <div className="flex justify-between text-[12px] mt-2">
									<p className="text-[12px]">Carbon offset:</p>
									<div className="font-bold text-[12px]">
										{Number(carbonOffset).toFixed(2)}kg
									</div>
								</div> */}
              </>
            </div>
          </div>
        </div>

        <div className="w-full">
          <div className="text-[14px] text-darker-grey/30 font-semibold uppercase">Payment</div>
          <div className="border-2 border-gray-500 bg-white w-full h-[167px] rounded-[20px] grid items-center px-4 mt-2">
            <div className="font-bold text-base ">Choose a credit card:</div>
            <Select className="bg-mist-grey w-full h-[56px]  " options={cards} setSelected={setSelected} selected={selected} />

            <div className="text-base text-orange_v2 font-semibold mt-2 cursor-pointer" onClick={() => dispatch(ToggleNewCreditCardModal())}>
              Add New Card
            </div>
          </div>
        </div>
      </div>
      <div className="grid grid-cols-2 gap-6 "></div>
    </div>
  )
}
