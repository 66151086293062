import React from "react"
import { useDispatch, useSelector } from "react-redux"
import icon_close_green from "@/assets/img/icon_close_green.svg"
import { ToggleCancelEventModal } from "../../store/actions/modalActions"
import { getEvent, getEventsByStrategies, unRegisterEvent } from "../../store/actions/events"
import { toast } from "react-toastify"

export default function CancelEventModal({}) {
  const dispatch = useDispatch()
  const event = useSelector((state) => state.events.event)
  const selectedStrategies = useSelector((state) => state.projects.selectedStrategies)

  return (
    <>
      <div className="justify-centeritems-center flex overflow-x-hidden items-center overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative w-auto my-6 mx-auto max-w-3xl">
          {/*content*/}
          <div className="border-0 rounded-lg rounded shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            {/*body*/}
            <div className="relative bg-lighter-grey max-w-[872px] h-fit rounded py-8">
              <div className="font-bold text-[30px] mx-8 ">Are you sure you want to cancel you registration for this Event?</div>
              <div className="flex justify-end gap-2 mx-8">
                <div className="grid justify-center mt-[32px]">
                  <div
                    onClick={() => {
                      dispatch(ToggleCancelEventModal())
                    }}
                    className="font-semibold border text-base laptop:w-[232px] px-10 h-[44px] rounded-full uppercase grid justify-center items-center cursor-pointer">
                    Not Now
                  </div>
                </div>
                <div className="grid justify-center mt-[32px]">
                  <div
                    onClick={() => {
                      dispatch(unRegisterEvent(event.id)).then(() => {
                        dispatch(ToggleCancelEventModal())
                        dispatch(getEvent(event.id))
                        toast.success("Cancelled the event!")
                        dispatch(getEventsByStrategies({ strategies: selectedStrategies }))
                      })
                    }}
                    className="bg-[#EC3254] text-white font-semibold text-base px-10 laptop:w-[232px] h-[44px] rounded-full uppercase grid justify-center items-center cursor-pointer">
                    Cancel
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  )
}
