import React from "react"
import closeIcon from "@/assets/img/icon_close_orange.svg"
import { useDispatch, useSelector } from "react-redux"
import { ToggleRemovePaymentMothodModal } from "@/store/actions/modalActions"
import { getPaymentSources, removePaymentMethod } from "../../store/actions/payment"

export default function RemovePaymentMethodModal() {
  const dispatch = useDispatch()
  const selectedCard = useSelector((state) => state.modal.selectedCard)

  return (
    <>
      <div className="justify-centeritems-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative w-auto my-6 mx-auto">
          {/*content*/}
          <div className="border-0 rounded-lg rounded shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            <div className="flex justify-end">
              <img src={closeIcon} alt="closeIcon" className="cursor-pointer mt-2 mr-2" onClick={() => dispatch(ToggleRemovePaymentMothodModal())} />
            </div>
            {/*body*/}
            <div className="relative max-w-[872px] h-fit rounded px-8 pb-8">
              <div className="font-bold text-[30px] max-w-[740px] break-words">Are you sure you want to remove this payment method?</div>
              <div className="grid justify-end mt-8">
                <div
                  className="w-[180px] h-[54px] cursor-pointer bg-[#EC3254] text-white grid justify-center items-center rounded"
                  onClick={() => {
                    dispatch(removePaymentMethod(selectedCard)).then(() => {
                      dispatch(getPaymentSources())
                      dispatch(ToggleRemovePaymentMothodModal())
                    })
                  }}>
                  Remove Card
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  )
}
