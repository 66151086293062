// useful functions which are not components
import moment from "moment"
import { toastError } from "../store/actions/axiosErrorActions"
import store from "../store/index.js"
// import moment from "moment";

export function isValidEmail(email) {
  return RegExp(
    '^(([^<>()[\\]\\\\.,;:\\s@\\"]+(\\.[^<>()[\\]\\\\.,;:\\s@\\"]+)*)|' +
      '(\\".+\\"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])' +
      "|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$"
  ).test(email)
}

export function capitalizeCreditCard(word) {
  if (word === "mastercard") {
    return "MasterCard"
  }

  return word.charAt(0).toUpperCase() + word.slice(1)
}

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export function validatePassword(value, confirmation) {
  const checkLength = value?.length >= 8 && value?.length <= 255
  const checkLowerCase = value ? /[a-z|ç|ş|ö|ü|ı|ğ]/u.test(value) : false
  const checkUpperCase = /[A-Z|Ç|Ş|Ö|Ü|İ|Ğ]/u.test(value)
  const checkNumberAndSymbols = /[0-9]/.test(value) && /[;,.!@#$%^&*()]/.test(value)
  // const checkRepeat = /(.)\1{1}/.test(value);
  const checkMatch = value ? value === confirmation : false

  return {
    checkLength,
    checkLowerCase,
    checkUpperCase,
    checkNumberAndSymbols,
    checkMatch,
  }
}

export function formatPhone(phone) {
  if (!phone) return ""

  var ex
  var ph = phone
  var exidx = ph.search(/[e|x]/)

  if (exidx >= 0) {
    ex = ph.substring(exidx).replace(/\D/g, "")
    ph = ph.substring(0, exidx)
  }

  ph = ph.replace(/\D/g, "")

  if (ph.indexOf("1") === 0) ph = ph.substring(1)

  if (ph.length !== 10) return phone

  ph = `(${ph.substring(0, 3)}) ${ph.substring(3, 6)}-${ph.substring(6)}`

  if (ex) ph += " x" + ex

  return ph
}

export function toTitleCase(str) {
  str = str.toLowerCase()
  str = str.split(" ")

  for (var i = 0; i < str.length; i++) {
    str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1)
  }

  return str.join(" ")
}

export function formatISODate(date) {
  if (!date) {
    return ""
  }

  return `${moment(date).format("YYYY-MM-DDTHH:mm:ss.000")}Z`
}

export function formatISODateWithoutTime(date) {
  if (!date) {
    return ""
  }

  return `${moment(date).format("YYYY-MM-DD")}T00:00:00.000Z`
}

export function formatDateOnly(date) {
  if (!date) {
    return ""
  }

  return moment(date).format("YYYY-MM-DD")
}

export function parseError(err) {
  console.log(err)

  if (err.response?.status !== 401) {
    if (err?.response?.data?.Message) {
      store.dispatch(toastError(err?.response?.data?.Message))
      return err?.response?.data?.Message
    } else if (err?.response?.data?.message) {
      store.dispatch(toastError(err?.response?.data?.message))
      return err?.response?.data?.message
    } else if (err?.response?.data && typeof err?.response?.data === "string") {
      store.dispatch(toastError(err?.response?.data))
      return err?.response?.data
    } else if (err.message) {
      store.dispatch(toastError(err.message))
      return err.message
    }
  }
}
const monthArr = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
export function getDate(date) {
  const d = new Date(date)
  let month = monthArr[d.getMonth()]
  let day = d.getDate()
  let year = d.getFullYear()
  let postfix = nth(day)

  return `${month} ${day}${postfix}, ${year}`
}

export function getDateForEvent(data) {
  // const d = new Date(date);

  const eventLocalDate = data?.split("T")[0]
  let date = eventLocalDate?.split("-")
  if (date?.length === 3) {
    let month = monthArr[parseInt(date[1]) - 1]
    return `${month} ${date[2]}, ${date[0]}`
  }
}

export function getTimeForEvent(data) {
  const eventLocalDate = data?.split("T")[1]?.substring(0, 5)
  let postfix = eventLocalDate?.split(":")[0] > 12 ? "PM" : "AM"
  let hours = eventLocalDate?.split(":")[0] % 12 || 12
  let minutes = eventLocalDate?.split(":")[1]

  return `${hours}:${minutes} ${postfix}`
}

export function getDateByTimezone(date, timeZone) {
  if (date) {
    const d = new Date(date).toLocaleString("en-US", {
      timeZone: timeZone,
      month: "long",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      year: "numeric",
    })
    return d
  }
}

export function getDateWithoutPostfix(date) {
  const d = new Date(date)
  let month = monthArr[d.getUTCMonth()]
  let day = d.getUTCDate()
  let year = d.getUTCFullYear()

  return `${month} ${day}, ${year}`
}
function addZero(i) {
  if (i < 10) {
    i = "0" + i
  }
  return i
}
export function getNotificationDate(date) {
  const d = new Date(date)
  let month = monthArr[d.getMonth()]
  let day = d.getDate()
  let year = d.getFullYear()
  let hour = addZero(d.getHours())
  let minutes = addZero(d.getMinutes())
  if (year === new Date().getFullYear()) {
    return `${month}, ${day} · ${hour}:${minutes}`
  } else {
    return `${month} ${day}, ${year}`
  }
}

export function getMonthAndYear(date) {
  const d = new Date(date)
  let month = monthArr[d.getMonth()]
  let year = d.getFullYear()

  return `${month}, ${year}`
}
export function getTime(date) {
  const d = new Date(date)
  let hours = d.getHours()
  let minutes = d.getMinutes() < 10 ? "0" + d.getMinutes() : d.getMinutes()

  let postfix = hours > 12 ? "PM" : "AM"

  return `${hours}:${minutes} ${postfix}`
}

const nth = function (d) {
  if (d > 3 && d < 21) return "th"
  switch (d % 10) {
    case 1:
      return "st"
    case 2:
      return "nd"
    case 3:
      return "rd"
    default:
      return "th"
  }
}

export function getDatesDifferent(startDate, endDate) {
  if (startDate && endDate) {
    var starts = moment(startDate)
    var ends = moment(endDate)
    // var duration = moment.duration(ends.diff(starts));

    // let months = duration.get("months") + duration.get("years") * 12;
    // let days = duration.get("hours")
    // 	? duration.get("days") + 1
    // 	: duration.get("days");

    let years = ends.diff(starts, "years")
    starts.add(years, "years")
    let months = ends.diff(starts, "months")
    starts.add(months, "months")
    let days = ends.diff(starts, "days")
    let yearsText = years > 1 ? "years" : "year"
    let monthsText = months > 1 ? "months" : "month"
    let daysText = days > 1 ? "days" : "day"

    if (years) {
      return `${years ? years + " " + yearsText : ""} ${months ? months + " " + monthsText : ""}`
    }
    return `${months ? months + " " + monthsText : ""} ${days ? days + " " + daysText : ""}`
  }
}
