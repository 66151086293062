import React, { useState } from "react"
import EyeOffIcon from "@/assets/img/icon_eye_slash_grey.svg"
import EyeIcon from "@/assets/img/icon_eye_open_grey.svg"

export default function InputField({
  type = "text",
  placeholder,
  onChange,
  value,
  disabled,
  name = "",
  inputStyle = "",
  divStyle = "",
  postfix = false,
  postfixOnclick = () => {},
}) {
  const [intermediateType, setIntermediateType] = useState(type)
  const [errorState, setErrorState] = useState(false)

  const onInputChange = (e) => {
    if (onChange !== undefined) {
      onChange(e.target.value)
    }
  }
  return (
    <div className={`${divStyle}   relative`}>
      <input
        disabled={disabled}
        type={intermediateType}
        name={name}
        id={name}
        className={`${inputStyle}`}
        placeholder={placeholder}
        aria-invalid="true"
        aria-describedby="email-error"
        value={value}
        onChange={onInputChange}
      />
      {type === "password" && (
        <div
          onClick={() => {
            setIntermediateType((prev) => (prev === "text" ? "password" : "text"))
          }}
          className="absolute   top-1/2 right-3 -translate-y-1/2 cursor-pointer text-gray-400">
          <img className="w-8" src={intermediateType === "text" ? EyeOffIcon : EyeIcon} alt="seePassword" />
        </div>
      )}
      {postfix && (
        <div className="absolute top-2 right-5 cursor-pointer text-base text-orange_v2 " onClick={postfixOnclick}>
          Apply
        </div>
      )}
    </div>
  )
}
