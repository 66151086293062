import * as actionTypes from "../action-types";
import axios from "@/utils/request.js";

export function getUnreadNotificationCount() {
	return async (dispatch) => {
		try {
			dispatch({ type: actionTypes.SHOW_LOADING, payload: true });
			let res = await axios.get(`/notifications/count`);
			dispatch({
				type: actionTypes.GET_UNREAD_NOTIFICATION,
				payload: res.data?.count,
			});
		} catch (error) {
		} finally {
			dispatch({ type: actionTypes.HIDE_LOADING, payload: false });
		}
	};
}

export function getAllNotifications() {
	return async (dispatch) => {
		try {
			// dispatch({ type: actionTypes.SHOW_LOADING, payload: true });
			let res = await axios.get(`/notifications`);
			dispatch({
				type: actionTypes.GET_ALL_NOTIFICATIONS,
				payload: res.data,
			});
		} catch (error) {
		} finally {
			// dispatch({ type: actionTypes.HIDE_LOADING, payload: false });
		}
	};
}

export function getNavbarStatusBar(PageNo = 0, PerPage = 10) {
	return async (dispatch) => {
		try {
			dispatch({ type: actionTypes.SHOW_LOADING, payload: true });
			let res = await axios.get(
				`/notifications/status-bar?PageNo=${PageNo}&PerPage=${PerPage}`
			);
			dispatch({
				type: actionTypes.GET_STATUS_BAR,
				payload: res.data?.notifications,
			});
		} catch (error) {
		} finally {
			dispatch({ type: actionTypes.HIDE_LOADING, payload: false });
		}
	};
}

export function updateUnreadNotificationCount(num = 1) {
	return function (dispatch) {
		dispatch({
			type: actionTypes.UPDATE_UNREAD_NOTIFICATION_COUNT,
			payload: num,
		});
	};
}

export function markNotificationAsRead(id) {
	return (dispatch) => {
		return new Promise(async (resolve, reject) => {
			try {
				dispatch({ type: actionTypes.SHOW_LOADING, payload: true });
				await axios.put(`/notifications/${id}/read`);
				dispatch(updateUnreadNotificationCount());
				dispatch({ type: actionTypes.MARK_NOTIFICATION_AS_READ, payload: id });
				resolve();
			} catch (error) {
			} finally {
				dispatch({ type: actionTypes.HIDE_LOADING, payload: false });
			}
		});
	};
}

export function markAllNotificationAsRead() {
	return (dispatch) => {
		return new Promise(async (resolve, reject) => {
			try {
				dispatch({ type: actionTypes.SHOW_LOADING, payload: true });
				await axios.put(`/notifications/read-all`);
				dispatch(updateUnreadNotificationCount(0));
				dispatch({ type: actionTypes.MARK_ALL_AS_READ });
				resolve();
			} catch (error) {
			} finally {
				dispatch({ type: actionTypes.HIDE_LOADING, payload: false });
			}
		});
	};
}
export function deleteAllNotifications() {
	return (dispatch) => {
		return new Promise(async (resolve, reject) => {
			try {
				dispatch({ type: actionTypes.SHOW_LOADING, payload: true });
				await axios.delete(`/notifications/delete-all`);
				dispatch(updateUnreadNotificationCount(0));
				dispatch(getAllNotifications());
				resolve();
			} catch (error) {
			} finally {
				dispatch({ type: actionTypes.HIDE_LOADING, payload: false });
			}
		});
	};
}

export function setSelectedNotification(data) {
	return function (dispatch) {
		dispatch({ type: actionTypes.SET_CURRENT_NOTIFICATION, payload: data });
	};
}

export function removeNotification(id) {
	return (dispatch) => {
		return new Promise(async (resolve, reject) => {
			try {
				dispatch({ type: actionTypes.SHOW_LOADING, payload: true });
				await axios.delete(`/notifications/${id}`);
				dispatch({ type: actionTypes.REMOVE_NOTIFICATION, payload: id });
				resolve();
			} catch (error) {
			} finally {
				dispatch({ type: actionTypes.HIDE_LOADING, payload: false });
			}
		});
	};
}

export function setRemovedNotificationId(data = null) {
	return function (dispatch) {
		dispatch({ type: actionTypes.REMOVE_NOTIFICATION, payload: data });
	};
}

export function setNotificationPage(page) {
	return function (dispatch) {
		dispatch({ type: actionTypes.SET_NOTIFICATION_PAGE, payload: page });
	};
}
