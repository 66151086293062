// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._LZixhGog7l83Nmbkmk_ {\n    display: -webkit-box;\n    -webkit-line-clamp: 4;\n    -webkit-box-orient: vertical;\n    overflow: hidden;\n    text-overflow: ellipsis;\n}\n\n.nhm4VUjLCM8XGiyIb7Qg::before {\n    content: \"\";\n    position: absolute;\n    right: 0px;\n    bottom: 0;\n    width: 0;\n    height: 0;\n    border-right: 11px solid white;\n    border-top: 11px solid transparent;\n    border-bottom: 11px solid transparent;\n}", "",{"version":3,"sources":["webpack://./src/components/Card/projectDescription.module.css"],"names":[],"mappings":"AAAA;IACI,oBAAoB;IACpB,qBAAqB;IACrB,4BAA4B;IAC5B,gBAAgB;IAChB,uBAAuB;AAC3B;;AAEA;IACI,WAAW;IACX,kBAAkB;IAClB,UAAU;IACV,SAAS;IACT,QAAQ;IACR,SAAS;IACT,8BAA8B;IAC9B,kCAAkC;IAClC,qCAAqC;AACzC","sourcesContent":[".project_description {\n    display: -webkit-box;\n    -webkit-line-clamp: 4;\n    -webkit-box-orient: vertical;\n    overflow: hidden;\n    text-overflow: ellipsis;\n}\n\n.before_content::before {\n    content: \"\";\n    position: absolute;\n    right: 0px;\n    bottom: 0;\n    width: 0;\n    height: 0;\n    border-right: 11px solid white;\n    border-top: 11px solid transparent;\n    border-bottom: 11px solid transparent;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"project_description": "_LZixhGog7l83Nmbkmk_",
	"before_content": "nhm4VUjLCM8XGiyIb7Qg"
};
export default ___CSS_LOADER_EXPORT___;
